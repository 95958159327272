import Papa from 'papaparse';

export enum FileFormat {
    CSV = 'csv',
    TSV = 'tsv'
}

/**
 * Utility function to convert a CSV file to JSON object.
 * @param file CSV file blob.
 * @returns JSON object.
 */
export const convertCSVToJSON = async(file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: false,
            complete (results) {
                resolve(results.data);
            },
            error (error) {
                reject(error);
            }
        });
    });
};

/**
 * Downloads a given list of rows as a CSV file.
 * @param fileRows list of rows.
 * @param fileName name of the file to be downloaded.
 */
export const downloadFile = (fileRows: string[], fileName: string, format: FileFormat) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/${format};charset=utf-8,` + encodeURIComponent(fileRows.join('\n')));
    element.setAttribute('download', fileName);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
    document.body.removeChild(element);
};
