import React, { FunctionComponent, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataTable, DataTableDefinition } from './DataTable';
import { makeStyles } from '@material-ui/core/styles';
import { Header, Tabs } from '@amzn/awsui-components-react-v3';

export interface DataTableGroupDefinition {
    groupName: string;
    dataTableDefinitions: DataTableDefinition[];
}

export interface DataTableGroupProps {
    dataTableGroupDefinition: DataTableGroupDefinition;
    expandableSection?: boolean;
    firstColumnHeader?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    }
}));

export const DataTableGroup: FunctionComponent<DataTableGroupProps> = (props: DataTableGroupProps) => {
    const classes = useStyles();

    const [activeTabId, setActiveTabId] = useState(props.dataTableGroupDefinition.dataTableDefinitions[0].tableName);

    const content = props.dataTableGroupDefinition.dataTableDefinitions.length === 1
        ? <DataTable tableDefinition={props.dataTableGroupDefinition.dataTableDefinitions[0]}/>
        : <Tabs
            tabs={props.dataTableGroupDefinition.dataTableDefinitions.map((dataTableDefinition) => {
                return {
                    label: dataTableDefinition.tableName,
                    id: dataTableDefinition.tableName,
                    content: <DataTable tableDefinition={dataTableDefinition}/>
                };
            })}
            activeTabId={activeTabId}
            onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
        />;

    const component = props.expandableSection ? (
        <div className={classes.root}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Header variant='h2'>{props.dataTableGroupDefinition.groupName}</Header>
                </AccordionSummary>

                <AccordionDetails>
                    {content}
                </AccordionDetails>
            </Accordion>
        </div>
    ) : (
        <div>
            <Header variant='h2'>{props.dataTableGroupDefinition.groupName}</Header>
            {content}
        </div>
    );

    return component;
};

export const exportDataTablesToCSV = (dataTables: DataTableGroupDefinition[]): string[] => {
    const fileRows: string[] = [];
    dataTables.forEach((tableGroupDefinition: DataTableGroupDefinition) => {
        fileRows.push(tableGroupDefinition.groupName);
        tableGroupDefinition.dataTableDefinitions.forEach((tableDefinition) => {
            fileRows.push(tableDefinition.tableName);
            fileRows.push(tableDefinition.columnNames.join(','));
            tableDefinition.rows.forEach((row) => {
                fileRows.push(row.map((data) => data.split(',').join('')).join(',')); // Remove commas from the data
            });
            fileRows.push('');
        });
    });

    return fileRows;
};
