import { Stage } from '@amzn/limestone-experiment-portal-types';
import * as UrlUtils from '../utils/url-utils';
import AmplifyConfigDev from './config.dev';
import AmplifyConfigBeta from './config.beta';
import AmplifyConfigGamma from './config.gamma';
import AmplifyConfigProd from './config.prod';

export default (currentStage?: Stage) => {
    let stage = currentStage;
    if (!currentStage) {
        const url: URL = new URL(window.location.href);
        stage = UrlUtils.parseStageFromUrl(url);
    }

    switch(stage) {
        case Stage.BETA:
            return AmplifyConfigBeta;
        case Stage.GAMMA:
            return AmplifyConfigGamma;
        case Stage.PROD:
            return AmplifyConfigProd;
        default:
            return AmplifyConfigDev;
    }
};