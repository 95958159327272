import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';
import {
    MARKETPLACE_DEFAULT_REGION_DEFINITION_TYPE,
} from '../../constants/experiment/region-definition-type';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import { SelectProps } from '@amzn/awsui-components-react-v3';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';

/**
 * Experiment region definition type.
 */
export class RegionDefinitionType extends SelectField {
    protected displayConfig: SelectFieldConfig;
    private regionServiceClient: RMSApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.REGION_DEFINITION_TYPE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Common ReSES experiments should use the default definition type. ' +
                'If you want to use other definition types, please make sure you have discussed with business team.',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.REGION_DEFINITION_TYPE),
            selectedOption: null,
            statusType: 'loading',
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };

        this.regionServiceClient = new RMSApiHandler(props.realm!);
    }

    async componentDidMount() {
        const decryptedMarketplaceId = decryptCustomerId(this.props.marketplaceId!);
        const marketplace = INVERTED_MARKETPLACE_MAP[Number(decryptedMarketplaceId)];

        const payloadValue = this.props.initialValue ? this.props.initialValue : MARKETPLACE_DEFAULT_REGION_DEFINITION_TYPE[marketplace];
        this.setValueFromPayload(payloadValue);

        // Initializes select options from RMS API call.
        if (!this.displayConfig.options) {
            await this.regionServiceClient.getRegionDefinitions(marketplace)
                .then((response) =>
                    this.displayConfig.options = response.regionDefinitionTypes
                        .map((regionDefinitionType) => {
                            return {
                                label: regionDefinitionType.name,
                                value: regionDefinitionType.name,
                            } as SelectProps.Option;
                        })
                )
                .catch((error) => console.error('Error getting region definition types: ', error));
        }

        if (this.props.updateFormState) {
            this.props.updateFormState(MetadataAttribute.REGION_DEFINITION_TYPE, payloadValue, payloadValue, true);
        }
        this.displayConfig.statusType = 'finished';
        this.forceUpdate();
    }

    setValueFromPayload = async(regionDefinitionType: string) => {
        this.setValue({ value: regionDefinitionType, label: regionDefinitionType });
    }
}
