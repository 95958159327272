import React from 'react';
import { DatePicker, DatePickerProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';
import { IValidationRules } from '@amzn/limestone-experiment-portal-types';
export interface DateFieldConfig extends ExperimentAttributeConfig, DatePickerProps {}

/**
 * Field that will allow user to enter a single line input.
 * Reference: https://polaris.a2z.com/components/awsui-date-picker/?example=standard
 */
export abstract class DateField extends ExperimentAttribute<ExperimentAttributeProps> {
    protected displayConfig!: DateFieldConfig;

    parseValueFromEvent = (event: CustomEvent<DatePickerProps.ChangeDetail>): string => event.detail.value;

    setValue = async(newValue: string) => {
        const { isValid, errorText } = this.validateDate(newValue, this.validationRules);
        this.displayConfig = {
            ...this.displayConfig,
            value: newValue,
            touched: true,
            invalid: !isValid,
            errorText,
        };

        await new Promise((resolve) => this.setState({ displayValue: newValue, validity: isValid }, () => resolve(newValue)));
    }

    validateDate = (value: string, validationRules: IValidationRules) => {
        let { isValid, errorText } = this.validate(value, validationRules);
        try {
            const date = new Date(value);
            isValid = isValid && this.displayConfig.isDateEnabled!(date);
        } catch {
            isValid = false;
            const newError = ' Entered date not allowed to be selected';
            errorText = errorText ? errorText + ' ' + newError : newError;
        }

        return { isValid, errorText };
    }

    getPolarisElement = () => <DatePicker data-testid={this.props['data-testid']} {...this.displayConfig}/>
}
