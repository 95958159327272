import { Header } from '@amzn/awsui-components-react-v3';
import React, { Component } from 'react';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { DisplayTable } from '../../common/DisplayTable';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps } from '@amzn/limestone-experiment-portal-types';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { columnDefinitions, columnOptions, pageSizeOptions } from '../../constants/table/approvals-queue-definition';
import { LIMESTONE_ADMIN_ALIAS, TableHeaders, ApproverExperimentAttributes, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import * as ApproverAdaptor from '../../api/experiment-service/adaptors/experiment-approver-adaptor';
import { ApproverRoleType } from '../../enums/ApproverRoleType';

export interface ApproversQueueState {
    approverExperiments: ApproverExperimentAttributes[];
    tableLoading: boolean;
}

class ApproversQueue extends Component<PageProps, ApproversQueueState> {
    public experimentServiceAPI: LemsApiHandler;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: true,
            approverExperiments: [],
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);
    }

    componentDidUpdate = async(prevProps: PageProps) => {
        if (prevProps.realm !== this.props.realm) {
            this.experimentServiceAPI = new ApiHandler(this.props.realm);
            await this.fetchExperiments();
        }
    }

    componentDidMount = async() => await this.fetchExperiments();

    fetchExperiments = async() => {
        await this.experimentServiceAPI.getExperimentsByApprover(this.props.userAttributes?.username!)
            .then((response: LimestoneExperiment[]) => {
                this.setState({ approverExperiments: ApproverAdaptor.convertExperimentToApproverAttributes(response, ApproverRoleType.BUSINESS) });
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsByApprover.FAIL!))
            .finally(() => this.setState({ tableLoading: false }));

        if (this.props.userAttributes!.isAdmin) {
            this.setState({ tableLoading: true });

            await this.experimentServiceAPI.getExperimentsByApprover(LIMESTONE_ADMIN_ALIAS)
                .then((response: LimestoneExperiment[]) => {
                    const updatedApproverExperiments = [...this.state.approverExperiments, ...ApproverAdaptor.convertExperimentToApproverAttributes(response, ApproverRoleType.LIMESTONE)];
                    this.setState({ approverExperiments: updatedApproverExperiments });
                })
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsByApprover.FAIL!))
                .finally(() => this.setState({ tableLoading: false }));
        }
    }

    render() {
        return (
            <div style={ { padding: '20px' } }>
                <DisplayTable
                    title={<Header variant="h2" counter={String(this.state.approverExperiments.length)}>{TableHeaders.APPROVERS_QUEUE}</Header>}
                    items={this.state.approverExperiments}
                    tableLoading={this.state.tableLoading}
                    columnDefinitions={columnDefinitions}
                    columnOptions={columnOptions}
                    pageSizeOptions={pageSizeOptions}
                    preferencesEnabled={true}
                />
            </div>
        );
    }
}

export default ApproversQueue;
