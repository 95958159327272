import * as FileUtils from '../../utils/file-utils';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode,
    ProductSelectionAttribute,
    AttributeLabels,
    FileValidationRules } from '@amzn/limestone-experiment-portal-types';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';

export class ProductSelectionFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            duplicateCheck: true,
            emptyCheck: true,
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.VALIDATION_COMPLETE || this.props.experimentStatus === ExperimentStatusType.REJECTED;

        this.displayConfig = {
            label: AttributeLabels.OFFERS_FILE,
            editable,
            touched: false,
            value: [],
            onChange: (event: any) => this.onChangeEvent(event, ProductSelectionAttribute.OFFERS_FILE),
            i18nStrings: {
                uploadButtonText: (e: any) => e ? '' : 'Upload Selection',
                dropzoneText: (e: any)=> e ? '' : 'Drop file',
                removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error'
            },
            showFileSize: true,
            showFileThumbnail: true,
            tokenLimit: 1,
            multiple: false,
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            await this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValueFromPayload = async(newContent: string[]) => {
        const fileName = this.getDisplayValue() ? this.getDisplayValue() : 'selection_file.csv';
        const file = new File(newContent.map((line) => line + '\n'), fileName, { type: 'text/csv' });
        await this.setValue([file]);
    }

    extractContentFromFile = async(file: File): Promise<string[]> => {
        const asinsArr = await FileUtils.convertCSVToJSON(file);
        return asinsArr.map((asin: string[]) => asin[0].trim()).filter((asin: string) => asin && asin !== '');
    };
}
