import { FileField, FileFieldConfig } from '../fields/FileField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { AttributeLabels,
    CustomEvaluationRequestAttributes,
    DisplayMode,
    FileValidationRules  } from '@amzn/limestone-experiment-portal-types';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';

export class CustomEvaluationRequestFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            duplicateCheck: false,
            emptyCheck: false,
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.VALIDATION_COMPLETE || this.props.experimentStatus === ExperimentStatusType.REJECTED;

        this.displayConfig = {
            label: AttributeLabels.OFFERS_FILE,
            editable,
            touched: false,
            value: [],
            onChange: (event: any) => this.onChangeEvent(event, CustomEvaluationRequestAttributes.SELECTION),
            i18nStrings: {
                uploadButtonText: (e: any) => e ? '' : 'Upload Selection',
                dropzoneText: (e: any)=> e ? '' : 'Drop file',
                removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error'
            },
            showFileSize: true,
            showFileThumbnail: true,
            tokenLimit: 1,
            multiple: false,
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: ReadonlyArray<File>) => {
        let files: ReadonlyArray<File> = [];
        if (newValue && newValue.length) {
            files = newValue;
        }

        this.validateAndSetFileValue(files);
    }

    setValueFromPayload = async(newContent: File) => {
        this.setValue([newContent]);
    }

    getPayloadValue = () => this.displayConfig.value[0];
}
