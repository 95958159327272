import React, { FunctionComponent } from 'react';
import { Box, Cards, CardsProps, Link } from '@amzn/awsui-components-react-v3';
export interface QuickLinkCardItem {
    name: string;
    link: string;
}

const quickLinkCardsPerRow = [
    {
        'cards': 1
    },
    {
        'minWidth': 500,
        'cards': 2
    }
];
interface QuickLinkCardProps {
    item: QuickLinkCardItem;
}

const QuickLinkCardContent: FunctionComponent<QuickLinkCardProps> = ({ item }) => {
    return (
        <Link href={item.link}>
            <Box><b>{item.name}</b></Box>
        </Link>
    );
};

const quickLinkCardsDefinitions: CardsProps.CardDefinition<QuickLinkCardItem> = {
    header: (item) => <QuickLinkCardContent item={item} />,
};

export interface QuickLinkSectionProps {
    cardItems: QuickLinkCardItem[];
}

export const QuickLinksSection: FunctionComponent<QuickLinkSectionProps> = ({ cardItems }) => (
    <div data-testid={'quicklink-section'} style={{ padding: '10px' }}>
        <Cards cardDefinition={quickLinkCardsDefinitions} items={cardItems} cardsPerRow={quickLinkCardsPerRow} />
    </div>
);
