import { RadioGroupField, RadioGroupFieldConfig } from '../fields/RadioGroupField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode, RegionSelectionAttribute } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';
import { Box } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { TreatmentRegionSelectedUploadType } from '../../enums/TreatmentRegionSelectedUploadType';

export class TreatmentRegionUploadTypeField extends RadioGroupField {
    protected displayConfig: RadioGroupFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { displayMode } = props;

        this.displayConfig = {
            label: AttributeLabels.TREATMENT_REGION_UPLOAD_TYPE,
            editable: false,
            touched: false,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, RegionSelectionAttribute.TREATMENT_REGION_RADIO_GROUP),
            value: '',
            items: [
                {
                    value: TreatmentRegionSelectedUploadType.DROPDOWN,
                    label: 'Select boundaries from a drop-down',
                    description: 'Choose the treatment boundaries from our dropdown menu (Recommended)',
                },
                {
                    value: TreatmentRegionSelectedUploadType.UPLOAD_FILE,
                    label: 'Upload boundaries via a csv file',
                    description: <Box variant="small">Use this option only if the number of treatment boundaries is very large. Please refer to the dropdown for the format of the region to be uploaded. <a href='/files/sample-treatment-regions.csv' download>Example File</a></Box>,
                },
            ]
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }
}
