import React from 'react';
import { RadioGroup, RadioGroupProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';

export interface RadioGroupFieldConfig extends ExperimentAttributeConfig, RadioGroupProps {
    items: RadioGroupProps.RadioButtonDefinition[];
}

/**
 * Field to allow use select one option from a given list of bullet options.
 * Reference: https://refresh.cloudscape.aws.dev/components/radio-group/?tabId=playground
 */
export abstract class RadioGroupField extends ExperimentAttribute<ExperimentAttributeProps> {
    protected displayConfig!: RadioGroupFieldConfig;

    parseValueFromEvent = (event: CustomEvent<RadioGroupProps.ChangeDetail>): string => event.detail.value;

    setValue = async(newValue: string) => {
        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            value: newValue,
        };
        await new Promise((resolve) => this.setState({ displayValue: newValue, validity: true }, () => resolve(newValue)));
    }

    getPolarisElement = () => <RadioGroup data-testid={this.props['data-testid']} {...this.displayConfig}/>;
}
