import * as React from 'react';
import { Backdrop, Button, Box, makeStyles } from '@material-ui/core';

export interface ClickableBackdropProps {
    name: string;
    content: JSX.Element;
}

export const useStyles = makeStyles({
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        background: 'white',
        color: 'black',
        border: '2px solid #000'
    },
});

export const ClickableBackdrop = (props: ClickableBackdropProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <Button variant='contained' disableElevation onClick={handleToggle}>{props.name}</Button>
            <Backdrop
                style={{ color: '#fff', zIndex: 1001 }}
                open={open}
                onClick={handleClose}
            >
                <Box onClick={(e) => e.stopPropagation()} className={classes.box} style={{ top: open ? '50%' : '200%' }}>
                    {props.content}
                </Box>
            </Backdrop>
        </div>
    );
};
