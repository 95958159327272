import { RadioGroupField, RadioGroupFieldConfig } from '../fields/RadioGroupField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode, RegionSelectionAttribute } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';
import { Box } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { ControlRegionSelectedUploadType } from './ControlRegionSelectedUploadType';

export class ControlRegionUploadTypeField extends RadioGroupField {
  protected displayConfig: RadioGroupFieldConfig;

  constructor(props: ExperimentAttributeProps) {
      super(props);

      const { displayMode } = props;

      this.displayConfig = {
          label: AttributeLabels.CONTROL_REGION_UPLOAD_TYPE,
          editable: false,
          touched: false,
          onChange: (event) => this.onChangeEvent(event as CustomEvent, RegionSelectionAttribute.CONTROL_REGION_RADIO_GROUP),
          value: '',
          items: [
              {
                  value: ControlRegionSelectedUploadType.DROPDOWN,
                  label: 'Select boundaries from a drop-down',
                  description: 'Choose the control boundaries from our dropdown menu',
              },
              {
                  value: ControlRegionSelectedUploadType.UPLOAD_FILE,
                  label: 'Upload control boundaries via a csv file',
                  description: <Box variant="small">Use this option only if the number of control boundaries is very large. Please refer to the dropdown for the format of the region to be uploaded. <a href='/files/sample-treatment-regions.csv' download>Example File</a></Box>,
              },
              {
                  value: ControlRegionSelectedUploadType.DEFAULT,
                  label: 'Use default control boundaries',
                  description: 'Choose the default control boundaries (Rest of the marketplace excluding' +
                ' treatment boundaries from all other experiments)',
              }
          ]
      };

      this.state = {
          displayValue: '',
          displayMode: displayMode ? displayMode : DisplayMode.CREATE,
          validity: true
      };
  }

  componentDidMount = async() => {
      if (this.props.initialValue) {
          this.setValueFromPayload(this.props.initialValue);
      }
  }
}
