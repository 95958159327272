import React, { Component } from 'react';
import { Button, ColumnLayout, Container, Header, Link } from '@amzn/awsui-components-react-v3';
import RMSApiHandler from '../../../api/region-service/handler/rms-api-handler';
import { PageProps } from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledView } from '../../../permissions/PermissionControlledView';
import { RegionDefinitionFile } from '../../../form/attributes/region/RegionDefinitionFile';
import { Marketplace } from '../../../form/attributes';
import { RegionDefinitionTypeName } from '../../../form/attributes/region/RegionDefinitionTypeName';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../../utils/error-handler-utils';
import { RegionDefinitionOperation } from '../../../form/attributes/region/RegionDefinitionOperation';
import { CreateRegionDefinitionOperationType,
    DisplayMode,
    RegionDefinitionType } from '@amzn/limestone-experiment-portal-types';
import { DisplayTable } from '../../../common/DisplayTable';
import { IButtonHandler, TableHeaders } from '@amzn/limestone-experiment-portal-types';
import {
    COLUMN_OPTIONS,
    COLUMN_DEFINITIONS,
    PAGE_SIZE_OPTIONS
} from '../../../constants/table/region-definitions-table-definition';
import {
    CreateRegionDefinitionModalAttributes,
} from '@amzn/limestone-experiment-portal-types';
import { UserInputModal } from '../../../common/UserInputModal';

export interface CreateRegionDefinitionPageState {
    tableLoading?: boolean;
    marketplace?: string;
    regionDefinitionTypeName?: string
    operationType?: CreateRegionDefinitionOperationType;
    file?: File;
    showSubmitModal: boolean;
    submittingRequest: boolean;
    availableRegionDefinitions: RegionDefinitionType[];
}

/**
 * Admin page to create a new region definition for ReSES experimentation use.
 */
class CreateRegionDefinitionPage extends Component<PageProps, CreateRegionDefinitionPageState> {

    /** Region Management Service handler instance which accesses RMS APIs to get region data. */
    public regionServiceClient: RMSApiHandler;

    private readonly submitButtonHandler: IButtonHandler;
    private readonly submitModalHandler: IButtonHandler;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: false,
            showSubmitModal: false,
            submittingRequest: false,
            availableRegionDefinitions: [],
        };

        this.regionServiceClient = new RMSApiHandler(props.realm);

        this.submitModalHandler = {
            dismiss: () => this.setState({ showSubmitModal: false }),
            submit: () => this.handleSubmitModalClicked()
        };

        this.submitButtonHandler = {
            submit: () => this.setState({ showSubmitModal: true }),
        };
    }

    handleSubmitModalClicked = async() => {
        this.setState({ submittingRequest: true });
        await this.regionServiceClient.uploadRegionDefinitionFile(this.state.marketplace!, this.state.regionDefinitionTypeName!, this.state.operationType!, this.state.file!)
            .then((succeed: boolean) => {
                if (succeed) {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.createRegionDefinition.SUCCESS);
                } else {
                    handleErrorResponse(new Error('No response from uploading region definition file'), this.props.setNotification!, NOTIFICATION_MESSAGES.createRegionDefinition.FAIL!);
                }
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.createRegionDefinition.FAIL!))
            .finally(() => this.setState({ showSubmitModal: false, submittingRequest: false }));
    }

    /*
    * Update states according to the form attributes update.
    */
    updateFileState = (_fieldId: string, payloadValue: File): void => {
        this.setState({ file: payloadValue });
    }

    updateMarketplaceState = async(fieldId: string, payloadValue: any, displayValue: string) => {
        this.setState({ marketplace: displayValue });
        await this.getAllRegionDefinitions();
    }

    updateRegionDefinitionTypeNameState = (fieldId: string, payloadValue: any): void => {
        this.setState({ regionDefinitionTypeName: payloadValue });
    }

    updateOperationTypeState = (fieldId: string, payloadValue: CreateRegionDefinitionOperationType): void => {
        this.setState({ operationType: payloadValue });
    }

    getAllRegionDefinitions = async () => {
        this.setState({ tableLoading: true });
        await this.regionServiceClient.getRegionDefinitions(this.state.marketplace!)
            .then((response) => this.setState({ availableRegionDefinitions: response.regionDefinitionTypes }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getRegionDefinition.FAIL!))
            .finally(() => this.setState({ tableLoading: false }));
    }

    /** Returns if it is allowed to click the submit button. */
    canSubmitRequest() : boolean {
        return !!(this.state.marketplace && this.state.regionDefinitionTypeName && this.state.file);
    }

    render() {
        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ paddingBottom: '20px' }}>
                    <Container>
                        <Container data-testid={'region-definition-metadata-section'} header={<Header>1. Define Region Definition Metadata</Header>}>
                            <ColumnLayout columns={1}>
                                <Marketplace
                                    data-testid="marketplace-dropdown"
                                    realm={this.props.realm}
                                    displayMode={DisplayMode.CREATE}
                                    updateFormState={this.updateMarketplaceState}
                                />
                                <RegionDefinitionTypeName
                                    displayMode={DisplayMode.CREATE}
                                    updateFormState={this.updateRegionDefinitionTypeNameState}
                                />
                                <RegionDefinitionOperation
                                    updateFormState={this.updateOperationTypeState}
                                />
                            </ColumnLayout>
                        </Container>
                        <Container
                            data-testid={'region-definition-file-section'}
                            header={
                                <Header info={
                                    <Link variant='info' href='https://w.amazon.com/bin/view/Limestone/RXRegionManagementService/RegionDefinitionFile/'>
                                        Format
                                    </Link>
                                }>
                                    2. Upload Region Definition File
                                </Header>}
                        >
                            <RegionDefinitionFile
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFileState}
                                initialValue={this.state.file}
                            />
                        </Container>
                        <div style={{ float: 'right' }}>
                            <Button data-testid="submit-button" id="buttonid" disabled={!this.canSubmitRequest()}
                                onClick={this.submitButtonHandler.submit}
                            >Submit
                            </Button>
                        </div>
                        <div style={ { paddingBottom: '20px' } }/>
                        <UserInputModal
                            data-testid="submit-modal"
                            visible={this.state.showSubmitModal}
                            buttonHandlers={this.submitModalHandler}
                            {...CreateRegionDefinitionModalAttributes}
                            submitButtonLoading={this.state.submittingRequest}
                        />
                    </Container>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <DisplayTable
                        title={<Header variant="h2">{TableHeaders.AVAILABLE_REGION_DEFINITION_TYPES} <span className="awsui-util-header-counter">({this.state.availableRegionDefinitions.length})</span></Header>}
                        items={this.state.availableRegionDefinitions}
                        tableLoading={this.state.tableLoading!}
                        columnDefinitions={COLUMN_DEFINITIONS}
                        columnOptions={COLUMN_OPTIONS}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        preferencesEnabled={true}
                    />
                </div>
            </PermissionControlledView>
        );
    }
}

export default CreateRegionDefinitionPage;
