import React, { Component } from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    ColumnLayout, Container,
    Header,
    Spinner,
    Table,
    TableProps
} from '@amzn/awsui-components-react-v3';
import { INotifications, DisplayMode, PageProps } from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../../api/experiment-service/handler/lems-api-handler-impl';
import { IButtonHandler } from '@amzn/limestone-experiment-portal-types';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import * as FormUtils from '../../../utils/form-utils';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { UserInputModal } from '../../../common/UserInputModal';
import {
    RegionalizeUnregionalizeAsinExecuteModalAttributes,
    RegionalizeUnregionalizeAsinSubmitModalAttributes,
    OfferValidationStatusType,
    UserRequestType
} from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import * as UrlUtils from '../../../utils/url-utils';
import { ProductSelectionFile } from '../../../form/attributes';
import { PermissionControlledView } from '../../../permissions/PermissionControlledView';
import { BaseEntityType } from '@amzn/limestone-experiment-portal-types';
import { ExperimentWorkflowType } from '../../../common/ExperimentWorkflowType';
import { ExperimentNavigationBar } from '../../ExperimentDetails/ExperimentNavigationBar';
import { asinActionPage } from '../../index';

export interface AsinActionPageState {
    experimentId: string;
    objectKey: string;
    showSpinner: boolean;
    getExperimentSuccess: boolean;
    experiment: LimestoneExperiment;
    operation: string;
    createExperimentUserRequestResult: LambdaModel.ExperimentUserRequestOutput;
    notifications: INotifications;
    showSubmitButton: boolean;
    showSubmitModal: boolean;
    showExecuteButton: boolean;
    regionalizeAsinRequestList: LambdaModel.ExperimentUserRequestOutput[];
    unregionalizeAsinRequestList: LambdaModel.ExperimentUserRequestOutput[];
    showUserRequestTables: boolean;
    showExecuteModal: boolean;
    currentRequestItem: LambdaModel.ExperimentUserRequestOutput;
}

class AsinActionPage extends Component<PageProps, AsinActionPageState> {
    /**
     * Experiment Service handler instance which provides api to get the experiment data from the backend
     */
     private experimentServiceAPI: LemsApiHandler;
     private readonly submitModalHandler: IButtonHandler;
     private readonly executeModalHandler: IButtonHandler;
     private static readonly OPERATION_TYPES: ButtonDropdownProps.Item[] = [
         {
             'text' : 'Regionalize',
             'id' : 'REGIONALIZATION',
             'disabled' : false,
         },
         {
             'text' : 'Un-Regionalize',
             'id' : 'UNREGIONALIZATION',
             'disabled' : false
         }
     ];
    private static readonly SELECTION_FILE_HEADER: string = 'ASIN,Regionalization Status';

    private readonly buttonHandler: any;

    private columnDefinitions: TableProps.ColumnDefinition<LambdaModel.ExperimentUserRequestOutput>[];

    constructor(props: PageProps) {
        super(props);
        this.state = {
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            experimentId: '',
            objectKey: '',
            showSpinner: true,
            getExperimentSuccess: false,
            operation: '',
            createExperimentUserRequestResult: {
                requestId: '',
                requestType: '',
                validationFailureReasons: '',
                validationStatus: OfferValidationStatusType.NOT_STARTED,
                requestStatus: '',
                requesterAlias: ''
            },
            notifications: {},
            showSubmitModal: false,
            showSubmitButton: false,
            showExecuteButton: false,
            unregionalizeAsinRequestList: [],
            regionalizeAsinRequestList: [],
            showUserRequestTables: false,
            showExecuteModal: false,
            currentRequestItem: {
                requestId: '',
                requestType: '',
                validationFailureReasons: '',
                validationStatus: OfferValidationStatusType.NOT_STARTED,
                requestStatus: '',
                requesterAlias: ''
            }
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.submitModalHandler = {
            dismiss: () => this.setState({ showSubmitModal: false }),
            submit: () => this.handleSubmitModalClicked()
        };

        this.executeModalHandler = {
            dismiss: () => this.setState({ showExecuteModal: false }),
            submit: () => this.handleUserRequestOperationBegin(this.state.currentRequestItem)
        };

        this.columnDefinitions = [];
        this.buttonHandler = {
            executeUserRequest: (item: LambdaModel.ExperimentUserRequestOutput) => {
                this.setState({ showExecuteModal: true, currentRequestItem: item });
            }
        };
    }

    /**
     *  Generates the table of ASIN Requests when the page is opened.
     *  Parses experimentId from URL
     */
    componentDidMount = async() => {
        const { experimentId, experimentIntegerId } = UrlUtils.parseQueryParametersFromUrl(new URL(window.location.href));
        await this.setState({ experimentId });
        this.setExperimentById(experimentId, experimentIntegerId);
        await this.generateAsinRequestTable();
    }

    /**
     * Calls the @function readExperiment API to ensure this experiment actually exists. If it doesn't, the user cannot progress.
     * If the response returns an experiment, then call @function setExperiment.
     * Otherwise, pop up a failure banner
     */
    setExperimentById(experimentId: string, experimentIntegerId: number) {
        this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
            .then((response: LimestoneExperiment) => this.setExperiment(response))
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentById.FAIL!);
                this.setState({ showSpinner: false });
            });
    }

    /**
     * Sets the experiment and inserts a button which allows the user to choose the type of operation (REGIONALIZATION vs UN-REGIONALIZATION)
     * When the operation is selected, it calls the @function handleOperationClicked function
     * @param response A limestoneExperiment
     */
    setExperiment(response: LimestoneExperiment) {
        this.setState({ experiment: response, getExperimentSuccess: true, showSpinner: false });
    }

    /**
     * The operation is set depending on what the user chose from the dropdown menu.
     * Then, we find the section of the page we want the product selection box to appear on (we've decided to call it 'asin-upload')
     * This calls the @function getProductSelectionForm function.
     * We also pass in the @function updateFormState function which will be called.
     * @param id
     */
    handleOperationClicked(id: any) {
        this.setState({ operation: id });
    }

    /**
     * When a selection file is uploaded, we insert a submit button beneath the box which will call the @function handleSubmitUserRequest function
     * @param fieldId
     * @param payloadValue
     * @param displayValue
     * @param isValid
     */
    updateFormState = (fieldId: string, payloadValue: any, displayValue: string, isValid: boolean): void => {
        const updatedProductSelection = this.state.experiment.productSelection;
        updatedProductSelection[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
        this.setState({ experiment: { ...this.state.experiment, productSelection: updatedProductSelection } });
        this.setState({ showSubmitButton: true });
    }

    /**
     * Sets the state to show the submit Modal.
     * If submit is pressed on submit Modal, @function handleSubmitModalClicked will trigger.
     */
    handleSubmitUserRequest() {
        this.setState({ showSubmitModal: true });
    }

    /**
     * This function calls the @function createExperimentUserRequest API
     * If the API call was successful, then it will return an ExperimentUserRequestOutput object and set the state variable to the response
     * If there was an error, we will pop up a banner
     * finally, we will modify the experimentId to be the correct format and call @function UploadProductSelection
     * If UploadProductSelection was successful, then we will call the @function generateAsinRequestTable once again to update the list of selections.
     * If there is an error, we will pop up another banner.
     */
    handleSubmitModalClicked() {
        this.setState({ showSubmitModal: false, showSubmitButton: false });

        this.experimentServiceAPI.createExperimentUserRequest(this.state.experimentId, this.state.operation)
            .then((response: LambdaModel.ExperimentUserRequestOutput) => this.setState({ createExperimentUserRequestResult: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.createExperimentUserRequest.FAIL!))
            .finally(() => {
                this.setState({ objectKey: this.generateSelectionUploadObjectKey(this.state.createExperimentUserRequestResult.requestId) });

                this.experimentServiceAPI.uploadProductSelection(this.state.experiment.productSelection.offersFile.payloadValue, this.state.objectKey, this.state.experiment.metadata.marketplace.payloadValue, BaseEntityType.EXPERIMENT)
                    .then(() =>  {
                        this.generateAsinRequestTable();
                    }).catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadProductSelection.FAIL!));
            });
    }

    generateSelectionUploadObjectKey(requestId: string) {
        var underscore = /_/gi;
        return requestId.replace(underscore, '/');
    }

    /**
     * This sets up the columnDefinition for an AWS Table.
     * In the columnDefinition, we call
     * @function downloadProductSelection which is downloads the product selection file we just uploaded
     * @function handleUserRequestOperationBegin this operation is associated with a button that is greyed out unless the validation is passed and the request is not running/complete
     * for each ExperimentUserRequestOutput item, the table generates data for each column based on each of the item's attributes.
     * We also call:
     * @function getExperimentUserRequestsByRequestType once on 'REGIONALIZATION' and 'UNREGIONALIZATION'
     */
    generateAsinRequestTable = async() => {
        this.columnDefinitions = [{
            id: 'requestId',
            header: 'Request ID',
            cell: (item) => item.requestId,
            minWidth: '140px'
        },
        {
            id: 'requesterAlias',
            header: 'Requested By',
            cell: (item) => item.requesterAlias,
            minWidth: '100px'
        },
        {
            id: 'requestType',
            header: 'Request Type',
            cell: (item) => item.requestType,
            minWidth: '110px'
        },
        {
            id: 'validation',
            header: 'Validation Status',
            cell: (item) => item.validationStatus,
            minWidth: '75px'
        },
        {
            id: 'selectionLink',
            header: 'Selection Link',
            cell: (item) => { return <Button data-testid="download-button" onClick={() => this.downloadProductSelection(item)}> Download Selection</Button>; },
            minWidth: '300px'
        },
        {
            id: 'button',
            header: 'Action Button',
            cell: (item) => {
                return <Button data-testid="execute-button" id="buttonid" disabled={!(item.validationStatus === OfferValidationStatusType.PASS && item.requestStatus === 'REQUESTED')}
                    onClick={() => { this.buttonHandler.executeUserRequest(item); }}>
                        Execute
                </Button>;
            },
            minWidth: '160px'
        },
        {
            id: 'request-status',
            header: 'Request Status',
            cell: (item) => item.requestStatus
        }];
        this.experimentServiceAPI.getExperimentUserRequestsByRequestType(UserRequestType.UNREGIONALIZATION, this.state.experimentId)
            .then((response: LambdaModel.ExperimentUserRequestOutput[]) => this.setState({ unregionalizeAsinRequestList: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentUserRequest.FAIL!));

        this.experimentServiceAPI.getExperimentUserRequestsByRequestType(UserRequestType.REGIONALIZATION, this.state.experimentId)
            .then((response: LambdaModel.ExperimentUserRequestOutput[]) => this.setState({ regionalizeAsinRequestList: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentUserRequest.FAIL!));

        this.setState({ showUserRequestTables: true });
    }

    /**
     * This is a helper function to avoid repeating code.
     * @param requestType The type of request REGIONALIZE or UN-REGIONALIZE
     * @param asinRequestList The request list associated with the specified type of request
     */
    renderAsinRequestTable = (requestType: string, asinRequestList: LambdaModel.ExperimentUserRequestOutput[]) => {
        return (
            <Table
                data-testid={requestType + '-requests-table'}
                loadingText="Loading resources"
                items={asinRequestList}
                columnDefinitions={this.columnDefinitions}
                wrapLines={false}
                header={<Header variant="h2"> {requestType} ASIN Update Requests</Header>}
                empty={<Box textAlign="center" color="inherit">
                    <Box margin={{ 'bottom':'xxs' }} padding={{ 'top':'xs' }}>
                        <b>No resources</b>
                    </Box>
                    <Box variant="p" margin={{ 'bottom':'xs' }}>No resources to display.</Box>
                </Box>} />
        );
    }

    /**
     * The Download Selection File button in the ASIN request table will trigger this function, which will save a snapshot of the selection file to the user's local machine that reveals regionalization status
     * This function calls:
     * @function getExperimentUserRequestOffers API which will return a list of ExperimentOfferDto
     * Then, the list of offers are looped through and a row is created in a csv file for each offer.
     * @param item The Request object
     */
    downloadProductSelection(item: LambdaModel.ExperimentUserRequestOutput) {
        let offers: LambdaModel.ExperimentOfferDto[];

        this.experimentServiceAPI.getExperimentUserRequestOffers(item.requestId)
            .then((response: LambdaModel.ExperimentOfferDto[]) => {
                let lines: string[] = [AsinActionPage.SELECTION_FILE_HEADER];
                offers = response;
                offers.forEach((offer: LambdaModel.ExperimentOfferDto) => {
                    lines.push(`${offer.asin},${offer.offerRegionalizationStatus}`);
                });

                const element = document.createElement('a');
                element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(lines.join('\n')));
                element.setAttribute('download', `${item.requestId!}-product-selection`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentUserRequestOffers.FAIL!));
    }

    /**
     * This function is triggered when the "Execute" button is pressed in the table.
     * @param item The request item you want to execute
     */
    handleUserRequestOperationBegin(item: LambdaModel.ExperimentUserRequestOutput) {
        this.setState({ showExecuteModal: false });

        this.experimentServiceAPI.executeExperimentUserRequest(item.requestId, item.requestType as ExperimentWorkflowType)
            .then((response: boolean) => {
                if (response) {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.executeExperimentUserRequest.SUCCESS);
                } else {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.executeExperimentUserRequest.FAIL);
                }
            })
            .finally(() => {
                this.generateAsinRequestTable();
            });
    }

    render() {
        let newRequestContent: JSX.Element;

        newRequestContent =
            <>
                <div style={{ padding: 10 }}>
                    <ButtonDropdown
                        data-testid={'choose-operation-dropdown'}
                        items={AsinActionPage.OPERATION_TYPES}
                        onItemClick={(event) => this.handleOperationClicked(event.detail.id)}>
                        Choose Operation
                    </ButtonDropdown>
                </div>
                {this.state.operation &&
                <Container data-testid={'product-selection-section'} header={'Upload ASINs for ' + this.state.operation}>
                    <ColumnLayout columns={1}>
                        <ProductSelectionFile
                            updateFormState={this.updateFormState}
                            displayMode={DisplayMode.CREATE}
                            initialValue={this.state.experiment.productSelection.offersFile.payloadValue}
                        />
                    </ColumnLayout>
                </Container>}
                {this.state.showSubmitButton &&
                <Button data-testid="submit-button" variant='primary' onClick={this.handleSubmitUserRequest.bind(this)} >Submit Request for {this.state.operation} of ASINs </Button>}
            </>;

        const content = <div>
            <UserInputModal
                data-testid="submit-modal"
                visible={this.state.showSubmitModal}
                buttonHandlers={this.submitModalHandler}
                {...RegionalizeUnregionalizeAsinSubmitModalAttributes}
            />
            <UserInputModal
                data-testid="execute-modal"
                visible={this.state.showExecuteModal}
                buttonHandlers={this.executeModalHandler}
                {...RegionalizeUnregionalizeAsinExecuteModalAttributes}
            />
            <Container header={<Header>Regionalize and Un-Regionalize ASINs</Header>}>
                <ExperimentNavigationBar
                    experiment={this.state.experiment}
                    realm={this.props.realm}
                    userAccessLevels={this.props.userAccessLevels}
                    currentPage={asinActionPage}
                />
                {newRequestContent}

                <div style={{ padding: 20 }}>
                    {this.state.showUserRequestTables && (this.renderAsinRequestTable('UN-REGIONALIZATION', this.state.unregionalizeAsinRequestList))}
                </div>
                <div style={{ padding: 20 }}>
                    {this.state.showUserRequestTables && (this.renderAsinRequestTable('REGIONALIZATION', this.state.regionalizeAsinRequestList))}
                </div>
            </Container>
        </div>;

        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                {
                    this.state.showSpinner
                        ? <Spinner data-testid={'asin-actions-spinner'} size={'large'} />
                        : this.state.getExperimentSuccess && content
                }
            </PermissionControlledView>
        );
    }
}

export default AsinActionPage;
