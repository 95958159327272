import React, { Component } from 'react';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { LifecycleType, Realm, GetSelectionsInExperimentOutput, SelectionOutput } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import * as FormUtils from '../../utils/form-utils';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { NonCancelableCustomEvent, FormField, Multiselect, MultiselectProps, Container, Button } from '@amzn/awsui-components-react-v3';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { CustomEvaluationRequestField } from '../../form/attributes/CustomEvaluationRequestField';
import { CUSTOM_EVALUATION_REQUESTS_DESCRIPTION, CustomEvaluationRequest, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { UserInputModal } from '../../common/UserInputModal';
import { submitCustomEvaluationRequestModalAttributes, UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { CustomEvaluationMetricsSubSection } from './CustomEvaluationMetricSubSection';

export interface CustomEvaluationMetricsSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    addMetricElement?: JSX.Element;
    setNotification: Function;
    userAttributes: UserAttributes;
}

type SelectionOutputMap = {
    [name: string]: SelectionOutput
};

export interface CustomEvaluationMetricsSectionState {
    experiment: LimestoneExperiment;
    selectionMap: SelectionOutputMap;
    selectedOptions: ReadonlyArray<MultiselectProps.Option>;
    showSpinner: boolean;
    newCustomEvaluationRequests: CustomEvaluationRequest[];
    showSubmitCustomEvaluationRequestModal: boolean;
    submittingCustomEvaluationRequest: boolean;
    customEvaluationRequestToSubmitIndex: number;
}

export class CustomEvaluationMetricsSection extends Component<CustomEvaluationMetricsSectionProps, CustomEvaluationMetricsSectionState> {
    public dataCollectionAPI: CdcApiHandler;
    public experimentServiceAPI: LemsApiHandler;

    private customEvaluationRequestId = 0;

    constructor(props: CustomEvaluationMetricsSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            selectionMap: {},
            selectedOptions: [],
            showSpinner: false,
            newCustomEvaluationRequests: [],
            showSubmitCustomEvaluationRequestModal: false,
            submittingCustomEvaluationRequest: false,
            customEvaluationRequestToSubmitIndex: 0
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchCustomEvaluationRequestMetrics();
    }


    fetchCustomEvaluationRequestMetrics = async() => {
        this.setState({ showSpinner: true });

        const selectionMap: SelectionOutputMap = {};
        await this.experimentServiceAPI.getSelectionsInExperiment(this.state.experiment.experimentId)
            .then((response: GetSelectionsInExperimentOutput) => {
                response.selections
                    .filter((selection: SelectionOutput) => selection.lifecycleType === LifecycleType.OUTPUT_METRIC_ONLY)
                    .forEach((selection: SelectionOutput) => {
                        selectionMap[selection.title] = selection;
                    });

                this.setState({ selectionMap });
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getCustomEvaluationRequests.FAIL!);
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    saveCustomEvaluationRequest = (index: number) => {
        this.setState(({
            showSubmitCustomEvaluationRequestModal: true,
            customEvaluationRequestToSubmitIndex: index,
        }));
    }

    updateCustomEvaluationRequest = (customEvaluationRequest: CustomEvaluationRequest, index: number) => {
        this.setState(({ newCustomEvaluationRequests }) => ({
            newCustomEvaluationRequests: [
                ...newCustomEvaluationRequests.slice(0, index),
                {
                    ...customEvaluationRequest
                },
                ...newCustomEvaluationRequests.slice(index + 1)
            ]
        }));
    }

    addCustomEvaluationRequest = () => {
        const newCustomEvaluationRequest = FormUtils.createEmptyCustomEvaluationRequest();
        newCustomEvaluationRequest.id = this.customEvaluationRequestId++;
        this.setState(({ newCustomEvaluationRequests }) => ({
            newCustomEvaluationRequests: [
                ...newCustomEvaluationRequests,
                newCustomEvaluationRequest
            ]
        }));
    }

    deleteCustomEvaluationRequest = (index: number) => {
        this.setState(({ newCustomEvaluationRequests }) => ({
            newCustomEvaluationRequests: [
                ...newCustomEvaluationRequests.slice(0, index),
                ...newCustomEvaluationRequests.slice(index + 1)
            ]
        }));
    }

    submitCustomEvaluationRequest = async(index: number) => {
        this.setState(({ submittingCustomEvaluationRequest: true }));

        const customEvaluationRequest = this.state.newCustomEvaluationRequests[index];

        await this.experimentServiceAPI.addCustomEvaluationRequest(
            this.state.experiment.experimentId,
            this.state.experiment.metadata.marketplace.payloadValue,
            customEvaluationRequest.name,
            customEvaluationRequest.metricTypes,
            customEvaluationRequest.selection!,
        ).then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitCustomEvaluationRequest.SUCCESS);
                this.setState({
                    submittingCustomEvaluationRequest: false,
                    showSubmitCustomEvaluationRequestModal: false
                });
                this.deleteCustomEvaluationRequest(index);
        })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitCustomEvaluationRequest.FAIL);
                this.setState({
                    submittingCustomEvaluationRequest: false,
                    showSubmitCustomEvaluationRequestModal: false
                });
            });
    }

    onCustomEvaluationMetricOptionChange = async(event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
        await this.setState({ selectedOptions: event.detail.selectedOptions });
    }

    render() {
        const addCustomEvaluationRequestForm = (
            <div style={{ padding: '10px 0 10px 0' }}>
                <Container>
                    <FormField
                        data-testid={'custom-evaluation-requests-section'}
                        label={'Impact on Custom Selection'}
                        description={CUSTOM_EVALUATION_REQUESTS_DESCRIPTION}
                    >
                        {this.state.newCustomEvaluationRequests.map((customEvaluationRequest, index) =>
                            <CustomEvaluationRequestField
                                data-testid='custom-evaluation-request'
                                key={customEvaluationRequest.id}
                                customEvaluationRequest={customEvaluationRequest}
                                index={index}
                                updateFormState={this.updateCustomEvaluationRequest}
                                deleteCustomEvaluationRequest={this.deleteCustomEvaluationRequest}
                                saveCustomEvaluationRequest={this.saveCustomEvaluationRequest}
                            />
                        )}
                        <Button
                            data-testid='add-custom-evaluation-request-button'
                            onClick={this.addCustomEvaluationRequest}
                            iconName='add-plus'
                        >
                            Custom Evaluation Request
                        </Button>
                        <FormField data-testid={'select-custom-metrics-section'}>
                            <Multiselect
                                data-testid={'custom-evaluation-metric-dropdown'}
                                options={Object.keys(this.state.selectionMap).map((selectionTitle: string) => { return { label: selectionTitle, value: selectionTitle };})}
                                selectedOptions={this.state.selectedOptions}
                                onChange={this.onCustomEvaluationMetricOptionChange}
                            />
                        </FormField>
                    </FormField>
                </Container>
            </div>
        );

        return (
            <>
                {this.state.showSubmitCustomEvaluationRequestModal && <UserInputModal
                    visible={this.state.showSubmitCustomEvaluationRequestModal}
                    buttonHandlers={{
                        dismiss: () => {
                            this.updateCustomEvaluationRequest({
                                ...this.state.newCustomEvaluationRequests[this.state.customEvaluationRequestToSubmitIndex],
                                editing: true
                            }, this.state.customEvaluationRequestToSubmitIndex);
                            this.setState({ showSubmitCustomEvaluationRequestModal: false });
                        },
                        submit: () => this.submitCustomEvaluationRequest(this.state.customEvaluationRequestToSubmitIndex)
                    }}
                    {...submitCustomEvaluationRequestModalAttributes(
                        this.state.newCustomEvaluationRequests[this.state.customEvaluationRequestToSubmitIndex].name)}
                    submitButtonLoading={this.state.submittingCustomEvaluationRequest}
                />}
                {addCustomEvaluationRequestForm}
                {this.state.selectedOptions.length > 0 && <CustomEvaluationMetricsSubSection
                    realm={this.props.realm}
                    selections={this.state.selectedOptions.map((selectedOption) => this.state.selectionMap[selectedOption.value!])}
                    setNotification={this.props.setNotification}
                    experiment={this.state.experiment}
                    userAttributes={this.props.userAttributes}
                />}
            </>
        );
    }
};
