import React, { Component } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CheckboxProps,
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween,
    Table,
    WizardProps
} from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import {
    AttributeLabels,
    BaseEntityType,
    CUSTOM_EVALUATION_REQUESTS_DESCRIPTION,
    CustomEvaluationRequest,
    DisplayMode,
    EntityType,
    ExperimentStep,
    ExperimentStepIds,
    ExperimentTreatmentType,
    ExperimentType,
    INotifications,
    LifecycleType,
    LimestoneExperiment,
    MetadataAttribute,
    PageProps,
    RegionSelectionAttribute,
    RunDatesDto
} from '@amzn/limestone-experiment-portal-types';
import * as FormUtils from '../../utils/form-utils';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { experimentDetailPage } from '..';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import {
    AdditionalCustomMetricsField,
    BusinessApprover,
    Description,
    EndDate,
    Marketplace,
    Observers,
    PrimaryOwner,
    ProductFamily,
    ProductSelectionFile,
    RegionDefinitionType,
    SecondaryOwners,
    StartDate,
    Tags,
    Title,
} from '../../form/attributes';
import { CustomEvaluationRequestField } from '../../form/attributes/CustomEvaluationRequestField';
import { Boundaries } from '../../form/attributes/Boundaries';
import { WizardWrapper } from '../../common/WizardWrapper';
import { TreatmentType } from '../../form/attributes/TreatmentType';
import { RegionFile } from '../../form/attributes/RegionFile';
import { TreatmentRegionUploadTypeField } from '../../form/attributes/TreatmentRegionUploadType';
import TagsInput from 'react-tagsinput';
import { SummarySection } from '../../common/SummarySection';
import { TreatmentRegionSelectedUploadType } from '../../enums/TreatmentRegionSelectedUploadType';
import { ControlRegionUploadTypeField } from '../../form/attributes/ControlRegionUploadType';
import { ControlRegionSelectedUploadType } from '../../form/attributes/ControlRegionSelectedUploadType';
import { ExperimentRegionType } from '../../enums/ExperimentRegionType';

/** Props for the Create Experiment Wizard. */
export interface CreateExperimentWizardProps extends RouteComponentProps, PageProps {
    experimentType: ExperimentType;
}

export interface CreateExperimentWizardState {
    experiment: LimestoneExperiment,
    componentLoadingState: boolean,
    notifications: INotifications,
    showLinkedMultiTreatmentExperiments: boolean,
    unavailableRunDates: RunDatesDto[],
    customEvaluationRequests: CustomEvaluationRequest[],
    overrideActiveStepIndex?: number;
    useTreatmentRegionFile?: string;
    useControlRegionFile?: string;
}

export class CreateExperimentWizard extends Component<CreateExperimentWizardProps, CreateExperimentWizardState> {
    private customEvaluationRequestId = 0;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public constructor(props: CreateExperimentWizardProps) {
        super(props);

        this.experimentServiceAPI = new ApiHandler(props.realm);

        let initialLifecycleType: LifecycleType;
        let dealsEnabled: boolean;
        let bundleEnabled: boolean;

        const experiment = FormUtils.createEmptyLimestoneExperiment();

        if (this.props.experimentType === ExperimentType.LISTER_EXPERIMENT) {
            initialLifecycleType = LifecycleType.BLOCK_LIST;
            dealsEnabled = true;
            bundleEnabled = true;
        } else {
            initialLifecycleType = LifecycleType.RESULT_ANALYSIS_ONLY;
            dealsEnabled = false;
            bundleEnabled = false;

            experiment.metadata[MetadataAttribute.RABL_REGION_ID].updateAttributeDetails(true, '', '');
            experiment.metadata[MetadataAttribute.DISCRIMINATOR].updateAttributeDetails(true, '', '');
            experiment.metadata[MetadataAttribute.PROPORTIONS_OF_MARKETPLACE].updateAttributeDetails(true, [], '');
        }

        experiment.metadata[MetadataAttribute.EXPERIMENT_TYPE].updateAttributeDetails(true, initialLifecycleType, initialLifecycleType);
        experiment.metadata[MetadataAttribute.DEALS_ENABLED].updateAttributeDetails(true, dealsEnabled, '');
        experiment.metadata[MetadataAttribute.BUNDLE_ENABLED].updateAttributeDetails(true, bundleEnabled, '');
        experiment.metadata.treatmentType.updateAttributeDetails(true, ExperimentTreatmentType.OFFER_RESTRICTION, ExperimentTreatmentType.OFFER_RESTRICTION);

        this.state = {
            componentLoadingState: false,
            notifications: {},
            experiment,
            showLinkedMultiTreatmentExperiments: false,
            unavailableRunDates: [],
            customEvaluationRequests: [],
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);
    }

    updateFormState = (fieldId: string, payloadValue: any, displayValue: string, isValid: boolean): void => {
        if (fieldId in this.state.experiment.metadata) {
            const updatedMetadata = this.state.experiment.metadata;
            updatedMetadata[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
            this.setState({ experiment: { ...this.state.experiment, metadata: updatedMetadata } });
        } else if (fieldId in this.state.experiment.productSelection) {
            const updatedProductSelection = this.state.experiment.productSelection;
            updatedProductSelection[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
            this.setState({
                experiment: { ...this.state.experiment, productSelection: updatedProductSelection },
            });
        } else {
            let updatedRegionSelection;
            if (fieldId === RegionSelectionAttribute.TREATMENT_BOUNDARIES) {
                updatedRegionSelection = this.state.experiment.treatmentRegionSelection;
            } else {
                updatedRegionSelection = this.state.experiment.controlRegionSelection;
            }

            updatedRegionSelection.boundaries.updateAttributeDetails(isValid, payloadValue, displayValue);

            if (fieldId === RegionSelectionAttribute.TREATMENT_BOUNDARIES) {
                this.setState({ experiment: { ...this.state.experiment, treatmentRegionSelection: updatedRegionSelection } });
            } else {
                this.setState({ experiment: { ...this.state.experiment, controlRegionSelection: updatedRegionSelection } });
            }
            
        }
    }

    updateCustomEvaluationRequest = (customEvaluationRequest: CustomEvaluationRequest, index: number) => {
        this.setState(({ customEvaluationRequests }) => ({
            customEvaluationRequests: [
                ...customEvaluationRequests.slice(0, index),
                {
                    ...customEvaluationRequest
                },
                ...customEvaluationRequests.slice(index + 1)
            ]
        }));
    }

    addCustomEvaluationRequest = () => {
        const newCustomEvaluationRequest = FormUtils.createEmptyCustomEvaluationRequest();
        newCustomEvaluationRequest.id = this.customEvaluationRequestId++;
        this.setState(({ customEvaluationRequests }) => ({
            customEvaluationRequests: [
                ...customEvaluationRequests,
                newCustomEvaluationRequest
            ]
        }));
    }

    deleteCustomEvaluationRequest = (index: number) => {
        this.setState(({ customEvaluationRequests }) => ({
            customEvaluationRequests: [
                ...customEvaluationRequests.slice(0, index),
                ...customEvaluationRequests.slice(index + 1)
            ]
        }));
    }

    validateStep = (activeStepIndex: number): boolean => {
        let currentStepData: ExperimentStep;

        if (activeStepIndex === ExperimentStepIds.METADATA.get(this.props.experimentType)) {
            const { startDate, endDate, customMetrics, proportionsOfMarketplace, ...metadataStep } = this.state.experiment.metadata;
            currentStepData = metadataStep;
        } else if (activeStepIndex === ExperimentStepIds.PRODUCT_REGION_SELECTION.get(this.props.experimentType)) {
            currentStepData = {
                ...this.state.experiment.productSelection,
                proportionsOfMarketplace: this.state.experiment.metadata.proportionsOfMarketplace,
            };
            if (this.props.experimentType === ExperimentType.RESULT_ANALYSIS_ONLY) {
                currentStepData.boundaries = this.state.experiment.treatmentRegionSelection.boundaries;
            }
        } else if (activeStepIndex === ExperimentStepIds.SCHEDULE_EXPERIMENT.get(this.props.experimentType)) {
            currentStepData = {
                startDate: this.state.experiment.metadata.startDate,
                endDate: this.state.experiment.metadata.endDate
            };
        } else {
            return this.state.customEvaluationRequests.every((customEvaluationRequest) => !customEvaluationRequest.editing);
        }

        let formIsValid = true;
        Object.keys(currentStepData).forEach((key) => {
            formIsValid = formIsValid && currentStepData[key].isValid;
        });

        return formIsValid;
    }

    submitResponse = async() => {
        const createExperimentResponse = await this.experimentServiceAPI.uploadMetadata(this.state.experiment.metadata)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadMetadata.FAIL!));

        if (createExperimentResponse) {
            this.props.setNotification!(NOTIFICATION_MESSAGES.uploadMetadata.SUCCESS);

            const { experimentId, experimentIntegerId } = createExperimentResponse;
            const redirectUri = `${experimentDetailPage.path}?experimentId=${experimentId}&experimentIntegerId=${experimentIntegerId}&realm=${this.props.realm}`;

            await this.experimentServiceAPI.uploadProductSelection(this.state.experiment.productSelection.offersFile.payloadValue, experimentId, this.state.experiment.metadata.marketplace.payloadValue, BaseEntityType.EXPERIMENT)
                .then(() => {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.uploadProductSelection.SUCCESS);
                    setTimeout(() => this.props.history.push(redirectUri), 3000);
                })
                .catch((error) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadProductSelection.FAIL!));

            for (const customEvaluationRequest of this.state.customEvaluationRequests) {
                await this.experimentServiceAPI.addCustomEvaluationRequest(
                    experimentId,
                    this.state.experiment.metadata.marketplace.payloadValue,
                    customEvaluationRequest.name,
                    customEvaluationRequest.metricTypes,
                    customEvaluationRequest.selection!,
                );
            }

            if (this.props.experimentType === ExperimentType.RESULT_ANALYSIS_ONLY) {
                try {
                    await this.experimentServiceAPI.updateExperimentBoundaries(
                        experimentId,
                        this.state.experiment.treatmentRegionSelection.boundaries.payloadValue,
                        EntityType.EXPERIMENT,
                        ExperimentRegionType.TREATMENT);
                    await this.experimentServiceAPI.updateExperimentBoundaries(
                        experimentId,
                        this.state.experiment.controlRegionSelection === undefined ? [] : this.state.experiment.controlRegionSelection.boundaries.payloadValue,
                        EntityType.EXPERIMENT,
                        ExperimentRegionType.CONTROL);
                } catch (error) {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.updateExperimentBoundaries.FAIL!);
                }
            }
        }
    };

    onRegionSimulationCheckBoxChanged = (detail: CheckboxProps.ChangeDetail) => {
        const experimentType = detail.checked ? LifecycleType.BLOCK_LIST_BOUNDARIES_OVERRIDE : LifecycleType.BLOCK_LIST;
        const updatedMetadata = this.state.experiment.metadata;
        updatedMetadata[MetadataAttribute.EXPERIMENT_TYPE].updateAttributeDetails(true, experimentType, experimentType);
        this.setState({ experiment: { ...this.state.experiment, metadata: updatedMetadata } });
    }

    overrideActiveStepIndex = (index: number) => {
        this.setState({
            overrideActiveStepIndex: index
        }, () => {
            this.setState({ overrideActiveStepIndex: undefined });
        });
    }

    renderCustomEvaluationRequestsSummary = () => {
        const columnDefinitions = [
            {
                id: 'name',
                header: 'Name',
                cell: (item: CustomEvaluationRequest) => item.name
            },
            {
                id: 'file',
                header: 'Selection File',
                cell: (item: CustomEvaluationRequest) => item.selection!.name
            },
            {
                id: 'metricTypes',
                header: 'Metric Types',
                cell: (item: CustomEvaluationRequest) => item.metricTypes
            }
        ];
        return (
            <Container data-testid='custom-evaluation-requests-summary'>
                <Header actions={<Button
                    onClick={() => this.overrideActiveStepIndex(ExperimentStepIds.CUSTOM_EVALUATION_REQUESTS.get(this.props.experimentType)!)}
                >Edit</Button>}>
                        Custom Evaluation Requests
                </Header>
                <Table
                    columnDefinitions={columnDefinitions}
                    items={this.state.customEvaluationRequests}
                />
            </Container>
        );
    }

    renderSummary = () => {
        return (
            <Container data-testid={'create-section-summary'}>
                <SummarySection header={'Experiment Definition'} columns={3} items={this.state.experiment.metadata}/>
                <SummarySection data-testid={'experiment-selection-summary-section'} header={'Experiment Selection'}
                    columns={1} items={{ offersFile: this.state.experiment.productSelection.offersFile }}
                    handler={() => this.overrideActiveStepIndex(ExperimentStepIds.PRODUCT_REGION_SELECTION.get(this.props.experimentType)!)}/>
                {this.props.experimentType === ExperimentType.RESULT_ANALYSIS_ONLY &&
                <SummarySection data-testid={'experiment-region-summary-section'} header={'Experiment Region'}
                    columns={1} items={{ treatmentRegion: this.state.experiment.treatmentRegionSelection.boundaries, controlRegion: this.state.experiment.controlRegionSelection.boundaries }}
                    handler={() => this.overrideActiveStepIndex(ExperimentStepIds.PRODUCT_REGION_SELECTION.get(this.props.experimentType)!)}/>}
                {this.state.customEvaluationRequests.length > 0 && this.renderCustomEvaluationRequestsSummary()}
            </Container>
        );
    };

    getExperimentDefinitionStep = (): WizardProps.Step => {
        return {
            title: 'Experiment Definition',
            description: 'The information entered below will be reviewed by the ReSES team, L8 Business and Finance Approvers.',
            content: (
                <Container data-testid={'metadata-section'}>
                    <ColumnLayout columns={2}>
                        <Marketplace
                            data-testid='marketplace-dropdown'
                            realm={this.props.realm}
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.marketplace.payloadValue}
                        />
                        <PrimaryOwner
                            data-testid='primary-owner-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.primaryOwner.payloadValue}
                        />
                        <Title
                            data-testid='title-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.title.payloadValue}
                        />
                        <SecondaryOwners
                            data-testid='secondary-owners-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.secondaryOwners.payloadValue}
                        />
                        <Description
                            data-testid='description-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.description.payloadValue}
                        />
                        <Observers
                            data-testid='observers-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.observers.payloadValue}
                        />
                        <ProductFamily
                            data-testid='product-family-dropdown'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.productFamily.payloadValue}
                        />
                        <BusinessApprover
                            data-testid='business-approver-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.businessApprover.payloadValue}
                        />
                        <Tags
                            data-testid='tags-input'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.tags.payloadValue}
                        />
                    </ColumnLayout>
                </Container>
            ),
            errorText: 'Complete All Required Fields'
        };};

    getSelectionRegionStep = (): WizardProps.Step => {
        let treatmentRegionSelectionOption = null;
        let controlRegionSelectionOption = null;
        if (this.state.useTreatmentRegionFile === TreatmentRegionSelectedUploadType.DROPDOWN) {
            treatmentRegionSelectionOption = <Boundaries
                data-testid='boundaries-dropdown-treatment'
                updateFormState={this.updateFormState}
                marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                experimentId={this.state.experiment.experimentId}
                realm={this.props.realm}
                definitionType={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                startDate={this.state.experiment.metadata.startDate.payloadValue}
                endDate={this.state.experiment.metadata.endDate.payloadValue}
                initialValue={this.state.experiment.treatmentRegionSelection.boundaries.payloadValue}
                key={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                allowOverlappingBoundaries={this.state.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
                otherBoundary={this.state.experiment.controlRegionSelection}
                regionSelectionAttribute={RegionSelectionAttribute.TREATMENT_BOUNDARIES}
            />;
        } else if (this.state.useTreatmentRegionFile === TreatmentRegionSelectedUploadType.UPLOAD_FILE) {
            treatmentRegionSelectionOption = <RegionFile
                data-testid='boundaries-file-upload-treatment'
                updateFormState={this.updateFormState}
                marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                experimentId={this.state.experiment.experimentId}
                realm={this.props.realm}
                definitionType={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                startDate={this.state.experiment.metadata.startDate.payloadValue}
                endDate={this.state.experiment.metadata.endDate.payloadValue}
                initialValue={this.state.experiment.treatmentRegionSelection.boundaries.payloadValue}
                key={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                allowOverlappingBoundaries={this.state.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
                initialFileName={this.state.experiment.treatmentRegionSelection.boundaries.displayValue}
                otherBoundary={this.state.experiment.treatmentRegionSelection}
                regionSelectionAttribute={RegionSelectionAttribute.TREATMENT_BOUNDARIES}
            />;
        }
        if (this.state.useControlRegionFile === ControlRegionSelectedUploadType.DROPDOWN) {
            controlRegionSelectionOption = <Boundaries
                data-testid='boundaries-dropdown-control'
                updateFormState={this.updateFormState}
                marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                experimentId={this.state.experiment.experimentId}
                realm={this.props.realm}
                definitionType={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                startDate={this.state.experiment.metadata.startDate.payloadValue}
                endDate={this.state.experiment.metadata.endDate.payloadValue}
                initialValue={this.state.experiment.controlRegionSelection.boundaries.payloadValue}
                key={this.state.experiment.metadata.regionDefinitionType.payloadValue + '_CONTROL'}
                allowOverlappingBoundaries={this.state.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
                otherBoundary={this.state.experiment.treatmentRegionSelection}
                regionSelectionAttribute={RegionSelectionAttribute.CONTROL_BOUNDARIES}
            />;
        } else if (this.state.useControlRegionFile === ControlRegionSelectedUploadType.UPLOAD_FILE) {
            controlRegionSelectionOption = <RegionFile
                data-testid='boundaries-file-upload-control'
                updateFormState={this.updateFormState}
                marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                experimentId={this.state.experiment.experimentId}
                realm={this.props.realm}
                definitionType={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                startDate={this.state.experiment.metadata.startDate.payloadValue}
                endDate={this.state.experiment.metadata.endDate.payloadValue}
                initialValue={this.state.experiment.controlRegionSelection.boundaries.payloadValue}
                key={this.state.experiment.metadata.regionDefinitionType.payloadValue + '_CONTROL'}
                allowOverlappingBoundaries={this.state.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
                initialFileName={this.state.experiment.controlRegionSelection.boundaries.displayValue}
                otherBoundary={this.state.experiment.treatmentRegionSelection}
                regionSelectionAttribute={RegionSelectionAttribute.CONTROL_BOUNDARIES}
            />;
        }

        return {
            title: 'Experiment Selection and Experiment Region',
            content: (
                <>
                    <Container
                        data-testid={'product-selection-section'}
                        header={<Header description={<>
                            <Box variant="p">Please upload a product selection file in CSV format or input a list of product selection offers. Currently we only support retail offers for experiment selection.</Box>
                            <Box variant="p">Format the selection file as a single list of ASINs in CSV format. <a href='/files/template_file.csv' download>Example File</a></Box>
                        </>}>Experiment Selection</Header>}
                    >
                        <ColumnLayout columns={1}>
                            <ProductSelectionFile
                                updateFormState={this.updateFormState}
                                displayMode={DisplayMode.CREATE}
                                initialValue={this.state.experiment.productSelection.offersFile.payloadValue}
                                initialFileName={this.state.experiment.productSelection.offersFile.displayValue}
                            />
                            {this.props.experimentType === ExperimentType.RESULT_ANALYSIS_ONLY && <TreatmentType
                                data-testid='treatment-type'
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFormState}
                                initialValue={this.state.experiment.metadata.treatmentType.payloadValue}
                            />}
                        </ColumnLayout>
                    </Container>
                    {this.props.experimentType === ExperimentType.LISTER_EXPERIMENT && <Container
                        data-testid={'region-selection-section'}
                        header={<Header
                            description={'Region Selection automatically picks regions that can be most generalizable to the entire marketplace. We recommend that you use our region selection. However, if you want to manually select your own regions to experiment in, please select skip region selection. You can also modify the boundaries that region selection picks later on the experiment details page.'}
                        >Experiment Region</Header>}
                    >
                        <SpaceBetween size={'l'}>
                            <RegionDefinitionType
                                data-testid='region-definition-type-dropdown'
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFormState}
                                initialValue={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                                marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                                realm={this.props.realm}
                            />
                            <FormField
                                label={AttributeLabels.PROPORTIONS_OF_MARKETPLACE}
                                description={'Enter the desired treatment region size as a percentage of OPS in the marketplace (for example, to choose ten percent, type "10" followed by Enter to confirm the selection). If more than one size is selected, pre-experiment analysis will be run and region options will be generated for each size. Most ReSES experiments use a treatment region size of around ten percent.'}
                            >
                                <TagsInput
                                    value={this.state.experiment.metadata.proportionsOfMarketplace.payloadValue.map((proportion: number) => proportion * 100 + '%')}
                                    onChange={(percentages) => {
                                        const proportions = [...new Set(percentages.map((percentage: string) => Number(percentage.replace('%', '')) / 100))];
                                        this.updateFormState(MetadataAttribute.PROPORTIONS_OF_MARKETPLACE, proportions, proportions.map((proportion: number) => proportion * 100 + '%').join(', '), percentages.length > 0);
                                    }}
                                    validate={(percentage) => !isNaN(percentage) && percentage > 0 && percentage <= 100}
                                    renderLayout={(tagElements, inputElement) => <>
                                        {inputElement}{tagElements}
                                    </>}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: 'Region size (%)',
                                        'data-testid': 'proportions-of-marketplace-input',
                                    }}
                                    data-testid="proportions-of-marketplace-field"
                                />
                            </FormField>
                            <Checkbox
                                data-testid={'region-simulation-checkbox'}
                                onChange={({ detail }) => this.onRegionSimulationCheckBoxChanged(detail)}
                                checked={this.state.experiment.metadata.experimentType.payloadValue === LifecycleType.BLOCK_LIST_BOUNDARIES_OVERRIDE}
                            >Skip Region Simulation</Checkbox>
                        </SpaceBetween>
                    </Container>}
                    {this.props.experimentType === ExperimentType.RESULT_ANALYSIS_ONLY && <Container
                        data-testid={'region-selection-section-result-analysis-only'}
                        header={<Header description={'Upload the boundaries that make up the treatment/control region of your experiment.'}>Experiment Region</Header>}
                    >
                        <RegionDefinitionType
                            data-testid='region-definition-type-dropdown'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={(fieldId, payloadValue, displayValue, isValid) => {
                                const updatedRegionSelection = this.state.experiment.treatmentRegionSelection;
                                updatedRegionSelection.boundaries.updateAttributeDetails(false, [], '');
                                this.setState({ experiment: { ...this.state.experiment, treatmentRegionSelection: updatedRegionSelection } });
                                this.updateFormState(fieldId, payloadValue, displayValue, isValid);
                            }}
                            initialValue={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                            marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                            realm={this.props.realm}
                        />
                        {this.state.experiment.metadata.regionDefinitionType.payloadValue && <TreatmentRegionUploadTypeField
                            data-testid='treatment-region-upload-type-radio-group'
                            updateFormState={((_fieldId, _payloadValue, displayValue, _isValid) => {
                                this.setState({ useTreatmentRegionFile: displayValue });
                                this.updateFormState(RegionSelectionAttribute.TREATMENT_BOUNDARIES, [], '', false);
                            })}
                        />}
                        {treatmentRegionSelectionOption}
                        {this.state.experiment.metadata.regionDefinitionType.payloadValue && <ControlRegionUploadTypeField
                            data-testid='control-region-upload-type-radio-group'
                            updateFormState={((_fieldId, _payloadValue, displayValue, _isValid) => {
                                this.setState({ useControlRegionFile: displayValue });
                                this.updateFormState(RegionSelectionAttribute.CONTROL_BOUNDARIES, [], '', false);
                            })}
                        />}
                        {controlRegionSelectionOption}
                    </Container>}
                </>
            ),
            errorText: this.props.experimentType === ExperimentType.LISTER_EXPERIMENT
                ? 'Upload selection file'
                : 'Upload selection file and select treatment region'
        };};

    getScheduleExperimentStep = (): WizardProps.Step => {
        return {
            title: 'Schedule Experiment',
            content: (
                <>
                    <Container
                        data-testid={'schedule-experiment-section'}
                        header={
                            <Header 
                                variant='h2'
                                description={`Select the run dates for the experiment. Your scheduled start date cannot be
                                    within 3 days starting today to have enough time for the experiment design process`}>
                                Schedule Experiment
                            </Header>}
                    >
                        <ColumnLayout columns={1}>
                            {<>
                                <StartDate
                                    data-testid='start-date-input'
                                    displayMode={DisplayMode.CREATE}
                                    updateFormState={this.updateFormState}
                                    initialValue={this.state.experiment.metadata.startDate.payloadValue}
                                    endDate={this.state.experiment.metadata.endDate.payloadValue}
                                />
                                <EndDate
                                    data-testid='end-date-input'
                                    displayMode={DisplayMode.CREATE}
                                    updateFormState={this.updateFormState}
                                    initialValue={this.state.experiment.metadata.endDate.payloadValue}
                                    startDate={this.state.experiment.metadata.startDate.payloadValue}
                                />
                            </>}
                        </ColumnLayout>
                    </Container>
                </>
            ),
            errorText: 'Complete all required fields'
        };
    };

    getCustomEvaluationRequestsStep = (): WizardProps.Step => {
        return {
            title: 'Custom Evaluation Requests',
            description: `By default, we will generate transactional metrics and downstream estimates for all ReSES experiments.
                    Below are additional options that you can choose apart from the default ones above. If there is a metric which
                    is not available in the list below, please reach out to our team to understand the use-case.`,
            content: (
                <>
                    <Container
                        data-testid={'custom-evaluation-requests-section'}
                        header={<Header description={CUSTOM_EVALUATION_REQUESTS_DESCRIPTION}>Impact on Custom Selection</Header>}
                    >
                        {this.state.customEvaluationRequests.map((customEvaluationRequest, index) =>
                            <CustomEvaluationRequestField
                                data-testid='custom-evaluation-request'
                                key={customEvaluationRequest.id}
                                customEvaluationRequest={customEvaluationRequest}
                                index={index}
                                updateFormState={this.updateCustomEvaluationRequest}
                                deleteCustomEvaluationRequest={this.deleteCustomEvaluationRequest}
                            />
                        )}
                        <Button
                            data-testid='add-custom-evaluation-request-button'
                            onClick={this.addCustomEvaluationRequest}
                            iconName='add-plus'
                        >
                            Custom Evaluation Request
                        </Button>
                    </Container>
                    <Container
                        header={<Header description={
                            <>
                                Below are additional metrics that ReSES supports apart from the default transactional and
                                downstream metrics. If you do not find the metric you are looking for in the below list please cut us a
                                <a href='https://sim.amazon.com/issues/create?template=12012b85-2ccf-4ba0-8dff-df4f4919c8a8'>SIM</a> so
                                that our science team can understand your request. </>}>Additional Metrics</Header>}
                        data-testid={'additional-metrics-requests-section'}
                    >
                        <AdditionalCustomMetricsField
                            data-testid='additional-metrics-request'
                            displayMode={DisplayMode.CREATE}
                            realm={this.props.realm}
                            updateFormState={this.updateFormState}
                            initialValue={this.state.experiment.metadata.customMetrics.payloadValue}
                        />
                    </Container>
                </>
            ),
            errorText: 'Save or delete all Custom Evaluation Requests'
        };
    };

    readonly getSummaryStep = (): WizardProps.Step => {
        return {
            title: 'Confirm and Submit',
            description: 'This is the summary of your experiment.',
            content: this.renderSummary()
        };
    };

    getSteps = (experimentType: ExperimentType): WizardProps.Step[] => {
        if (experimentType === ExperimentType.LISTER_EXPERIMENT) {
            return [
                this.getExperimentDefinitionStep(),
                this.getSelectionRegionStep(),
                this.getScheduleExperimentStep(),
                this.getCustomEvaluationRequestsStep(),
                this.getSummaryStep(),
            ];
        } else {
            return [
                this.getExperimentDefinitionStep(),
                this.getScheduleExperimentStep(),
                this.getSelectionRegionStep(),
                this.getCustomEvaluationRequestsStep(),
                this.getSummaryStep(),
            ];
        }
    };

    render() {
        return (
            <WizardWrapper
                steps={this.getSteps(this.props.experimentType)}
                overrideActiveStepIndex={this.state.overrideActiveStepIndex}
                validateStep={this.validateStep}
                submitResponse={this.submitResponse}
                {...this.props}
            />
        );
    }
}

export default withRouter(CreateExperimentWizard);
