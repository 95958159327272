import React, { FunctionComponent, createContext } from 'react';
import { Theme, ThemeProvider as MUIThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const lightTheme: Theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1d1906'
        },
        secondary: {
            main: '#e0ca57'
        }
    }
});

const initialState = {
    isDarkMode: false
};

export const DarkModeContext = createContext(initialState);

export const ThemeProvider: FunctionComponent = ({ children }) => {
    return (
        <DarkModeContext.Provider value={{ isDarkMode: false }}>
            <MUIThemeProvider theme={lightTheme}>{children}</MUIThemeProvider>
        </DarkModeContext.Provider>
    );
};
