import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { Box } from '@amzn/awsui-components-react-v3';

export enum ApprovalStatusType {
    NOT_STARTED = 'NOT_STARTED',
    PENDING_RESPONSE = 'PENDING_RESPONSE',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

type StatusTypeMap = {
    [name in ApprovalStatusType]: JSX.Element;
};

export const ApprovalStatus: StatusTypeMap = {
    NOT_STARTED: <Box color="text-status-info" fontWeight={'bold'}><AccessTimeIcon fontSize='large'/> Pending Response</Box>,
    PENDING_RESPONSE: <Box color="text-status-info" fontWeight={'bold'}><AccessTimeIcon fontSize='large'/> Pending Response</Box>,
    APPROVED: <Box color="text-status-success" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Approved</Box>,
    REJECTED: <Box color="text-status-error" fontWeight={'bold'}><WarningIcon fontSize='large'/> Rejected</Box>
};
