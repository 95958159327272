import React, { Component } from 'react';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { CDC_DATA_NOT_READY_CODE, Realm, MetricsTable } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { MetricsPageDisplay } from './MetricsPageDisplay';
import { NonCancelableCustomEvent, SelectProps } from '@amzn/awsui-components-react-v3';
import _ from 'lodash';
import { UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { constructDate, getNextSaturday } from '../../utils/date-utils';

export interface CustomMetricsSubSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    setNotification: Function;
    metricTypes: string[];
    userAttributes: UserAttributes;
}

export interface CustomMetricsSubSectionState {
    experiment: LimestoneExperiment;
    availableDays: Set<string>;
    tableGroups: DataTableGroupDefinition[];
    showSpinner: boolean;
    dataNotReadyMetrics: Set<string>;
}

export class CustomMetricsSubSection extends Component<CustomMetricsSubSectionProps, CustomMetricsSubSectionState> {
    /** Data Collection Service handler instance which provides api to get the metrics data from the backend */
    public dataCollectionAPI: CdcApiHandler;

    constructor(props: CustomMetricsSubSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            availableDays: new Set(),
            tableGroups: [],
            showSpinner: false,
            dataNotReadyMetrics: new Set(),
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchAvailableDates();
    }

    componentDidUpdate = async(prevProps: Readonly<CustomMetricsSubSectionProps>) => {
        if (prevProps.metricTypes !== this.props.metricTypes) {
            this.setState({ tableGroups: [], availableDays: new Set() });
            await this.fetchAvailableDates();
        }
    }

    fetchAvailableDates = async() => {
        this.setState({ showSpinner: true });
        this.props.metricTypes.forEach(async(metricType) => {
            await this.dataCollectionAPI.getCompletedCollectionDates(this.state.experiment.experimentId, metricType)
                .then((dates: string[]) => {
                    if (_.isEmpty(dates)) {
                        this.setState({ dataNotReadyMetrics: new Set([...this.state.dataNotReadyMetrics, metricType]) });
                    } else {
                        const availabileDaysSet = this.state.availableDays;
                        dates.forEach((date) => availabileDaysSet.add(date));
                        this.setState({ availableDays: availabileDaysSet });
                    }
                })
                .catch((error: any) => {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getCustomEvaluationRequests.FAIL!);
                })
                .finally(() => this.setState({ showSpinner: false }));
        });

    }

    fetchCustomMetric = async(selectedDay: string) => {
        this.setState({ showSpinner: true });

        this.props.metricTypes.forEach(async(metricType) => {
            await this.dataCollectionAPI.getCustomMetric(this.state.experiment.experimentId, metricType, selectedDay)
                .then((table: MetricsTable) => {
                    this.setState({ tableGroups: [...this.state.tableGroups, ...TransactionalMetricsAdaptor.convertMetricsTableToDataTableGroup(table, metricType) ] });
                })
                .catch((error: any) => {
                    if (!(error.response && error.response.status === CDC_DATA_NOT_READY_CODE)) {
                        handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getCustomEvaluationRequests.FAIL!);
                    }

                    this.setState({ dataNotReadyMetrics: new Set([...this.state.dataNotReadyMetrics, metricType ]) });
                })
                .finally(() =>  this.setState({ showSpinner: false }));
        });

    }

    onDateChange = async(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        this.setState({ tableGroups: [] });
        await this.fetchCustomMetric(event.detail.selectedOption.value!);
    }

    render() {
        return (
            <MetricsPageDisplay
                id={'custom-metrics-display'}
                experiment={this.props.experiment}
                tableGroups={this.state.tableGroups}
                tableKey='Custom Metrics'
                dataNotReadyWarning={'No custom metric data is available for this experiment.'}
                availableDays={this.props.userAttributes.isAdmin
                    ? [...this.state.availableDays]
                    : [...this.state.availableDays].filter((date) => constructDate(date) <= getNextSaturday(constructDate(this.props.experiment.metadata.endDate.payloadValue)))}
                showSpinner={this.state.showSpinner}
                onDateChangeFunction={this.onDateChange}
            />
        );
    }
};
