import React, { FunctionComponent } from 'react';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react-v3';
import { basicPages, adminPages } from '../pages';
import { IProps, Realm } from '@amzn/limestone-experiment-portal-types';

interface SidebarProps extends IProps {
    realm: Realm;
    activeHref: string,
    isAdminPortal: boolean
}

export const Sidebar: FunctionComponent<SidebarProps> = ({ activeHref, isAdminPortal, realm }) => {
    const menuItems: SideNavigationProps.Link[] = [];
    let pagesToRender = isAdminPortal ? adminPages : basicPages;
    pagesToRender.filter((page) => !page.hideTab).forEach((page) => {
        menuItems.push({
            type: 'link',
            text: page.title,
            href: `${page.path}?realm=${realm}`,
            external: false
        });
    });

    return (
        <SideNavigation
            activeHref={activeHref}
            items={[
                {
                    type: 'section',
                    text: 'Menu',
                    items: menuItems
                },
            ]}/>
    );
};
