interface MarketplaceMap {
    [name: string]: number;
}

interface InvertedMarketplaceMap {
    [name: number]: string;
}

export const MARKETPLACE_MAP: MarketplaceMap = {
    US: 1,
    DE: 4,
    UK: 3,
    FR: 5,
    JP: 6,
    CN: 7,
    IT: 35691,
    IN: 44571,
    ES: 44551
};

export const INVERTED_MARKETPLACE_MAP: InvertedMarketplaceMap = {
    1: 'US',
    4: 'DE',
    3: 'UK',
    5: 'FR',
    6: 'JP',
    7: 'CN',
    35691: 'IT',
    44571: 'IN',
    44551: 'ES',
};

export enum Marketplace {
    US,
    DE,
    UK,
    FR,
    JP,
    CN,
    IT,
    IN,
    ES
};