import React, { Fragment, FunctionComponent, useState } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { pageMargin, pagePadding, AdminHeader, BasicHeader } from '@amzn/limestone-experiment-portal-types';
import { IProps, UserAttributes, Realm, Portal } from '@amzn/limestone-experiment-portal-types';
import { createExperimentPage, homePage } from '../pages';
import { Link } from '@amzn/awsui-components-react-v3';

interface WebsiteHeaderProps extends IProps {
    userAttributes: UserAttributes;
    isAdminPortal: Boolean;
    username: string;
    togglePortal: (newPortal: Portal) => Promise<void>;
    realm: Realm;
    toggleRealm: (newRealm: Realm) => Promise<void>;
}

export const useBasicStyles = makeStyles((theme) => ({
    appBar: {
        transition: 'background-color ease 0.2s'
    },
    brand: {
        color: theme.palette.secondary.main,
        gridColumn: '1',
        margin: 0,
        textDecoration: 'none',
        fontSize: 20,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    user: {
        float: 'left',
        display: 'flex',
        gridColumn: '2',
        marginLeft: '10px',
        fontSize: 15
    },
    rightAlignedComponents: {
        float: 'right',
        display: 'flex',
        gridColumn: '3'
    },
    realmDisplay: {
        color: 'white',
        fontSize: 15
    },
    toggleButtonDisplay: {
        color: 'white',
        fontSize: 13
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto max-content',
        margin: pageMargin,
        width: `calc(100% - 0.2 * ${pagePadding})`
    },
}));

export const WebsiteHeader: FunctionComponent<WebsiteHeaderProps> = (props: WebsiteHeaderProps) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const classes = useBasicStyles();
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

    const handleClick = (event: any) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleChange = async(newRealm: Realm) => {
        await props.toggleRealm(newRealm);
        setMenuAnchor(null);
    };

    const handlePortalChange = async(newPortal: Portal) => {
        await props.togglePortal(newPortal);
    };

    let headerContents = (props.isAdminPortal) ? AdminHeader : BasicHeader;

    const currentPath = window.location.pathname;
    const realmMenuDisplay = (currentPath !== createExperimentPage.path) ?
        (<Fragment>
            <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} color='primary'>
                <Typography variant='h6' className={classes.realmDisplay}>
                    {props.realm.toUpperCase()}
                    <KeyboardArrowDownIcon />
                </Typography>
            </Button>
            <Menu
                id='simple-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => handleChange(props.realm)}
            >
                <MenuItem onClick={() => handleChange(Realm.NA)}>NA</MenuItem>
                <MenuItem onClick={() => handleChange(Realm.EU)}>EU</MenuItem>
                <MenuItem onClick={() => handleChange(Realm.FE)}>FE</MenuItem>
            </Menu>
        </Fragment>) :
        (<Typography variant='h6' className={classes.realmDisplay}>{`REALM: ${props.realm.toUpperCase()}`}</Typography>);

    const portalDisplay = (currentPath === homePage.path && props.userAttributes.isAdmin) ? (
        <Button onClick={() => handlePortalChange(headerContents.targetPortal)} className={classes.toggleButtonDisplay}>
            {headerContents.targetName}
        </Button>) : null;

    const header = (
        <AppBar data-testid={props.testId} className={classes.appBar} position="sticky" id='website-header'>
            <Toolbar className={classes.toolbar}>
                <Link href={homePage.path}>
                    <h3 className={classes.brand}> {headerContents.title} </h3>
                </Link>

                <Typography className={classes.user}>
                    <span className={classes.user}>{`User: @${props.username}`}</span>
                </Typography>
                <span className={classes.rightAlignedComponents}>
                    {realmMenuDisplay}
                    {portalDisplay}
                </span>
            </Toolbar>
        </AppBar>
    );

    return React.cloneElement(header, { elevation: trigger ? 4 : 0 });
};