import { AttributeLabels, DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { constructDate } from '../../utils/date-utils';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DateField, DateFieldConfig } from '../fields/DateField';

export class DownstreamMetricRequestEndDate extends DateField {
    protected displayConfig: DateFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        const { isValid } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.END_DATE,
            editable: props.editable === true,
            touched: false,
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            onChange: (event) => this.onChangeEvent(event as CustomEvent<any>, MetadataAttribute.END_DATE),
            disabled: true,
            value: initialValue,
            hintText: `
                The analysis end date is recommended to be 9 days before your request date (today) to cover all the orders for the triggered customers.
                We have observed that 98% of the orders are shipped on the 9th day after the order is placed. Thus we will be able to obtain the most
                accurate data for generating the results for the analysis duration.
            `
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid,
            startDate: props.startDate
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate() {
        this.displayConfig.disabled = this.props.startDate === '';
        if (this.state.startDate !== this.props.startDate) {
            this.setValueFromPayload('');
            this.setState({ startDate: this.props.startDate });
        }
    }

    isDateEnabled(date: Date): boolean {
        const earliestDate = constructDate(this.props.startDate!);
        // Default data collection date is the next day of today, end date should before data collection date
        const today = new Date();

        if (date <= earliestDate || date > today) {
            return false;
        }

        return true;
    }
}
