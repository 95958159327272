import React from 'react';
import { Link } from 'react-router-dom';
import { experimentDetailPage } from '../../../pages';
import { ColumnOption } from '../../../common/DisplayTable';
import { LimestoneExperiment, Realm } from '@amzn/limestone-experiment-portal-types';
import { ExpandableSection, TableProps } from '@amzn/awsui-components-react-v3';
import { ExperimentComment } from '../../../form/attributes/ExperimentComment';
import ExperimentDashboardMetrics from '../../../pages/Metrics/ExperimentDashboardMetrics';

/**
 * Column definition for a basic experiment display to ReSES customers.
 */
export function getColumnDefinition(realm: Realm): Array<TableProps.ColumnDefinition<LimestoneExperiment>>{
    return [
        {
            id: 'title',
            header: <div style={{ 'paddingLeft': '20px' }}>Title</div>,
            cell: (experiment) => {
                return (
                    <div style={{ 'paddingLeft': '20px' }}>
                        <Link
                            to={`${experimentDetailPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`}>
                            {experiment.metadata.title.displayValue}
                        </Link>
                    </div>
                );
            },
            width: 200,
            sortingField: 'title',

        },
        {
            id: 'currentStatus',
            header: 'Current Status',
            cell: (experiment) => experiment.currentStatus.currentStatus.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.currentStatus.currentStatus.payloadValue as string)
                    .localeCompare(experiment2.currentStatus.currentStatus.payloadValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'experimentId',
            header: 'ExperimentId',
            cell: (experiment) => experiment.experimentId,
            width: 350,
            sortingField: 'experimentId',
        },
        {
            id: 'startDate',
            header: 'Start Date',
            cell: (experiment) => experiment.metadata.startDate.displayValue,
            width: 150,
            sortingField: 'startDate',
        },
        {
            id: 'endDate',
            header: 'End Date',
            cell: (experiment) => experiment.metadata.endDate.displayValue,
            width: 150,
            sortingField: 'endDate',
        },
        {
            id: 'experimentType',
            header: 'Experiment Type',
            cell: (experiment) => experiment.metadata.experimentType.displayValue,
            width: 200,
            sortingField: 'experimentType',
        },
        {
            id: 'productFamily',
            header: 'Product Family',
            cell: (experiment) => experiment.metadata.productFamily.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.productFamily.displayValue as string)
                    .localeCompare(experiment2.metadata.productFamily.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'primaryBusinessGroup',
            header: 'Primary Business Group',
            cell: (experiment) => experiment.metadata.primaryBusinessGroup.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.primaryBusinessGroup.displayValue as string)
                    .localeCompare(experiment2.metadata.primaryBusinessGroup.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'primaryOwner',
            header: 'Primary Owner',
            cell: (experiment) => experiment.metadata.primaryOwner.displayValue,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.primaryOwner.displayValue as string)
                    .localeCompare(experiment2.metadata.primaryOwner.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'marketplace',
            header: 'Marketplace',
            cell: (experiment) => experiment.metadata.marketplace.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.marketplace.displayValue as string)
                    .localeCompare(experiment2.metadata.marketplace.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'tags',
            header: 'Tags',
            cell: (experiment) => experiment.metadata.tags.displayValue,
            width: 120,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.tags.displayValue as string)
                    .localeCompare(experiment2.metadata.tags.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'metrics',
            header: 'Metrics',
            cell: (experiment) => {
                return <ExpandableSection headerText="Click to expand">
                    <ExperimentDashboardMetrics
                        experiment={experiment}
                        realm={realm}
                    />
                </ExpandableSection>;
            },
            width: 600,
            sortingField: 'metrics',
        },
        {
            id: 'creationDate',
            header: 'Creation Date',
            cell: (experiment) => experiment.creationDate,
            width: 150,
            sortingField: 'creationDate',
        },
        {
            id: 'lastUpdatedDate',
            header: 'Last Updated Date',
            cell: (experiment) => experiment.lastUpdatedDate,
            width: 130,
            sortingField: 'lastUpdatedDate',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (experiment) => experiment.metadata.description.displayValue,
            width: 300,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.description.displayValue as string)
                    .localeCompare(experiment2.metadata.description.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'customerDecision',
            header: 'Customer Decision',
            cell: (experiment) => experiment.customerDecision,
            width: 300,
            sortingField: 'customerDecision',
        },
        {
            id: 'businessRecommendation',
            header: 'Business Recommendation',
            cell: (experiment) => experiment.businessRecommendation,
            width: 300,
            sortingField: 'businessRecommendation',
        },
    ];
}

/**
 * Column definition for all experiments in Admin portal. Administrators are allowed to add and edit comments to any
 * experiments.
 */
export function getAllExperimentsTableColumnDefinition(realm: Realm): Array<TableProps.ColumnDefinition<LimestoneExperiment>> {
    return [
        ...getColumnDefinition(realm),
        {
            id: 'comment',
            header: 'Comment',
            cell: (experiment) => experiment.comment,
            width: 250,
            sortingField: 'comment',
            editConfig: {
                ariaLabel: 'Comment',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Comment Error',
                editingCell: (experiment, { currentValue, setValue }) => {
                    return (
                        <ExperimentComment
                            setValue={setValue}
                            initialValue={experiment.comment}
                        />
                    );
                }
            },
        }
    ];
}

export const columnOptions: ColumnOption[] = [
    { id: 'title', label: 'Title', editable: false, visible: true },
    { id: 'currentStatus', label: 'Current Status', editable: true, visible: true },
    { id: 'marketplace', label: 'Marketplace', editable: true, visible: true },
    { id: 'startDate', label: 'Start Date', editable: true, visible: true },
    { id: 'endDate', label: 'End Date', editable: true, visible: true },
    { id: 'experimentType', label: 'Experiment Type', editable: true, visible: true },
    { id: 'experimentId', label: 'ExperimentId', editable: true, visible: false },
    { id: 'productFamily', label: 'Product Family', editable: true, visible: false },
    { id: 'primaryBusinessGroup', label: 'Primary Business Group', editable: true , visible: false },
    { id: 'primaryOwner', label: 'Primary Owner', editable: true, visible: true },
    { id: 'tags', label: 'Tags', editable: true, visible: false },
    { id: 'metrics', label: 'Metrics', editable: true, visible: true },
    { id: 'creationDate', label: 'Creation Date', editable: true, visible: false },
    { id: 'lastUpdatedDate', label: 'Last Updated Date', editable: true, visible: false },
    { id: 'description', label: 'Description', editable: true, visible: false },
    { id: 'customerDecision', label: 'Customer Decision', editable: true, visible: true },
    { id: 'businessRecommendation', label: 'Business Recommendation', editable: true, visible: true },
];

export const allExperimentColumnOptions: ColumnOption[] = [
    ...columnOptions,
    { id: 'comment', label: 'Comment', editable: true, visible: true },
];

export const pageSizeOptions = [
    { value: 10, label: '10 experiments' },
    { value: 15, label: '15 experiments' },
    { value: 20, label: '20 experiments' }];
