import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

/**
 * Property filter properties for experiment.
 */
export const EXPERIMENT_FILTERING_PROPERTIES: PropertyFilterProperty[] = [
    {
        propertyLabel: 'Title',
        key: 'title',
        groupValuesLabel: 'Title values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Current Status',
        key: 'currentStatusToDisplay',
        groupValuesLabel: 'Current status values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'ExperimentId',
        key: 'experimentId',
        groupValuesLabel: 'ExperimentId values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Start Date',
        key: 'startDate',
        groupValuesLabel: 'Start Date values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'End Date',
        key: 'endDate',
        groupValuesLabel: 'End Date values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Product Family',
        key: 'productFamily',
        groupValuesLabel: 'Product Family values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Primary Owner',
        key: 'primaryOwner',
        groupValuesLabel: 'Primary Owner values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Marketplace',
        key: 'marketplace',
        groupValuesLabel: 'Marketplace values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Tags',
        key: 'tags',
        groupValuesLabel: 'Tags values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Description',
        key: 'description',
        groupValuesLabel: 'Description values',
        operators: [':', '!:', '=', '!='],
    },
];
