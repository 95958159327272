import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterested from '@material-ui/icons/NotInterested';
import { Box } from '@amzn/awsui-components-react-v3';

export enum RablRegionCreationStatusType {
    SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
    AWAITING_USER_RESPONSE = 'AWAITING_USER_RESPONSE',
    BOUNDARIES_UPDATE_COMPLETE = 'BOUNDARIES_UPDATE_COMPLETE',
    UPDATING_TO_LIVE = 'UPDATING_TO_LIVE',
    LIVE = 'LIVE',
    MARKED_FOR_DEPRECATION = 'MARKED_FOR_DEPRECATION',
    DEPRECATED = 'DEPRECATED'
}

type StatusTypeMap = {
    [name in RablRegionCreationStatusType]: JSX.Element;
};

export const RegionCreationStatus: StatusTypeMap = {
    SETUP_IN_PROGRESS: <Box color="text-status-info" fontWeight={'bold'}><NotInterested fontSize='large'/> Not Live</Box>,
    AWAITING_USER_RESPONSE: <Box color="text-status-info" fontWeight={'bold'}><NotInterested fontSize='large'/> Not Live</Box>,
    BOUNDARIES_UPDATE_COMPLETE: <Box color="text-status-info" fontWeight={'bold'}><NotInterested fontSize='large'/> Not Live</Box>,
    UPDATING_TO_LIVE: <Box color="text-status-info" fontWeight={'bold'}><AccessTimeIcon fontSize='large'/> Updating to Live</Box>,
    LIVE: <Box color="text-status-success" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Live</Box>,
    MARKED_FOR_DEPRECATION: <Box color="text-status-info" fontWeight={'bold'}><AccessTimeIcon fontSize='large'/> Marked For Deprecation</Box>,
    DEPRECATED: <Box color="text-status-error" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Deprecated</Box>
};
