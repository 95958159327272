export default {
    'aws_project_region': 'us-east-1',
    'aws_cognito_identity_pool_id': 'us-east-1:57a2e13b-f9b7-4b59-b641-297e47780765',
    'aws_cognito_region': 'us-east-1',
    'aws_user_pools_id': 'us-east-1_9dS9GazBR',
    'aws_user_pools_web_client_id': '4enjar0bb5u12inq43l3qtbbbb',
    'oauth': {
        'domain': 'limestone-prod.auth.us-east-1.amazoncognito.com',
        'scope': [
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        'redirectSignIn': 'https://limestone.strx.amazon.dev',
        'redirectSignOut': 'https://limestone.strx.amazon.dev',
        'responseType': 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    'federationTarget': 'COGNITO_USER_POOLS',
};