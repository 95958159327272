import React, { FunctionComponent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { PageSection } from '../../layout/PageSection';
import { homePage } from '..';
import { pageMargin } from '@amzn/limestone-experiment-portal-types';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        padding: '40px 0 40px 0',
        margin: pageMargin,
        width: 'min-content'
    },
    description: {
        opacity: 1,
    },
    '@keyframes descriptionFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    introContainer: {
        color: 'white',
        margin: 'auto 0',
        textAlign: 'center',
    },
    title: {
        whiteSpace: 'nowrap',
    },
    titleName: {
        opacity: 1,
        color: theme.palette.secondary.main,
        width: 'max-content',
    },
    '@keyframes titleMeetFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    '@keyframes titleNameFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    }
}));

const getBackgroundColor = (theme: Theme): string => theme.palette.primary.main;

/**
 * The Header Section of the Home Page
 */
export const HomePageHeader: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <PageSection testId={`${homePage.testId}-header`} backgroundColor={getBackgroundColor} className={classes.container}>
            <div className={classes.introContainer}>
                <h2 className={classes.titleName}>Regional Selection Experimentation Service</h2>
                <p>A self service tool to design, create and evaluate regional experiments</p>
            </div>
        </PageSection>
    );
};
