import React, { Component } from 'react';
import dagre from 'dagre';
import { Modal, Spinner } from '@amzn/awsui-components-react-v3';
import ReactFlow, { ArrowHeadType, FlowElement } from 'react-flow-renderer';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LIFECYCLE_STATUS_COLOR_MAP, IProps } from '@amzn/limestone-experiment-portal-types';
import { GetExperimentLifecycleGraphResponse, LifecycleStepConfigurationMap, LifecycleStepDisplayStatus } from '@amzn/limestone-experiment-portal-types';
import { graphEdgeLabelWidth, graphNodeHeight, graphNodeWidth, graphEdgeLabelPosition, graphEdgeLabelHeight, graphWidth } from '@amzn/limestone-experiment-portal-types';

export interface ExperimentLifecycleGraphState {
    selectedNodeId: string;
    elements: FlowElement[];
    nodeConfigurationMap: LifecycleStepConfigurationMap;
    showSpinner: boolean;
}

export interface ExperimentLifecycleGraphProps extends IProps {
    realm: Realm;
    experimentId: string;
    setNotification: Function;
}

export class ExperimentLifecycleGraph extends Component<ExperimentLifecycleGraphProps, ExperimentLifecycleGraphState> {
    public experimentServiceAPI: LemsApiHandler;
    private static readonly AWAITING_USER_INPUT = 'Awaiting User Input';

    constructor(props: ExperimentLifecycleGraphProps) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);

        this.state = {
            selectedNodeId: '-1',
            nodeConfigurationMap: {},
            elements: [],
            showSpinner: false
        };
    }

    componentDidMount = async() => {
        this.setState({ showSpinner: true });
        this.experimentServiceAPI.getExperimentLifecycleGraph(this.props.experimentId)
            .then((response) => {
                if (response.edges && response.nodeConfigurationMap) {
                    this.setGraphVisualization(response);
                }
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentLifecycleGraph.FAIL!))
            .finally(() => this.setState({ showSpinner: false }));
    }

    setGraphVisualization = (graphConfiguration: GetExperimentLifecycleGraphResponse) => {
        const autoLayout = new dagre.graphlib.Graph();
        autoLayout.setGraph({});

        const { nodeConfigurationMap } = graphConfiguration;
        const elements: FlowElement[] = [];

        Object.keys(nodeConfigurationMap).forEach((nodeId) => {
            autoLayout.setNode(nodeId, {
                label: nodeConfigurationMap[nodeId].displayName,
                width: graphNodeWidth,
                height: graphNodeHeight
            });
        });

        graphConfiguration.edges.forEach((edge, idx) => {
            autoLayout.setEdge(edge[0], edge[1], {
                width: graphEdgeLabelWidth,
                height: graphEdgeLabelHeight,
                labelpos: graphEdgeLabelPosition
            });
            const isAwaitingUserInput = nodeConfigurationMap[edge[0]].currentStatus === LifecycleStepDisplayStatus.AWAITING_USER_INPUT;
            elements.push({
                id: `${edge[0]}-${idx}`,
                source: edge[0],
                target: edge[1],
                arrowHeadType: ArrowHeadType.ArrowClosed,
                label: isAwaitingUserInput ? ExperimentLifecycleGraph.AWAITING_USER_INPUT : null,
                animated: isAwaitingUserInput
            });
        });

        dagre.layout(autoLayout);

        autoLayout.nodes().forEach((nodeId) => {
            const node = autoLayout.node(nodeId);
            elements.push({
                id: nodeId,
                data: {
                    label: node.label
                },
                style: {
                    background: LIFECYCLE_STATUS_COLOR_MAP.get(nodeConfigurationMap[nodeId].currentStatus)!
                },
                position: {
                    x: 350 + node.x - graphNodeWidth / 2,
                    y: node.y - graphNodeHeight / 2
                }
            });
        });

        autoLayout.setGraph({});
        this.setState({ elements, nodeConfigurationMap });
    };

    onElementClick = (_event: any, element: FlowElement) => {
        this.setState({ selectedNodeId: element.id });
    };

    onModalDismiss = () => {
        this.setState({ selectedNodeId: '-1' });
    };

    render() {
        const modal = this.state.selectedNodeId in this.state.nodeConfigurationMap ?
            (
                <Modal
                    data-testid='selected-node-modal'
                    visible={true}
                    header={this.state.nodeConfigurationMap[this.state.selectedNodeId].displayName}
                    onDismiss={this.onModalDismiss}>
                    {this.state.nodeConfigurationMap[this.state.selectedNodeId].description}
                </Modal>
            ) : null;

        const content = this.state.showSpinner ? (<Spinner size='large' />) : (
            <div style={{ width: graphWidth, height: Object.keys(this.state.nodeConfigurationMap).length *  (graphNodeHeight + 75) }}>
                <ReactFlow
                    data-testid='experiment-lifecycle-graph'
                    elements={this.state.elements}
                    zoomOnScroll={false}
                    onNodeDoubleClick={this.onElementClick}
                    onElementClick={this.onElementClick}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    preventScrolling={false}
                    paneMoveable={false}
                />
                {modal}
            </div>
        );

        return content;
    }
};
