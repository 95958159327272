import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { DateField, DateFieldConfig } from '../fields/DateField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '@amzn/limestone-experiment-portal-types';

export class StartDate extends DateField {
    protected displayConfig: DateFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { displayMode } = props;

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);
        this.validationRules = { required: true };

        this.displayConfig = {
            label: AttributeLabels.START_DATE,
            editable: props.editable === true,
            touched: false,
            placeholder: 'YYYY-MM-DD',
            hintText: 'Required *',
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.START_DATE),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    isDateEnabled(date: Date): boolean {
        const earliestDate = new Date();
        earliestDate.setDate(earliestDate.getDate() + 3);
        if (date < earliestDate) {
            return false;
        }

        if (date.getUTCDay() === 6 || date.getUTCDay() === 0) {
            return false;
        }

        return true;
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateExperimentStartDate(this.props.experimentId!, this.getPayloadValue())
            .finally(() => {
                this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW });
            });

        return response;
    }
}
