import React, { Component } from 'react';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { CDC_DATA_NOT_READY_CODE,
    Realm,
    MetricsTable,
    ScheduleDataCollectionDisplayItem } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { Box, Button, Checkbox, Container, Form, Header, NonCancelableCustomEvent, ProgressBar, SelectProps, SpaceBetween, Table  } from '@amzn/awsui-components-react-v3';
import { createEmptyDownstreamMetricsRequest } from '../../utils/form-utils';
import { CustomerTriggersFile } from '../../form/attributes/CustomerTriggersFile';
import { DisplayMode } from '@amzn/limestone-experiment-portal-types';
import { CustomerTriggersTypeField } from '../../form/attributes/CustomerTriggersTypeField';
import { DownstreamMetricRequestStartDate } from '../../form/attributes/DownstreamMetricRequestStartDate';
import { DownstreamMetricRequestEndDate } from '../../form/attributes/DownstreamMetricRequestEndDate';
import { MetricsPageDisplay } from './MetricsPageDisplay';
import { UserInputModal } from '../../common/UserInputModal';
import { DownstreamMetricRequest, DownstreamMetricRequestAttributes, SubmitDownstreamMetricRequestModal } from '@amzn/limestone-experiment-portal-types';
import { constructDateString } from '../../utils/date-utils';
import { AwsServicesApiHandler } from '../../api/aws-services/aws-services-handler';
import AwsServicesApiHandlerImpl from '../../api/aws-services/aws-services-handler-impl';
import { decryptCustomerId } from '@amzn/amazon-id';
import { SCHEDULED_REQUESTS_COLUMN_DEFINITIONS } from '../../constants/table/scheduled-requests-column-definitions';
import { Upload } from '@aws-sdk/lib-storage';

export interface DownstreamMetricsSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    addMetricElement?: JSX.Element;
    setNotification: Function;
}

export interface DownstreamMetricsSectionState {
    experiment: LimestoneExperiment;
    availableDays: string[];
    tableGroups: DataTableGroupDefinition[];
    showSpinner: boolean;
    buttonLoadingState: boolean;
    request: DownstreamMetricRequest;
    showModal: boolean;
    isSubmitting: boolean;
    isEditing: boolean;
    scheduledRequests: ScheduleDataCollectionDisplayItem[];
    fileUploadProgress: number,
    fileUploadPromise?: Upload,
}


export class DownstreamMetricsSection extends Component<DownstreamMetricsSectionProps, DownstreamMetricsSectionState> {
    public dataCollectionAPI: CdcApiHandler;
    public awsServicesHandler: AwsServicesApiHandler;

    constructor(props: DownstreamMetricsSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            availableDays: [],
            tableGroups: [],
            showSpinner: false,
            buttonLoadingState: false,
            request: createEmptyDownstreamMetricsRequest(),
            showModal: false,
            isSubmitting: false,
            isEditing: false,
            scheduledRequests: [],
            fileUploadProgress: 0.0,
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
        this.awsServicesHandler = new AwsServicesApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchScheduledRequests();
        await this.fetchAvailableDates();
    }

    fetchScheduledRequests = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getScheduledDataCollectionItems(this.state.experiment.experimentId, 'DSE_FINAL_DOWNSTREAM_METRICS')
            .then((items: ScheduleDataCollectionDisplayItem[]) => {
                this.setState({ scheduledRequests: items });
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    fetchAvailableDates = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getCompletedCollectionDates(this.state.experiment.experimentId, 'DSE_FINAL_DOWNSTREAM_METRICS')
            .then((dates: string[]) => {
                this.setState({ availableDays: dates });
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    fetchDownstreamMetrics = async(selectedDay: string) => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getFinalDownstreamMetrics(this.state.experiment.experimentId, selectedDay)
            .then((table: MetricsTable) => {
                this.setState({ tableGroups: TransactionalMetricsAdaptor.convertMetricsTableToDataTableGroup(table, 'Downstream Metrics') });
            })
            .catch((error: any) => {
                if (!(error.response && error.response.status === CDC_DATA_NOT_READY_CODE)) {
                    handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                }

                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    updateFormState = (fieldId: string, payloadValue: any, _displayValue: string, _isValid: boolean): void => {
        const updatedRequest: any = this.state.request;
        updatedRequest[fieldId] = payloadValue;
        this.setState({ request: updatedRequest });
    }

    onDateChange = async(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        const selectedDay = event.detail.selectedOption.value!;
        await this.fetchDownstreamMetrics(selectedDay);
    }

    deleteRequest = () => {
        this.setState({ request: createEmptyDownstreamMetricsRequest(), isEditing: false });
    }

    saveRequest = () => {
        this.setState({ showModal: true, isEditing: false });
    }

    submitRequest = async() => {
        this.setState(({ isSubmitting: true }));

        const experimentId = this.state.experiment.experimentId;
        const marketplaceId = this.state.experiment.metadata.marketplace.payloadValue;
        const decryptedMarketplaceId = Number(decryptCustomerId(marketplaceId));
        // Use the next day of today as collection date
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const collectionDates = [constructDateString(tomorrow)];
        let dataType;
        if (this.state.request.useDefaultScienceModel) {
            dataType = 'DSE_FINAL_DOWNSTREAM_METRICS';
        } else {
            dataType = 'DSE_REGIONALLY_AGGREGATED_METRICS';
        }

        let noUploadError = true;
        const customerTriggersFile = this.state.request.customerTriggersFile;

        if (customerTriggersFile !== null) {
            await this.awsServicesHandler.uploadCustomerTriggersFile(customerTriggersFile, experimentId, decryptedMarketplaceId, collectionDates[0])
                .then(async(upload) => {
                    this.setState({ fileUploadPromise: upload });

                    upload.on('httpUploadProgress', (progress) => {
                        if (progress && progress.loaded && progress.total) {
                            this.setState({ fileUploadProgress: progress.loaded / progress.total });
                        }
                    });

                    await upload.done();
                })
                .catch((error: any) => {
                    noUploadError = false;
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadCustomerTriggersFile.FAIL!);
                });
        }

        if (noUploadError) {
            this.setState({ fileUploadProgress: 0, fileUploadPromise: undefined });

            await this.dataCollectionAPI.scheduleDataCollection(
                experimentId,
                decryptedMarketplaceId,
                dataType,
                collectionDates,
                this.state.request.startDate,
                this.state.request.endDate,
                this.state.experiment.metadata.regionDefinitionType.payloadValue,
                !this.state.request.useDefaultCustomerTriggers,
            ).then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.scheduleDataCollection.SUCCESS);
                this.deleteRequest();
            }).catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.scheduleDataCollection.FAIL);
            }).finally(() => {
                this.setState({ isSubmitting: false, showModal: false });
            });
        }
    }

    render() {
        const addRequestForm = (
            !this.state.isEditing ? <Button
                data-testid='add-downstream-metric-request-button'
                onClick={() => this.setState({ isEditing: true })}
                iconName='add-plus'
            >
                Downstream Metrics Request
            </Button> :
                <form onSubmit={(e: any) => e.preventDefault()}>
                    <Form actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid='save-button'
                                variant='primary'
                                formAction='submit'
                                onClick={this.saveRequest}>Submit</Button>
                            <Button
                                data-testid='delete-button'
                                formAction='none'
                                variant='link'
                                onClick={this.deleteRequest}>Delete</Button>
                        </SpaceBetween>
                    }>
                        <Container>
                            <CustomerTriggersTypeField
                                data-testid='customer-triggers-type-dropdown'
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFormState}
                                endDate={this.state.experiment.metadata.endDate.payloadValue}
                            />
                            {!this.state.request.useDefaultCustomerTriggers &&
                            <>
                                <CustomerTriggersFile
                                    updateFormState={this.updateFormState}
                                    displayMode={DisplayMode.CREATE}
                                    initialValue={this.state.request.customerTriggersFile}
                                    initialFileName={'Upload Customer Triggers'}
                                />
                                <Box variant="small" margin={{ top: 's' }}>
                                    Upload the customer triggers file as a compressed CSV (Zip the file before you upload).
                                    Make sure the regions codes are in a string format so that trailing zeroes are not truncated.
                                    Invalid region codes will automatically be excluded from the analysis.
                                    <span> Sample file - <a href='/files/sample-triggered-customers.csv' download>Sample file</a></span>
                                </Box>
                            </>
                            }
                            <DownstreamMetricRequestStartDate
                                data-testid='downstream-metric-start-date-input'
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFormState}
                                startDate={this.state.experiment.metadata.startDate.payloadValue}
                                endDate={this.state.experiment.metadata.endDate.payloadValue}
                            />
                            <DownstreamMetricRequestEndDate
                                data-testid='downstream-metric-end-date-input'
                                displayMode={DisplayMode.CREATE}
                                updateFormState={this.updateFormState}
                                startDate={this.state.request.startDate}
                                endDate={this.state.experiment.metadata.endDate.payloadValue}
                            />
                            <Checkbox
                                data-testid='use-reses-default-science-model'
                                checked={this.state.request.useDefaultScienceModel}
                                onChange={({ detail }) => this.updateFormState(DownstreamMetricRequestAttributes.SCIENCE_MODEL, detail.checked, '', true)}
                            >
                                Use Synthetic Control to generate metrics
                            </Checkbox>
                        </Container>
                    </Form>
                </form>
        );

        return (
            <>
                {this.state.scheduledRequests.length > 0 && <Table
                    data-testid={'downstream-metrics-requests-table'}
                    loadingText="Loading resources"
                    items={this.state.scheduledRequests}
                    columnDefinitions={SCHEDULED_REQUESTS_COLUMN_DEFINITIONS}
                    wrapLines={false}
                    header={<Header variant="h2"> On Demand Requests</Header>}
                    empty={<Box textAlign="center" color="inherit">
                        <Box margin={{ 'bottom':'xxs' }} padding={{ 'top':'xs' }}>
                            <b>No resources</b>
                        </Box>
                        <Box variant="p" margin={{ 'bottom':'xs' }}>No resources to display.</Box>
                    </Box>}
                />}
                <div style={{ padding: '10px 0 10px 0' }}>
                    <MetricsPageDisplay
                        id='downstream-metrics'
                        experiment={this.props.experiment}
                        tableGroups={this.state.tableGroups}
                        tableKey='Downstream Metrics'
                        dataNotReadyWarning={'Downstream Metrics are collected by default 9 days after the experiment ends. If you want to schedule \
                            another run before that you can use the below form to submit the request for your own list of triggered customers.'}
                        availableDays={this.state.availableDays}
                        showSpinner={this.state.showSpinner}
                        onDateChangeFunction={this.onDateChange}
                    />
                </div>
                <>
                    <UserInputModal
                        visible={this.state.showModal}
                        buttonHandlers={{
                            dismiss: () => {
                                if (this.state.fileUploadPromise) {
                                    this.state.fileUploadPromise.abort();
                                }
                                this.setState({ showModal: false, isEditing: true });
                            },
                            submit: () => this.submitRequest()
                        }}
                        {...SubmitDownstreamMetricRequestModal}
                        submitButtonLoading={this.state.isSubmitting}
                        content={
                            <>
                                <Box variant="p">Please stay on this page till the upload is complete.</Box>
                                {this.state.fileUploadProgress > 0 ? <ProgressBar
                                    value={Math.round(this.state.fileUploadProgress * 100)}
                                    additionalInfo='Uploading takes 2-5 mins depending on the size of your file'
                                    label='Uploading Customer Triggers'
                                /> : <></>}
                            </>

                        }
                    />
                    {addRequestForm}
                </>
            </>
        );
    }
};
