import React from 'react';
import { InputField, InputFieldConfig } from '../fields/InputField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { AttributeLabels, DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';

export class BusinessApprover extends InputField<ExperimentAttributeProps> {
    protected displayConfig: InputFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true, maxLength: 20 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.BUSINESS_APPROVER,
            editable: false,
            type: 'text',
            placeholder: 'Enter amazon alias of your L8 Contact (Without a \'@\')',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.BUSINESS_APPROVER),
            value: props.initialValue,
            toolTipText: 'Written approval from an L8 Contact is required before an experiment can begin.',
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    renderViewMode = () => <></>;
}
