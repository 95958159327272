import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { InputField, InputFieldConfig } from '../fields/InputField';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';

export class PrimaryOwner extends InputField<ExperimentAttributeProps> {
    protected displayConfig: InputFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true, maxLength: 128 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.PRIMARY_OWNER,
            editable: false,
            type: 'text',
            placeholder: 'Enter amazon alias of Primary Owner (Without a \'@\')',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.PRIMARY_OWNER),
            value: props.initialValue,
            toolTipText: 'Primary Owner is the POC for the experiment. They can manage the experiment lifecycle, make decisions about its inputs, and add Secondary Owners.',
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    getPayloadValue = (): LambdaModel.OwnerDto => {
        return {
            alias: this.state.displayValue,
            primary: true
        };
    };

    setValueFromPayload = async(ownerDto: LambdaModel.OwnerDto) => {
        this.setValue(ownerDto.alias);
    }
}
