import { LimestoneExperimentMetadata,
    UserAttributes,
    UserAccessLevel,
    ActionType,
    PermissionsMap } from '@amzn/limestone-experiment-portal-types';

/**
 * Function which returns if any of the user roles have permission to perform the given action.
 * @param accessLevels user access levels.
 * @param action requested action.
 * @param permissionMap map of user roles to actions.
 * @returns if action is authorized.
 */
export const isActionAuthorizedForUser = (
    accessLevels: Set<UserAccessLevel>,
    action: ActionType,
    permissionMap: PermissionsMap
) => {
    return Array.from(accessLevels).some((accessLevel) => permissionMap[accessLevel].includes(action));
};

/**
 * Gets the user access levels from the user attributes provided via Federate.
 * @param userAttributes user attributes obtained via Federate.
 * @returns set of user access level roles.
 */
export const getUserAccessLevelsFromAttributes = (
    userAttributes: UserAttributes
): Set<UserAccessLevel> => {
    const userAccessLevels = new Set<UserAccessLevel>();
    userAccessLevels.add(UserAccessLevel.DEFAULT);

    if (userAttributes.isAdmin) {
        userAccessLevels.add(UserAccessLevel.ADMIN);
    }

    if (userAttributes.isCreator) {
        userAccessLevels.add(UserAccessLevel.CREATOR);
    }

    return userAccessLevels;
};

/**
 * Gets the user access levels from experiment metadata.
 * @param metadata limestone experiment metadata to get the user details.
 */
export const addUserAccessLevelsFromExperiment = (
    metadata: LimestoneExperimentMetadata,
    username: string,
    existingAccessLevels = new Set<UserAccessLevel>()
): Set<UserAccessLevel> => {

    if (username === metadata.primaryOwner.displayValue) {
        existingAccessLevels.add(UserAccessLevel.PRIMARY_OWNER);
    }

    if (metadata.secondaryOwners.displayValue.includes(username)) {
        existingAccessLevels.add(UserAccessLevel.SECONDARY_OWNER);
    }

    if (metadata.observers.displayValue.includes(username)) {
        existingAccessLevels.add(UserAccessLevel.OBSERVER);
    }

    return existingAccessLevels;
};