import { SelectProps } from '@amzn/awsui-components-react-v3';

export const NA_MARKETPLACES: Array<SelectProps.Option> = [
    { value: 'US', label: 'US' }
];

export const EU_MARKETPLACES: Array<SelectProps.Option> = [
    { value: 'DE', label: 'DE' },
    { value: 'IT', label: 'IT' },
    { value: 'FR', label: 'FR' },
    { value: 'ES', label: 'ES' },
    { value: 'UK', label: 'UK' }
];

export const FE_MARKETPLACES: Array<SelectProps.Option> = [
    { value: 'JP', label: 'JP' }
];

export const REALM_TO_MAKRETPLACE_MAP = {
    'na': 'US',
    'eu': 'UK, DE, IT, FR, ES',
    'fe': 'JP'
};
