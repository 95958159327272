import React, { FunctionComponent, useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth, Hub, Cache } from 'aws-amplify';
import { AppLayout, AppLayoutProps, Flashbar, NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3';
import { WebsiteHeader } from './WebsiteHeader';
import { basicPages, adminPages } from '../pages';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { isEmpty } from 'lodash';
import { FlashbarMessageDefinition, INotifications } from '@amzn/limestone-experiment-portal-types';
import { Page } from '../interfaces/Page';
import { PageProps, UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { TEST_USER_ALIAS,
    UserAttributeLabels,
    UserAccessLevel,
    Portal,
    Realm } from '@amzn/limestone-experiment-portal-types';
import * as AuthUtils from '../utils/auth-utils';

export const renderPage = (
    page: Page,
    pageProps: Partial<PageProps>,
    notifications: JSX.Element|null,
    username: string,
    togglePortal: (newPortal: Portal) => Promise<void>,
    realm: Realm,
    toggleRealm: (newRealm: Realm) => Promise<void>,
    navigationToolbarState: boolean,
    onNavigationToolbarToggle: (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => void
) => (
    <ErrorBoundary>
        <WebsiteHeader
            testId={'website-header'}
            userAttributes={pageProps.userAttributes!}
            isAdminPortal={pageProps.portal === Portal.ADMIN}
            username={username}
            togglePortal={togglePortal}
            realm={realm}
            toggleRealm={toggleRealm}
            id={'website-header'}
        />
        
        <AppLayout
            content={
                <ErrorBoundary>
                    {pageProps.userAttributes!.username !== 'Undefined' &&  React.cloneElement(page.content, pageProps)}
                </ErrorBoundary>
            }
            contentType={page.contentType}
            headerSelector={'#website-header'}
            notifications={notifications}
            navigation={
                <Sidebar
                    realm={pageProps.realm ? pageProps.realm : Realm.NA}
                    testId={page.testId}
                    activeHref={page.path}
                    isAdminPortal={pageProps.portal === Portal.ADMIN}
                />
            }
            toolsHide={true}
            disableContentPaddings={page.disableContentPaddings}
            navigationOpen={navigationToolbarState}
            onNavigationChange={onNavigationToolbarToggle}
        />
    </ErrorBoundary>
);

export interface AppProps {
    username: string;
    portal: Portal;
    togglePortal: (newPortal: Portal) => Promise<void>;
    realm: Realm;
    toggleRealm: (newRealm: Realm) => Promise<void>;

}

const App: FunctionComponent<AppProps> = (props: AppProps) => {
    const [userAttributes, updateUserAttributes]: [UserAttributes, Function] = useState({
        name: 'Undefined',
        isAdmin: false,
        isCreator: false,
        isDev: false,
        username: 'Undefined'
    });

    const [userAccessLevels, updateUserAccessLevels]: [Set<UserAccessLevel>, Function] = useState(new Set<UserAccessLevel>());
    const [navigationToolbarState, setNavigationToolbarState]: [boolean, Function] = useState(true);

    const onNavigationToolbarToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>): void => {
        setNavigationToolbarState(event.detail.open);
    };

    const getUserAttributes = async (): Promise<UserAttributes> => {
        const session = await Auth.currentSession();
        const givenName = session.getIdToken().payload[UserAttributeLabels.GIVEN_NAME];
        const familyName = session.getIdToken().payload[UserAttributeLabels.LAST_NAME];
        const permissionsGroups: string = session.getIdToken().payload[UserAttributeLabels.USER_ROLES];
        const username = (await Auth.currentAuthenticatedUser()).getUsername().split('_')![1];

        if ((username === TEST_USER_ALIAS)) {
            return {
                name: givenName + ' ' + familyName,
                isAdmin: true,
                isCreator: true,
                isDev: true,
                username: username
            };
        }

        const doesPermissionsGroupExist = permissionsGroups !== undefined;
        return {
            name: givenName + ' ' + familyName,
            isAdmin: doesPermissionsGroupExist && permissionsGroups.includes(UserAttributeLabels.ADMIN_ROLE),
            isCreator: doesPermissionsGroupExist && permissionsGroups.includes(UserAttributeLabels.CREATOR_ROLE),
            isDev: doesPermissionsGroupExist && permissionsGroups.includes(UserAttributeLabels.DEV_ROLE),
            username: username
        };
    };

    useMemo(() => {
        (async () => {
            try {
                const updatedUserAttributes = await getUserAttributes();
                updateUserAttributes(updatedUserAttributes);
                updateUserAccessLevels(AuthUtils.getUserAccessLevelsFromAttributes(updatedUserAttributes));
            } catch (error) {
                console.error('There was an error in getting user information', error);
            }
        })();
    }, []);

    const [notifications, updateNotifications]: [INotifications, Function] = useState({});

    Hub.listen('auth', (data) => {
        if (data.payload.event === 'signedIn' && Cache.getItem('location') !== null) {
            const location = Cache.getItem('location');
            Cache.removeItem('location');
            window.open(location, '_self');
        }
    });

    const setNotification = (message: FlashbarMessageDefinition, responseDetails?: JSX.Element) => {
        message.onDismiss = () => dismissNotification(message.id);

        const additionalContent = message.baseContent.length
            ? <>
                {message.baseContent.concat(':')} {responseDetails}
            </>
            : responseDetails;
        message.content = responseDetails ? additionalContent : message.baseContent;

        const updatedNotificationState = { ...notifications };
        updatedNotificationState[message.id] = message;
        updateNotifications(updatedNotificationState);
    };

    const dismissNotification = (id: string) => {
        const updatedNotificationState = { ...notifications };
        delete updatedNotificationState[id];
        updateNotifications(updatedNotificationState);
    };

    const renderNotifications = (): JSX.Element|null => {
        if (isEmpty(notifications)) {
            return null;
        }

        return <Flashbar items={Object.values(notifications)} />;
    };

    const pagesToRender = (props.portal === Portal.ADMIN) ? adminPages : basicPages;

    return (
        <div className='awsui'>
            <ErrorBoundary>
                <Switch>
                    {pagesToRender.map((page, index) => (
                        <Route
                            key={index}
                            exact={page.matchExactPath}
                            path={page.path}
                            render={
                                () => renderPage(page, {
                                    realm: props.realm,
                                    portal: props.portal,
                                    setNotification,
                                    userAttributes,
                                    userAccessLevels,
                                },
                                renderNotifications(),
                                props.username,
                                props.togglePortal,
                                props.realm,
                                props.toggleRealm,
                                navigationToolbarState,
                                onNavigationToolbarToggle
                                )
                            }
                        />
                    ))}
                </Switch>
            </ErrorBoundary>

            <ErrorBoundary>
                <Footer/>
            </ErrorBoundary>
        </div>
    );
};

export default App;
