import React, { useState } from 'react';
import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    ColumnLayout,
    Pagination, PropertyFilter,
    Table,
    TableProps,
} from '@amzn/awsui-components-react-v3';
import { ErrorBoundary } from './ErrorBoundary';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { DateRangeSelector, DateRangeSelectorProps } from './DateRangeSelector';
import { PROPERTY_FILTER_I18N_STRINGS } from '@amzn/limestone-experiment-portal-types';
import { EXPERIMENT_FILTERING_PROPERTIES } from '../constants/table/experiment-table/experiment-table-filter-properties';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { AttributeSelectFilter, AttributeSelectFilterProps } from './AttributeSelectFilter';

export interface DisplayTableProps<T> {
    title?: JSX.Element;
    items: any[];
    tableLoading: boolean;
    columnDefinitions: Array<TableProps.ColumnDefinition<T>>;
    columnOptions: Array<ColumnOption>;
    pageSizeOptions: Array<CollectionPreferencesProps.PageSizeOption>;
    preferencesEnabled?: boolean;
    initialSortingId?: string;
    initialSortingDescending?: boolean;
    selectionType?: 'single' | 'multi';
    stickyHeader?: boolean;
    variant?: TableProps.Variant,
    onSelectionChange?: (selectedItems: Array<T>) => void;
    selectedItems?: Array<T>;
    /**
     * Properties to define a date range picker in the table used to filter items based on date range, if provided.
     */
    dateRangeSelectorProps?: DateRangeSelectorProps;
    /**
     * A list of {@link PropertyFilterProperty} to define a property filter, if provided.
     */
    propertyFilterProperties?: PropertyFilterProperty[];
    /**
     * A list of properties to define attribute select filters, if provided.
     */
    attributeSelectFilterProps?: AttributeSelectFilterProps[];
    /**
     * Event that handles submit edit in table cells. It works with editable columns in column definition.
     */
    tableEditOnSubmitEvent?: TableProps.SubmitEditFunction<T>;
}

export interface ColumnOption extends CollectionPreferencesProps.ContentDisplayOption {
    visible: boolean;
    editable?: boolean;
}

export const DisplayTable = <T,>(props:DisplayTableProps<T>) => {
    const empty = <Box textAlign="center" color="inherit">
        <Box margin={{ 'bottom':'xxs' }} padding={{ 'top':'xs' }}>
            <b>No resources</b>
        </Box>
        <Box variant="p" margin={{ 'bottom':'xs' }}>No resources to display.</Box>
    </Box>;

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: 10,
        contentDisplay: props.columnOptions,
    });

    const { items, collectionProps, paginationProps, propertyFilterProps } = useCollection(
        props.items,
        {
            propertyFiltering: {
                filteringProperties: props.propertyFilterProperties ?? EXPERIMENT_FILTERING_PROPERTIES,
                empty: empty,
                noMatch: empty,
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: props.initialSortingId ? {
                    sortingColumn: props.columnDefinitions.find((columnDefinition) => {
                        return columnDefinition.id === props.initialSortingId;
                    })!,
                    isDescending: props.initialSortingDescending,
                } : undefined

            },
            selection: {},
        }
    );

    const content = (
        <Table
            {...collectionProps}
            variant={props.variant}
            columnDefinitions={props.columnDefinitions}
            items={items}
            header={props.title}
            resizableColumns={true}
            empty={empty}
            wrapLines={true}
            loading={props.tableLoading}
            loadingText={'Loading resources'}
            stickyHeader={props.stickyHeader ? props.stickyHeader : true}
            columnDisplay={preferences.contentDisplay}
            preferences={props.preferencesEnabled && <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                contentDisplayPreference={{
                    title: 'Select visible columns',
                    options: props.columnOptions,
                }}
                pageSizePreference={{
                    title: 'Page size',
                    options: props.pageSizeOptions,
                }}
                preferences={preferences}
                onConfirm={({ detail }) => {setPreferences(detail);}}/>}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <ColumnLayout columns={2 + (props.attributeSelectFilterProps ? props.attributeSelectFilterProps.length : 0)}>
                    {props.propertyFilterProperties && <PropertyFilter i18nStrings={PROPERTY_FILTER_I18N_STRINGS} {...propertyFilterProps} />}
                    {props.attributeSelectFilterProps &&
                            props.attributeSelectFilterProps.map((attributeSelectFilterProp) =>
                                <AttributeSelectFilter
                                    key={attributeSelectFilterProp.attributeName}
                                    {...attributeSelectFilterProp} />)}
                    {props.dateRangeSelectorProps && <DateRangeSelector {...props.dateRangeSelectorProps!}/>}
                </ColumnLayout>
            }
            selectionType={props.selectionType}
            onSelectionChange={({ detail }) => {
                props.onSelectionChange!(detail.selectedItems);
            }}
            selectedItems={props.selectedItems}
            submitEdit={props.tableEditOnSubmitEvent}
        />
    );

    return (
        <ErrorBoundary>
            {content}
        </ErrorBoundary>
    );
};
