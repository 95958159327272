import React from 'react';
import { Input, InputProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';

export interface InputFieldConfig extends ExperimentAttributeConfig, InputProps {}

/**
 * Field that will allow user to enter a single line input.
 * Reference: https://polaris.a2z.com/components/awsui-input/?example=with-value
 */
export abstract class InputField<T extends  ExperimentAttributeProps> extends ExperimentAttribute<T> {
    protected displayConfig!: InputFieldConfig;

    parseValueFromEvent = (event: CustomEvent<InputProps.ChangeDetail>): string => event.detail.value;

    setValue = async(newValue: string) => {
        const { isValid, errorText } = this.validate(newValue, this.validationRules);
        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            value: newValue,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };

        await new Promise((resolve) => this.setState({ displayValue: newValue, validity: isValid }, () => resolve(newValue)));
    }

    getPolarisElement = () => <Input data-testid={this.props['data-testid']} {...this.displayConfig}/>
}
