import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { MessageBox, MessageType } from '../../common/MessageBox';
import { PageSection } from '../../layout/PageSection';

const PageNotFound: FunctionComponent = () => (
    <PageSection testId={'error'}>
        <MessageBox testId={'errorpage'} type={MessageType.ERROR}>
            <Typography variant='h5'>Hello!</Typography>
            <Typography variant='h6'>It appears as though you are lost. The path you entered does not match any expected paths.</Typography>
            <Typography variant='h6'>Either the Limestone team is working on it, or there is a typo in the url</Typography>
            <Typography variant='body2'>Error 404</Typography>
        </MessageBox>
    </PageSection>
);

export default PageNotFound;
