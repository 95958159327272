import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';
import { convertToAbsoluteValue } from '../common/DateRangeSelector';
import { convertUTCToLocalDate } from '../utils/date-utils';
import { LimestoneExperiment, TagDto } from '@amzn/limestone-experiment-portal-types';

export const isTestExperiment = (experiment: LimestoneExperiment) =>
    (experiment.metadata.tags.payloadValue).some((tag: TagDto) => tag.tag.toLowerCase() === 'test');

/**
 * Filter a list of experiments by start date and a date range.
 *
 * @param experiments experiment to filter
 * @param dateRange {@link DateRangePickerProps.Value} or null value
 * @return a list of experiments of which start date is within the given range
 */
export const filterExperimentsByDate = (experiments: LimestoneExperiment[], dateRange: DateRangePickerProps.Value | null) => {
    if (dateRange == null) {
        return experiments;
    } else {
        const absoluteRange = convertToAbsoluteValue(dateRange);
        return experiments.filter((experiment) =>
            (new Date(experiment.metadata.startDate.payloadValue).getTime() - convertUTCToLocalDate(new Date(absoluteRange.startDate)).getTime() >= 0)
            && (new Date(experiment.metadata.startDate.payloadValue).getTime() - convertUTCToLocalDate(new Date(absoluteRange.endDate)).getTime() <= 0));
    }
};
