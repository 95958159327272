export default {
    'aws_project_region': 'us-east-1',
    'aws_cognito_identity_pool_id': 'us-east-1:fa46f4e4-aa77-4554-9351-7053c6297da1',
    'aws_cognito_region': 'us-east-1',
    'aws_user_pools_id': 'us-east-1_n6gCJawei',
    'aws_user_pools_web_client_id': '1frc179j3h6iiide0hq0nbg5j5',
    'oauth': {
        'domain': 'limestone-gamma.auth.us-east-1.amazoncognito.com',
        'scope': [
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        'redirectSignIn': 'https://gamma.limestone.strx.amazon.dev',
        'redirectSignOut': 'https://gamma.limestone.strx.amazon.dev',
        'responseType': 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    'federationTarget': 'COGNITO_USER_POOLS',
};