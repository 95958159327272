import { AttributeLabels, DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DateField, DateFieldConfig } from '../fields/DateField';


export class DownstreamMetricRequestStartDate extends DateField {
    protected displayConfig: DateFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { displayMode } = props;

        this.validationRules = { required: true };

        this.displayConfig = {
            label: AttributeLabels.START_DATE,
            editable: props.editable === true,
            touched: false,
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            onChange: (event) => this.onChangeEvent(event as CustomEvent<any>, MetadataAttribute.START_DATE),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    isDateEnabled(date: Date): boolean {
        // Default data collection date is the next day of today, start date should before data collection date
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (date > yesterday) {
            return false;
        }

        return true;
    }
}
