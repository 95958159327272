import { ExperimentType, LifecycleType } from '@amzn/limestone-experiment-portal-types';

const EXPERIMENT_TYPE_LIFECYCLE_TYPE_MAP = new Map<LifecycleType, ExperimentType>([
    [LifecycleType.BLOCK_LIST, ExperimentType.LISTER_EXPERIMENT],
    [LifecycleType.BLOCK_LIST_BOUNDARIES_OVERRIDE, ExperimentType.LISTER_EXPERIMENT],
    [LifecycleType.RESULT_ANALYSIS_ONLY, ExperimentType.RESULT_ANALYSIS_ONLY]
]);

/**
 * Convert an experiment lifecycle type to an {@link ExperimentType}.
 *
 * @param lifecycleType lifecycle type to convert
 * @return experiment type
 */
export const convertLifecycleTypeToExperimentType = (lifecycleType: LifecycleType): ExperimentType => {
    return EXPERIMENT_TYPE_LIFECYCLE_TYPE_MAP.get(lifecycleType)!;
};

