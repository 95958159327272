import AWS from 'aws-sdk';
import { S3Client, ListObjectsV2Command, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { downloadFile, FileFormat } from '../../utils/file-utils';
import { ApiHandler } from '../api-handler';
import { AwsServicesApiHandler } from './aws-services-handler';
import { EndpointMap, CDCAccountRegionMap, CDC_AWS_ACCOUNTS, Realm } from '@amzn/limestone-experiment-portal-types';
import { Upload } from '@aws-sdk/lib-storage';

export default class AwsServicesApiHandlerImpl extends ApiHandler implements AwsServicesApiHandler {
    artifactsBucketRegion = this.awsRegion;
    artifactsBucketName = this.stage + '-' + this.awsRegion+'-experiment-artifact-files';

    public constructor(realm: Realm) {
        super(realm, new EndpointMap({}));

        if(this.stage === 'dev') {
            //dev only has gamma na stage, so always use us east 1
            this.artifactsBucketRegion = this.stage === 'dev'? 'us-east-1' : this.awsRegion;
            this.artifactsBucketName = this.stage + '-' + this.artifactsBucketRegion +'-experiment-artifact-files-722725944636';
        }
    }

    public downloadSelectionAnalysisFile = async(experimentId: string, s3FileUri: string): Promise<void> => {
        await this.setupAWSCredentials();

        const s3BucketNameParts = s3FileUri.split('//')[1].split('/', 3);

        new AWS.S3().getObject({
            Bucket: s3BucketNameParts[0],
            Key: s3BucketNameParts[1] + '/' + s3BucketNameParts[2]
        }, (error, data) => {
            if (error) {
                console.error(error);
            } else {
                const result = new TextDecoder().decode(data.Body as any);
                downloadFile(result.split('\n'), `${experimentId}-selection-analysis`, FileFormat.TSV);
            }
        });
    }

    public listExperimentArtifacts = async(experimentId: string): Promise<AWS.S3.ObjectList> => {
        let credentials = await this.setupAWSCredentials();
        let res:AWS.S3.ObjectList = [];

        let client = new S3Client({
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            },
            region: this.artifactsBucketRegion
        });

        let listObjectsOutput = await client.send(new ListObjectsV2Command({
            Bucket: this.artifactsBucketName,
            Prefix: experimentId+'/', }));

        if(listObjectsOutput.Contents){
            res = listObjectsOutput.Contents;
        }

        return res;
    }

    public uploadExperimentArtifacts = async(experimentId: string, files:File[]): Promise<AWS.S3.ObjectList> => {
        let credentials = await this.setupAWSCredentials();
        let res:AWS.S3.ObjectList = [];

        let client = new S3Client({
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            },
            region: this.artifactsBucketRegion,
        });

        for (var i = 0; i < files.length; i++) {
            await client.send(new PutObjectCommand({
                Bucket: this.artifactsBucketName,
                Body:files[i],
                Key: experimentId+'/'+files[i].name }));
        }
        //update artifacts list after upload
        await this.listExperimentArtifacts(experimentId).then(
            (result)=>{
                res=result;
            }
        );
        return res;
    }

    public downloadExperimentArtifact = async(experimentId: string, Key: string): Promise<string> => {
        await this.setupAWSCredentials();
        var res:string = '';

        await new AWS.S3({ signatureVersion: 'v4', region:this.artifactsBucketRegion }).getSignedUrlPromise('getObject',{
            Bucket: this.artifactsBucketName,
            Key: experimentId+'/'+Key,
        }).then((file)=> {
            return res=file;
        });

        return res;
    }

    public deleteExperimentArtifact = async(experimentId: string, Key: string): Promise<AWS.S3.ObjectList> => {
        let credentials = await this.setupAWSCredentials();
        let res:AWS.S3.ObjectList = [];

        let client = new S3Client({
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            },
            region: this.artifactsBucketRegion,
        });

        await client.send(new DeleteObjectCommand({
            Bucket: this.artifactsBucketName,
            Key: experimentId+'/'+Key }));

        //update artifacts list after delete
        await this.listExperimentArtifacts(experimentId).then(
            (result)=>{
                res=result;
            }
        );
        return res;
    }

    public uploadCustomerTriggersFile = async(customerTriggersFile: File, experimentId: string, marketplaceId: number, collectionDate: string): Promise<Upload> => {
        const credentials = await this.setupAWSCredentials();
        const collectionDateParts = collectionDate.split('-');
        const objectKey = [marketplaceId, collectionDateParts[0], collectionDateParts[1], collectionDateParts[2], experimentId].join('/');
        const deploymentGroupName = this.stage.concat('-').concat(this.realm);
        const awsRegion = CDCAccountRegionMap.get(deploymentGroupName);
        const accountId = CDC_AWS_ACCOUNTS.get(deploymentGroupName);
        const bucketName = `reses-custom-customer-triggers-${this.stage}-${awsRegion}-${accountId}`;

        return new Upload({
            client: new S3Client({
                credentials: {
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken
                },
                region: this.awsRegion,
            }),
            params: {
                Bucket: bucketName,
                Key: objectKey + '/' + customerTriggersFile.name,
                ContentType: customerTriggersFile.type,
                Body: customerTriggersFile,
            },
            queueSize: 16,
            partSize: 1024 * 1024 * 50, // 50MB
        });
    }
}
