import React, { Component } from 'react';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { CDC_DATA_NOT_READY_CODE,
    Realm,
    GetCustomSelectionsTransactionalMetricResponse } from '@amzn/limestone-experiment-portal-types';
import { SelectionOutput } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { MetricsPageDisplay } from './MetricsPageDisplay';
import { NonCancelableCustomEvent, SelectProps  } from '@amzn/awsui-components-react-v3';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { constructDate, getNextSaturday } from '../../utils/date-utils';

export interface CustomEvaluationMetricsSubSectionProps {
    realm: Realm;
    selections: SelectionOutput[];
    setNotification: Function;
    experiment: LimestoneExperiment;
    userAttributes: UserAttributes;
}

export interface CustomEvaluationMetricsSubSectionState {
    selections: SelectionOutput[];
    dataNotReadySelections: SelectionOutput[];
    availableDays: Set<string>;
    tableGroups: DataTableGroupDefinition[];
    showSpinner: boolean;
}

export class CustomEvaluationMetricsSubSection extends Component<CustomEvaluationMetricsSubSectionProps, CustomEvaluationMetricsSubSectionState> {
    public dataCollectionAPI: CdcApiHandler;
    public experimentServiceAPI: LemsApiHandler;

    constructor(props: CustomEvaluationMetricsSubSectionProps) {
        super(props);
        this.state = {
            selections: props.selections,
            dataNotReadySelections: [],
            availableDays: new Set(),
            tableGroups: [],
            showSpinner: false,
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchAvailableDates();
    }

    componentDidUpdate = async(prevProps: Readonly<CustomEvaluationMetricsSubSectionProps>) => {
        if (prevProps.selections !== this.props.selections) {
            this.setState({ tableGroups: [], availableDays: new Set() });
            await this.fetchAvailableDates();
        }
    }

    fetchAvailableDates = async() => {
        this.setState({ showSpinner: true });

        this.props.selections.forEach(async(selection) => {
            await this.dataCollectionAPI.getCompletedCollectionDates(selection.selectionId, 'CUSTOM_SELECTION_WEEKLY_TRANSACTIONAL_METRIC')
                .then((dates: string[]) => {
                    const availabileDaysSet = this.state.availableDays;
                    dates.forEach((date) => availabileDaysSet.add(date));
                    this.setState({ availableDays: availabileDaysSet });
                })
                .catch((error: any) => {
                    handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getCustomEvaluationRequests.FAIL!);
                })
                .finally(() => this.setState({ showSpinner: false }));
        });
    }

    fetchCustomEvaluationRequestMetrics = async(selectedDay: string) => {
        this.setState({ showSpinner: true });

        await this.dataCollectionAPI.getCustomSelectionsTransactionalMetric(this.props.selections.map((selection) => selection.selectionId), selectedDay)
            .then((response: GetCustomSelectionsTransactionalMetricResponse) => {
                const dataTableGroupDefinitions: DataTableGroupDefinition[] = [];
                Object.values(response.selectionIdToTableMap)
                    .map((metricsTable) => TransactionalMetricsAdaptor.convertMetricsTableToDataTableGroup(metricsTable))
                    .forEach((definitions) => dataTableGroupDefinitions.push(...definitions));

                this.setState({
                    tableGroups: dataTableGroupDefinitions,
                    dataNotReadySelections: this.props.selections.filter((selection) => response.dataNotReadySelectionIds.includes(selection.selectionId))
                });
            })
            .catch((error: any) => {
                if (error.response && error.response.status === CDC_DATA_NOT_READY_CODE) {
                    this.setState({ dataNotReadySelections: this.props.selections });
                } else {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getCustomEvaluationRequests.FAIL!);
                }

                this.setState({ dataNotReadySelections: this.props.selections, tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    onDateChange = async(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        await this.setState({ tableGroups: [] });
        await this.fetchCustomEvaluationRequestMetrics(event.detail.selectedOption.value!,);
    }

    render() {
        return (
            <MetricsPageDisplay
                id={'custom-evaluation-display'}
                experiment={this.props.experiment}
                tableGroups={this.state.tableGroups}
                tableKey='Custom Evaluation Requests'
                dataNotReadyWarning={`Custom evaluation requests are not ready for the following selections: ${this.state.dataNotReadySelections.map((selection) => selection.title).join(', ')}`}
                availableDays={this.props.userAttributes.isAdmin
                    ? [...this.state.availableDays]
                    : [...this.state.availableDays].filter((date) => constructDate(date) <= getNextSaturday(constructDate(this.props.experiment.metadata.endDate.payloadValue)))}
                showSpinner={this.state.showSpinner}
                onDateChangeFunction={this.onDateChange}
            />
        );
    }
};
