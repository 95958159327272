import React, { FunctionComponent } from 'react';
import ReactErrorBoundary, { FallbackProps } from 'react-error-boundary';
import { MessageBox, MessageType } from './MessageBox';

export const FallbackComponent: FunctionComponent<FallbackProps> = ({ componentStack, error }) => (
    <MessageBox testId={'errorboundary'} type={MessageType.ERROR}>
        <b>Error</b>: An error occurred while rendering a component: <i>{error!.message}</i>
        <br /><br />Try refreshing the page, and please contact our team if this issue persists.<br /><br />
        <b>ComponentStack:</b>
        {componentStack}
    </MessageBox>
);

export const ErrorBoundary: FunctionComponent = ({ children }) => (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>
);
