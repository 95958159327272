import React, { FunctionComponent } from 'react';
import { Box, Button, PieChart } from '@amzn/awsui-components-react-v3';

export interface PieChartDataObject {
    title: string;
    value: number;
    color?: string;
}

export interface CustomPieChartProps {
    data: PieChartDataObject[];
    errorText?: string;
    isPercentage?: boolean;
}

export const CustomPieChart: FunctionComponent<CustomPieChartProps> = (props: CustomPieChartProps) => {
    return (
        <PieChart
            data={props.data.map((dataObject) => {
                return {
                    title: dataObject.title,
                    value: dataObject.value,
                    color: dataObject.color 
                };
            })}
            detailPopoverContent={(datum) => [
                {
                    key: props.isPercentage ? 'Percentage' : 'Count',
                    value: props.isPercentage ? `${(datum.value * 100).toFixed(0)}%` : datum.value.toLocaleString()
                }
            ]}
            segmentDescription={(datum) => props.isPercentage ? `${(datum.value * 100).toFixed(0)}%` : `Count: ${Number(datum.value).toLocaleString()}`}
            i18nStrings={{
                detailsValue: 'Value',
                detailsPercentage: 'Percentage',
                filterLabel: 'Filter displayed data',
                filterPlaceholder: 'Filter data',
                filterSelectedAriaLabel: 'selected',
                detailPopoverDismissAriaLabel: 'Dismiss',
                legendAriaLabel: 'Legend',
                chartAriaRoleDescription: 'pie chart',
                segmentAriaRoleDescription: 'segment'
            }}
            hideFilter
            hideLegend
            errorText={props.errorText}
            loadingText='Loading chart'
            recoveryText='Retry'
            empty={
                <Box textAlign='center' color='inherit'>
                    <b>No data available</b>
                    <Box variant='p' color='inherit'>
              There is no data available
                    </Box>
                </Box>
            }
            noMatch={
                <Box textAlign='center' color='inherit'>
                    <b>No matching data</b>
                    <Box variant='p' color='inherit'>
                There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                </Box>
            }
        />
    );
};