import * as FileUtils from '../../utils/file-utils';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { ExperimentAttributeProps, ExperimentAttributeState } from '../ExperimentAttribute';
import {
    AttributeLabels,
    DisplayMode,
    FileValidationRules,
    LimestoneExperimentBoundaries,
    RegionSelectionAttribute
} from '@amzn/limestone-experiment-portal-types';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';

export interface RegionFileProps extends ExperimentAttributeProps {
    definitionType: string;
    allowOverlappingBoundaries: boolean;
    otherBoundary?: LimestoneExperimentBoundaries;
    regionSelectionAttribute: RegionSelectionAttribute;
}

export interface TreatmentRegionFileConfig extends FileFieldConfig {
    validBoundaries: string[];
    overlappingBoundaries: string[];
}

export class RegionFile extends FileField<RegionFileProps> {
    protected displayConfig: TreatmentRegionFileConfig;
    protected validationRules: FileValidationRules;
    private regionServiceAPIHandler: RMSApiHandler;
    private experimentServiceAPIHandler: LemsApiHandlerImpl;

    constructor(props: RegionFileProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 40000000,
            duplicateCheck: true,
            emptyCheck: true,
        };

        this.displayConfig = {
            label: this.props.regionSelectionAttribute === RegionSelectionAttribute.TREATMENT_BOUNDARIES
                ? AttributeLabels.TREATMENT_REGIONS_FILE: AttributeLabels.CONTROL_REGIONS_FILE,
            editable: false,
            touched: false,
            value: [],
            validBoundaries: [],
            overlappingBoundaries: [],
            i18nStrings: {
                uploadButtonText: (e: any) => e ? '' : 'Upload Regions',
                dropzoneText: (e: any)=> e ? '' : 'Drop file',
                removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error'
            },
            showFileLastModified: true,
            showFileSize: true,
            showFileThumbnail: true,
            tokenLimit: 1,
            onChange: (event: any) => this.onChangeEvent(event, this.props.regionSelectionAttribute)
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };

        this.regionServiceAPIHandler = new RMSApiHandler(this.props.realm!);
        this.experimentServiceAPIHandler = new LemsApiHandlerImpl(this.props.realm!);
    }

    componentDidMount = async() => {
        await this.getBoundaryOptions();

        if (this.props.initialValue && this.props.initialValue.length !== 0) {
            await this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate = async(prevProps: Readonly<RegionFileProps>, prevState: Readonly<ExperimentAttributeState>, snapshot?: any) => {
        if (this.props.definitionType !== prevProps.definitionType || this.props.allowOverlappingBoundaries !== prevProps.allowOverlappingBoundaries) {
            await this.getBoundaryOptions();
            await this.setValue([new File([], 'empty_file.csv')]);
        }
    }

    getBoundaryOptions = async() => {
        this.setState({ editInProgress: true });
        const decryptedMarketplaceId = decryptCustomerId(this.props.marketplaceId!);
        const marketplace = INVERTED_MARKETPLACE_MAP[Number(decryptedMarketplaceId)];

        /* This code block is to get usable boundaries as displayed options for customers to choose. It:
        * 1. Calls RMS to get all boundaries in the experiment's region definition type(e.g., ZIP3). If overlap is allowed, skip remaining steps.
        * 2. Calls LEMS to get all treatment boundaries used in other active experiments.
        * 3. Calls RMS to convert these active treatment boundaries to the boundaries in experiment's region definition type(ZIP3).
        */
        // Step 1
        const validBoundaries = await this.regionServiceAPIHandler.getBoundariesInDefinitionType(marketplace, this.props.definitionType);
        this.displayConfig.validBoundaries = validBoundaries.map((boundary) => boundary.boundaryName);

        if (!this.props.allowOverlappingBoundaries) {
            // Step 2
            const treatmentBoundariesInUse = await this.experimentServiceAPIHandler.getActiveTreatmentBoundaries(this.props.marketplaceId!, this.props.startDate!, this.props.endDate!);
            const activeTreatmentBoundaries = treatmentBoundariesInUse.map((experimentBoundary) => {
                return {
                    boundaryName: experimentBoundary.boundary.boundaryId!,
                    definitionType: experimentBoundary.boundaryDefinitionType
                };
            });
            // Step 3
            const activeBoundariesInGivenDefinition = await this.regionServiceAPIHandler.getOverlappingBoundariesInDefinitionType(marketplace, this.props.definitionType, activeTreatmentBoundaries);
            this.displayConfig.overlappingBoundaries = activeBoundariesInGivenDefinition;
        }

        this.setState({ editInProgress: false });

        this.forceUpdate();
    }

    setValueFromPayload = async(newContent: File | string) => {
        if (newContent instanceof File) {
            this.setValue([newContent]);
        }
    }

    validateAdditionalRules = (_value: File, fileContent: any, _validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (fileContent) {
            const invalidBoundaries = fileContent.filter((boundary: string) => !this.displayConfig.validBoundaries.includes(boundary));

            if (invalidBoundaries.length > 0) {
                isValid = false;
                errors.push(`${invalidBoundaries} are invalid boundary(s) in the file, please refer to our region definition format.`);
            }

            const overlappingBoundaries = fileContent.filter((boundary: string) => this.displayConfig.overlappingBoundaries.includes(boundary));
            if (overlappingBoundaries.length > 0 && !this.props.allowOverlappingBoundaries) {
                isValid = false;
                errors.push(`${overlappingBoundaries} exist in other experiments, 
                please either allow overlapping boundaries or remove these boundaries.`);
            }
            if (this.props.otherBoundary && this.props.otherBoundary.boundaries.payloadValue) {
                const overlappingLocalBoundaries = fileContent.filter((boundary: string) => this.props.otherBoundary!.boundaries.payloadValue.includes(boundary));
                  
                if (overlappingLocalBoundaries.length > 0) {
                    isValid = false;
                    errors.push(`${overlappingLocalBoundaries} are overlapping boundary(s) present in the file, please remove boundary(s) from either treatment or control region`);
                }
            }
        }

        return { isValid, errors };
    }

    extractContentFromFile = async(file: File): Promise<string[]> => {
        const regionsArr = await FileUtils.convertCSVToJSON(file);
        return regionsArr.map((region: string[]) => region[0].trim()).filter((region: string) => region && region !== '');
    };
}
