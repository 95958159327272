import axios from 'axios';
import { LemsApiHandler } from './lems-api-handler';
import { LEMS_API_ENDPOINTS, LemsApiPaths } from '../api-endpoints';
import { BaseEntityType, LifecycleType, PreSignedUrlType, Realm } from '@amzn/limestone-experiment-portal-types';
import * as MetadataAdaptor from '../adaptors/metadata-adaptor';
import * as ReadExperimentAdaptor from '../adaptors/read-experiment-adaptor';
import * as ProductSelectionAdaptor from '../adaptors/product-selection-adaptor';
import { marketplaceIdToMerchantId } from '../adaptors/product-selection-adaptor';
import * as RegionSelectionAdaptor from '../adaptors/region-selection-adaptor';
import * as ExperimentApproverAdaptor from '../adaptors/experiment-approver-adaptor';
import { OfferValidationStatusType } from '@amzn/limestone-experiment-portal-types';
import * as LambdaModelTypes from '@amzn/limestone-experiment-portal-types';
import {
    EntityType,
    GetSelectionsInExperimentOutput,
    GetUnavailableRunDatesResponse,
    OrchestrationStep,
    OrchestrationSystemOutcome,
    SendOrchestratorCompletionEventInput,
    UpdateExperimentStatusInput,
    LimestoneExperiment,
    LimestoneExperimentBoundaries,
    LimestoneExperimentMetadata,
    PowerAnalysisResult,
} from '@amzn/limestone-experiment-portal-types';
import { ApprovalStatusType } from '../../../enums/ApprovalStatusType';
import { Approver,  BoundarySet, convertBoundarySetToObject, MetricType, UserRequestType } from '@amzn/limestone-experiment-portal-types';
import { ExperimentStatusType } from '../../../enums/ExperimentStatus';
import { ApiHandler } from '../../api-handler';
import { ExperimentWorkflowType } from '../../../common/ExperimentWorkflowType';
import { ExperimentRegionType } from '../../../enums/ExperimentRegionType';

export default class LemsApiHandlerImpl extends ApiHandler implements LemsApiHandler {
    public constructor(realm: Realm) {
        super(realm, LEMS_API_ENDPOINTS);
    }

    public uploadMetadata = async(metadata: LimestoneExperimentMetadata): Promise<LambdaModelTypes.CreateExperimentResponse> => {
        await this.authenticate();

        const payload = MetadataAdaptor.convertMetadataToPayload(metadata);
        const createExperimentResponse: LambdaModelTypes.CreateExperimentResponse = {
            experimentId: '',
            experimentIntegerId: -1,
            success: false
        };

        const response = await this.sendHttpPostRequest(LemsApiPaths.EXPERIMENT, payload);

        if (!response) {
            console.error('No response from experiment service');
        }

        if (response.hasOwnProperty('experimentId')) {
            createExperimentResponse.success = true;
            createExperimentResponse.experimentId = response.experimentId;
            createExperimentResponse.experimentIntegerId = response.experimentIntegerId;
        } else {
            console.error('Invalid Response: ', response);
        }

        return createExperimentResponse;
    }

    createExperimentUserRequest = async(experimentId: string, requestType: string ): Promise<LambdaModelTypes.ExperimentUserRequestOutput> => {
        await this.authenticate();

        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}`;

        const payload = {
            experimentId: experimentId,
            requestType: requestType,
            parentEntityType: BaseEntityType.EXPERIMENT,
        };

        const response = await this.sendHttpPostRequest(url, payload);

        if (!response) {
            console.error('No response from experiment service');
        }

        const createExperimentUserRequestOutput: LambdaModelTypes.ExperimentUserRequestOutput = {
            requestId: '',
            requestType: '',
            validationStatus: OfferValidationStatusType.NOT_STARTED,
            validationFailureReasons: '',
            requestStatus: '',
            requesterAlias: ''
        };

        if (response.hasOwnProperty('experimentUserRequestOutput')) {
            createExperimentUserRequestOutput.requestId = response.experimentUserRequestOutput.requestId;
            createExperimentUserRequestOutput.requestType = response.experimentUserRequestOutput.requestType;
            createExperimentUserRequestOutput.validationStatus = response.experimentUserRequestOutput.validationStatus;
            createExperimentUserRequestOutput.validationFailureReasons = response.experimentUserRequestOutput.validationFailureReasons;
            createExperimentUserRequestOutput.requestStatus = response.experimentUserRequestOutput.requestStatus;
        }

        return createExperimentUserRequestOutput;
    }

    public getPresignedS3Url = async(entityId: string, artifactType: LambdaModelTypes.ArtifactType, marketplaceId: string, baseEntityType: BaseEntityType, preSignedUrlType: PreSignedUrlType, contentType: string): Promise<string> => {
        const url = preSignedUrlType === PreSignedUrlType.UPLOAD ? LemsApiPaths.UPLOAD_ARTIFACT : LemsApiPaths.DOWNLOAD_ARTIFACT;
        const response = await this.sendHttpGetRequest(url, { params: { experimentId: entityId, artifactType, marketplaceId, baseEntityType, contentType }, headers: this.config.headers });

        return response.presignedUrl;
    }

    public uploadProductSelection = async(asins: string[], entityId: string, marketplaceId: string, baseEntityType: BaseEntityType): Promise<boolean> => {
        const offers = ProductSelectionAdaptor.convertAsinsToExperimentOffers(asins!, this.stage, marketplaceId);
        const uploadFile = new File([JSON.stringify(offers, null, 2)], '1_product-selection.json', { type: 'application/json' });
        return this.uploadProductSelectionFile('application/json', uploadFile, entityId, marketplaceId, baseEntityType);
    }

    uploadProductSelectionFile = async(contentType: string, selectionFile: File, entityId: string, marketplaceId: string, baseEntityType: BaseEntityType): Promise<boolean> => {
        const preSignedUrl = await this.getPresignedS3Url(entityId, LambdaModelTypes.ArtifactType.PRODUCT_SELECTION, marketplaceId, baseEntityType, PreSignedUrlType.UPLOAD, contentType);

        if (preSignedUrl === '') {
            console.error('Error in retrieving Pre Signed Url');
            return false;
        }

        const response = await axios.put(preSignedUrl, selectionFile, {
            headers: {
                'Content-Type': contentType,
            }
        });

        if (!response) {
            console.error('Error in sending product selection file to S3');
            return false;
        }

        return true;
    }

    public executeExperiment = async(experimentId: string, workflowType: ExperimentWorkflowType): Promise<boolean> => {
        await this.authenticate();
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/workflow-type/${workflowType}/execute`;

        const response = await this.sendHttpPostRequest(url, {
            entityType: EntityType.EXPERIMENT,
        });
        return response.success;
    }

    public readExperiment = async(experimentId: string, _experimentIntegerId: number): Promise<LimestoneExperiment> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id`;
        const response = await this.sendHttpGetRequest(url, this.getConfig());

        return ReadExperimentAdaptor.convertReadResponseToExperiment(response.experiment);
    }

    public getExperimentsByUserAlias = async(userAlias: string, userRoles: LambdaModelTypes.ExperimentUserRoleType[]): Promise<LimestoneExperiment[]> => {
        await this.authenticate();

        const url = `${LemsApiPaths.EXPERIMENTS}/user/${userAlias}`;
        const payload: LambdaModelTypes.GetExperimentsByUserAliasRequest = {
            userAlias,
            userRoles
        };

        const response = await this.sendHttpPutRequest(url, payload);

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) =>
            ReadExperimentAdaptor.convertReadResponseToExperiment(experiment)
        );
    }

    public getExperimentsInGivenStatuses = async(statusList: ExperimentStatusType[]): Promise<LimestoneExperiment[]> => {
        const url = LemsApiPaths.GET_EXPERIMENTS_IN_GIVEN_STATUSES;
        const payload = {
            experimentStatuses: statusList
        };

        const response = await this.sendHttpPutRequest(url, payload);

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => {
            return ReadExperimentAdaptor.convertReadResponseToExperiment(experiment);
        });
    }

    public getExperimentsByStatus = async (status: string): Promise<LimestoneExperiment[]> => {
        const url = LemsApiPaths.GET_EXPERIMENTS_BY_STATUS + '/' + status;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => {
            return ReadExperimentAdaptor.convertReadResponseToExperiment(experiment);
        });
    }

    public getExperimentById = async (experimentId: string): Promise<LimestoneExperiment> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return ReadExperimentAdaptor.convertReadResponseToExperiment(response.experiment);
    }

    public getAllExperimentBoundaries = async(experimentId: string, experimentRegionType: string): Promise<LimestoneExperimentBoundaries> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundaries?experimentRegionType=${experimentRegionType}`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: {
                experimentId,
                experimentRegionType
            }
        });

        return RegionSelectionAdaptor.convertGetBoundariesResponseToDisplay(response);
    }

    public updateExperimentTitle = async(experimentId: string, updatedTitle: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/title`;
        const payload: LambdaModelTypes.UpdateExperimentTitlePayload = { experimentId, title: updatedTitle };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public updateExperimentDescription = async(experimentId: string, updatedDescription: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/description`;
        const payload: LambdaModelTypes.UpdateExperimentDescriptionPayload = { experimentId, description: updatedDescription };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addSecondaryOwner = async(experimentId: string, newSecondaryOwner: LambdaModelTypes.OwnerDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/owners/secondary/add`;
        const payload: LambdaModelTypes.AddSecondaryOwnerPayload = { experimentId, owner: newSecondaryOwner };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeSecondaryOwner = async(experimentId: string, secondaryOwner: LambdaModelTypes.OwnerDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/owners/secondary/remove`;
        const payload: LambdaModelTypes.RemoveSecondaryOwnerPayload = { experimentId, owner: secondaryOwner };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addObserver = async(experimentId: string, newObserver: LambdaModelTypes.ObserverDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/observers/add`;
        const payload: LambdaModelTypes.AddObserverPayload = { experimentId, observer: newObserver };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeObserver = async(experimentId: string, observer: LambdaModelTypes.ObserverDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/observers/remove`;
        const payload: LambdaModelTypes.RemoveObserverPayload = { experimentId, observer };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addTag = async(experimentId: string, newTag: LambdaModelTypes.TagDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/tags/add`;
        const payload: LambdaModelTypes.AddTagPayload = { experimentId, tag: newTag };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeTag = async(experimentId: string, tag: LambdaModelTypes.TagDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/tags/remove`;
        const payload: LambdaModelTypes.RemoveTagPayload = { experimentId, tag };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public getAllOffersInExperiment = async(entityId: string, marketplaceId: string): Promise<LambdaModelTypes.ExperimentOfferDto[]> => {
        const allOffers: LambdaModelTypes.ExperimentOfferDto[] = [];
        let nextToken: string|null = null, result: LambdaModelTypes.GetExperimentOffersPaginatedResponse;
        const request: LambdaModelTypes.GetExperimentOffersPaginatedRequest = {
            entityId,
            marketplaceId,
            maxResults: 7500
        };

        do {
            request.nextToken = nextToken;
            result = await this.sendHttpPostRequest('entity/offers/paginated', request);
            allOffers.push(...result.experimentOffers);
            nextToken = result.nextToken;
        } while (nextToken !== null && result.experimentOffers.length !== 0);

        return allOffers;
    }

    public getExperimentsByApprover = async(alias: string): Promise<LimestoneExperiment[]> => {
        const url = `${LemsApiPaths.EXPERIMENTS}/approver/${alias}`;
        const response: LambdaModelTypes.GetExperimentsByApproverResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => ReadExperimentAdaptor.convertReadResponseToExperiment(experiment));
    }

    public submitApprovalDecision = async(experimentId: string, role: string, alias: string, updatedStatus: ApprovalStatusType, rablRegionId: string, discriminator: string, rejectionReason?: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/approver/submit`;
        const payload: LambdaModelTypes.SubmitApprovalDecisionPayload = { approverAlias: alias, approverRole: role, updatedStatus, rejectionReason, rablRegionId, discriminator };
        const response: LambdaModelTypes.SubmitApprovalDecisionResponse = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public getAllExperimentApprovers = async(experimentId: string): Promise<Approver[]> => {
        const url =  `${LemsApiPaths.EXPERIMENT}/${experimentId}/approvers`;
        const response: LambdaModelTypes.GetAllExperimentApproversResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.approvers.map((approverDto: LambdaModelTypes.ExperimentApproverDto) => ExperimentApproverAdaptor.convertApproverDtoToApprover(approverDto));
    }

    public getStaticConfigurationData = async(realm: string): Promise<string> => {
        const url = `${LemsApiPaths.GET_STATIC_CONFIGURATION_DATA}/${realm}`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: { realm }
        });

        return response.configurations;

    }

    public updateStaticConfigurationData = async(newConfiguration: string, realm: string): Promise<boolean> => {
        const url = `${LemsApiPaths.UPDATE_STATIC_CONFIGURATION_DATA}`;
        const payload = {
            realm: realm,
            newConfiguration: newConfiguration,
        };
        const response = await this.sendHttpPutRequest(url, payload);
        return response.success;
    }

    getExperimentUserRequestsByRequestType = async (requestType: UserRequestType, experimentId: string): Promise<LambdaModelTypes.ExperimentUserRequestOutput[]> => {
        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}/experimentId/${experimentId}/request-type/${requestType}`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experimentUserRequests;
    }

    getExperimentUserRequestOffers = async (requestId: string): Promise<LambdaModelTypes.ExperimentOfferDto[]> => {
        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}/requestId/${requestId}/offers`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experimentOffers;
    }

    executeExperimentUserRequest = async(requestId: string, workflowType: ExperimentWorkflowType): Promise<boolean> => {
        await this.authenticate();

        const url = `${LemsApiPaths.EXPERIMENT}/${requestId}/workflow-type/${workflowType}/execute`;

        const response = await this.sendHttpPostRequest(url, {
            entityType: EntityType.EXPERIMENT_USER_REQUEST
        });

        if (!response) {
            console.error('No response from experiment service');
            return false;
        }

        return response.success;
    }

    finalizeExperimentBoundaries = async(experimentId: string, finalBoundarySet: BoundarySet): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundaries/finalize`;

        const boundarySet = convertBoundarySetToObject(finalBoundarySet);
        const payload = { experimentId, finalBoundarySet: boundarySet };

        const response: LambdaModelTypes.FinalizeExperimentBoundariesResponse = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    getExperimentBoundaryOptions = async(experimentId: string): Promise<BoundarySet[]> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundary-sets`;
        const response: LambdaModelTypes.GetExperimentBoundaryOptionsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return RegionSelectionAdaptor.convertBoundarySetDtosToBoundarySet(response.boundarySets);
    }

    getPreExperimentSelectionAnalysisResult = async(experimentId: string): Promise<LambdaModelTypes.PreExperimentSelectionAnalysisResultDto|undefined> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/preExperimentAnalysis/selection`;
        const response: LambdaModelTypes.GetPreExperimentSelectionAnalysisResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.preExperimentSelectionAnalysisResult;
    }

    finalizeProductSelection = async(experimentId: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/selection/finalize`;
        const response: LambdaModelTypes.FinalizeProductSelectionResponse = await this.sendHttpPutRequest(url, {
            experimentId,
        });

        return response.success;
    }

    manualOverrideBoundaries = async(experimentId: string, boundaries: string[]): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundary-override`;
        const response: LambdaModelTypes.ActivityTriggerResponse = await this.sendHttpPostRequest(url, {
            experimentId,
            boundaries
        });
        return response.success;
    }

    getExperimentLifecycleGraph = async(experimentId: string): Promise<LambdaModelTypes.GetExperimentLifecycleGraphResponse> => {
        const url = `${LemsApiPaths.LIFECYCLE}/graph/${experimentId}`;
        const response: LambdaModelTypes.GetExperimentLifecycleGraphResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }

    getExperimentProgressDisplay = async(experimentId: string): Promise<LambdaModelTypes.GetExperimentProgressDisplayResponse> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/progress`;
        const response: LambdaModelTypes.GetExperimentProgressDisplayResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }

    public updateExperimentStartDate = async(experimentId: string, startDate: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/runDates`;
        const payload: LambdaModelTypes.UpdateExperimentRunDatesPayload = { experimentId, experimentStartDate: startDate };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public updateExperimentEndDate = async(experimentId: string, endDate: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/runDates`;
        const payload: LambdaModelTypes.UpdateExperimentRunDatesPayload = { experimentId, experimentEndDate: endDate };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public getUnavailableRunDates = async(marketplaceId: string, asins: string[]): Promise<GetUnavailableRunDatesResponse> => {
        const url = `${LemsApiPaths.UNAVAILABLE_RUN_DATES}/marketplaceId/${marketplaceId}`;
        const payload: LambdaModelTypes.GetUnavailableRunDatesPayload = { marketplaceId, asins };
        return await this.sendHttpPutRequest(url, payload);
    }

    public createExperimentOfferSelection = async(input: LambdaModelTypes.CreateExperimentOfferSelectionInput): Promise<LambdaModelTypes.CreateExperimentOfferSelectionResponse> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${input.experimentId}/selection`;
        return await this.sendHttpPostRequest(url, input);
    }

    public addCustomEvaluationRequest = async(experimentId: string, marketplaceId: string, title: string, metricTypes: MetricType[], selectionFile: File): Promise<boolean> => {
        const response = await this.createExperimentOfferSelection({
            experimentId: experimentId,
            lifecycleType: LifecycleType.OUTPUT_METRIC_ONLY,
            merchantId: marketplaceIdToMerchantId(marketplaceId, this.stage),
            metricTypes: metricTypes,
            title: title
        });

        return await this.uploadProductSelectionFile('text/csv', selectionFile, response.selectionId, marketplaceId, BaseEntityType.SELECTION);
    }

    public getSelectionsInExperiment = async(experimentId: string): Promise<GetSelectionsInExperimentOutput> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/selections`;
        return await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });
    }

    public sendOrchestratorCompletionEvent = async(input: SendOrchestratorCompletionEventInput): Promise<boolean> => {
        const url = `${LemsApiPaths.LIFECYCLE}/${input.baseEntityId}/complete`;
        const response = await this.sendHttpPutRequest(url, input);
        return response.success;
    }


    public updateExperimentStatus = async(input: UpdateExperimentStatusInput): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${input.baseEntityId}/status`;
        const response = await this.sendHttpPutRequest(url, input);
        return response.success;
    }

    public startExperiment = async(experimentId: string, lifecycleType: LifecycleType): Promise<boolean> => {
        if (lifecycleType === LifecycleType.RESULT_ANALYSIS_ONLY) {
            return await this.sendOrchestratorCompletionEvent({
                baseEntityId: experimentId,
                baseEntityType: BaseEntityType.EXPERIMENT,
                stepName: OrchestrationStep.RESULT_ANALYSIS_SCHEDULED,
                systemOutcome: OrchestrationSystemOutcome.PASS,
                systemOutput: '',
            });
        } else {
            return this.executeExperiment(experimentId, ExperimentWorkflowType.REGIONALIZE_ASIN);
        }
    }

    public endExperiment = async(experimentId: string): Promise<boolean> => {
        return await this.sendOrchestratorCompletionEvent({
            baseEntityId: experimentId,
            baseEntityType: BaseEntityType.EXPERIMENT,
            stepName: OrchestrationStep.EXPERIMENT_RUNNING,
            systemOutcome: OrchestrationSystemOutcome.PASS,
            systemOutput: '',
        });
    }

    public cancelExperiment = async(experimentId: string, username: string): Promise<boolean> => {
        console.log('cancel experiment function');
        return await this.updateExperimentStatus({
            baseEntityId: experimentId,
            baseEntityType: BaseEntityType.EXPERIMENT,
            updatedStatus: ExperimentStatusType.CANCELLED,
            updatedBy: username,
        });
    }

    public getCustomMetricOptions = async(): Promise<LambdaModelTypes.CustomMetricDisplayDto[]> =>{
        const url = `custom-metrics/${this.realm}/AVAILABLE_TO_SELECT`;

        const response: LambdaModelTypes.GetCustomMetricOptionsOutput = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.options;
    }

    public updateExperimentBoundaries = async(entityId: string, boundaryIds: string[], entityType: EntityType, experimentRegionType: ExperimentRegionType): Promise<boolean> => {
        const url = `entity/${entityId}/boundaries`;

        const response = await this.sendHttpPutRequest(url, {
            entityId: entityId,
            entityType: entityType,
            boundaries: boundaryIds,
            experimentRegionType: experimentRegionType,
        });

        return response.success;
    }

    public getActiveTreatmentBoundaries = async(marketplaceId: string, startDate: string, endDate: string): Promise<LambdaModelTypes.ExperimentBoundaryDto[]> => {
        const url = 'boundaries';
        return (await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: {
                marketplaceId,
                startDate,
                endDate,
            }
        })).boundaries;
    }

    public addCustomMetricsToExperiment = async(experimentId: string, metrics: LambdaModelTypes.CustomExperimentMetricDto[]): Promise<boolean> => {
        const url = `experiment/${experimentId}/metrics/add`;
        const payload: LambdaModelTypes.AddCustomMetricToExperimentInput = { experimentId, metrics };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public updateExperimentComment = async(experimentId: string, comment: string): Promise<boolean> => {
        const url = `experiment/${experimentId}/update`;
        const response = await this.sendHttpPutRequest(url, {
            experimentId,
            updateExperimentInput: {
                experimentComment: comment,
            }
        });
        return response.success;
    }

    public updateCustomerDecision = async(experimentId: string, customerDecision: string): Promise<boolean> => {
        const url = `experiment/${experimentId}/decision`;
        const response = await this.sendHttpPutRequest(url, {
            experimentId,
            customerDecision,
        });
        return response.success;
    }

    public updateBusinessRecommendation = async(experimentId: string, businessRecommendation: string): Promise<boolean> => {
        const url = `experiment/${experimentId}/update`;
        const response = await this.sendHttpPutRequest(url, {
            experimentId,
            updateExperimentInput: {
                businessRecommendation: businessRecommendation,
            }
        });
        return response.success;
    }

    public getPowerAnalysisResults = async(experimentId: string): Promise<PowerAnalysisResult[]> => {
        const url = `experiment/${experimentId}/preExperimentAnalysis/powerAnalysis`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
        });
        return response.results;
    }

    public getAllExperimentsWithASIN = async(asin: string): Promise<string[]> => {

        const url = `${LemsApiPaths.ASIN}/${asin}`;
        const response = await this.sendHttpGetRequest(url, this.getConfig());
        
        return response.experiments as string[];
    }
}
