import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { AttributeLabels, DisplayMode, DownstreamMetricRequestAttributes, FileValidationRules } from '@amzn/limestone-experiment-portal-types';

export class CustomerTriggersFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['application/zip'],
            maxSizeInBytes: 4000000000,
            emptyCheck: true,
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.RUNNING;

        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_TRIGGERS_FILE,
            editable,
            touched: false,
            value: [],
            i18nStrings: {
                uploadButtonText: (e: any) => e ? '' : 'Upload Customer Triggers',
                dropzoneText: (e: any)=> e ? 'Drop file' : '',
                removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error'
            },
            showFileSize: true,
            showFileThumbnail: true,
            tokenLimit: 1,
            multiple: false,
            constraintText: 'Upload the customer triggers file as a compressed CSV (Zip the file before you upload)',
            onChange: (event: any) => this.onChangeEvent(event, DownstreamMetricRequestAttributes.CUSTOMER_TRIGGERS_FILE)
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };

    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValueFromPayload = async(newContent: File) => {
        this.setValue([newContent]);
    }
}
