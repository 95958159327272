import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';

export class ProductFamily extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.PRODUCT_FAMILY,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.PRODUCT_FAMILY),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.displayConfig.options = [
            { label: 'Consumables', value: 'Consumables' },
            { label: 'Hardlines', value: 'Hardlines' },
            { label: 'Softlines', value: 'Softlines' },
            { label: 'Media', value: 'Media' }
        ];

        this.forceUpdate();
    }

    setValueFromPayload = async(productFamily: string) => {
        this.setValue({ value: productFamily, label: productFamily });
    }
}
