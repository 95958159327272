import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels, ExperimentTreatmentType } from '@amzn/limestone-experiment-portal-types';
import { SelectProps } from '@amzn/awsui-components-react-v3';

const CREATION_MODE_QUESTION_LABEL = 'Does this experiment restrict offer availability?';

const OFFER_RESTRICTION_OPTIONS: Array<SelectProps.Option> = [
    { value: ExperimentTreatmentType.OFFER_RESTRICTION.toString(), label: 'Yes' },
    { value: ExperimentTreatmentType.NON_OFFER_RESTRICTION.toString(), label: 'No' },
];

const SELECTION_OPTION_TO_TREATMENT_TYPE: Map<string, ExperimentTreatmentType> = new Map([
    ['Yes', ExperimentTreatmentType.OFFER_RESTRICTION],
    ['No', ExperimentTreatmentType.NON_OFFER_RESTRICTION],
]);

const DEFAULT_SELECTED_OPTION = ExperimentTreatmentType.OFFER_RESTRICTION;

const getOptionDisplayValueFromExperimentTreatmentType = (treatmentType: string) : string | null => {
    let optionDisplayValue = null;
    SELECTION_OPTION_TO_TREATMENT_TYPE.forEach((value: ExperimentTreatmentType, key: string) => {
        if (value.toString() === treatmentType) {
            optionDisplayValue = key;
        }
    });
    return optionDisplayValue;
};

const getExperimentTreatmentTypeFromOptionValue = (optionValue: string) : string | null => {
    return SELECTION_OPTION_TO_TREATMENT_TYPE.get(optionValue)!;
};

export class TreatmentType extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);
        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: displayMode === DisplayMode.CREATE ? CREATION_MODE_QUESTION_LABEL : AttributeLabels.TREATMENT_TYPE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'We do not allow multiple experiments with offer availability restriction running at the same region. '
                + 'If you choose yes, regions that other experiments are running in will be excluded.',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.TREATMENT_TYPE),
            selectedOption: null,
            options: OFFER_RESTRICTION_OPTIONS,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        const selectedTreatmentType = this.props.initialValue ? this.props.initialValue : DEFAULT_SELECTED_OPTION.toString();
        this.setValueFromPayload(selectedTreatmentType);

        if (this.props.updateFormState) {
            this.props.updateFormState(MetadataAttribute.TREATMENT_TYPE, selectedTreatmentType, selectedTreatmentType, true);
        }

        this.forceUpdate();
    }

    setValueFromPayload = async(selectedTreatmentType: string) => {
        this.setValue({ value: selectedTreatmentType, label: getOptionDisplayValueFromExperimentTreatmentType(selectedTreatmentType)! });
    }

    getPayloadValue = () => {
        return getExperimentTreatmentTypeFromOptionValue(this.state.displayValue);
    }

    getDisplayValue = () => {
        return getExperimentTreatmentTypeFromOptionValue(this.state.displayValue)!;
    }
}
