/**
 * Constructs a date object from the given date string.
 * @param yyMMdd date string in YY-MM-dd format.
 * @returns date object
 */
export const constructDate = (yyMMdd: string): Date => {
    const dateParts: number[] = yyMMdd.split('-').map((part: string) => Number.parseInt(part));
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};

/**
 * Constructs date string from the given date object.
 * @param date date object.
 * @returns date string in YY-MM-dd format.
 */
export const constructDateString = (date: Date): string => {
    const dateString = new Date(date);
    let month = '' + (dateString.getMonth() + 1);
    let day = '' + dateString.getDate();
    const year = dateString.getFullYear();

    if (month.length < 2)
    {month = '0' + month;}
    if (day.length < 2)
    {day = '0' + day;}

    return [year, month, day].join('-');
};

/**
 * Return true if two date intervals overlap.
 * @param startDate1 start of the first interval.
 * @param endDate1 end of the first interval.
 * @param startDate2 start of the second interval.
 * @param endDate2 end of the second interval.
 */
export const datesOverlap = (startDate1: Date, endDate1: Date, startDate2: Date, endDate2: Date): boolean => {
    return !(startDate1 > endDate2 || startDate2 > endDate1);
};

/**
 * Convert a {@link Date} in UTC time to the local time(PST).
 *
 * @param utcDate date in UTC
 * @return date in local time
 */
export const convertUTCToLocalDate = (utcDate: Date): Date => {
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
};

/**
 * Return the next upcoming Saturday, or the same date if already a Saturday
 * @param date
 */
export const getNextSaturday = (date: Date): Date => {
    const nextSaturday = new Date(date);
    nextSaturday.setDate(nextSaturday.getDate() + (7 - date.getDay() + 6) % 7);
    return nextSaturday;
};
