import React, { FunctionComponent } from 'react';
import { ColumnLayout, Button } from '@amzn/awsui-components-react-v3';

export const QuickStartSection: FunctionComponent = () => {
    return (
        <div data-testid={'quickstart-section'} style={{ padding: '20px 0 10px 100px', textAlign: 'center' }}>
            <ColumnLayout columns={4}>
                <Button
                    ariaLabel='ReSES Wiki'
                    href='https://w.amazon.com/bin/view/Limestone/'
                    iconAlign='right'
                    iconName='external'
                    target='_blank'
                >
                    ReSES Wiki
                </Button>
                <Button
                    ariaLabel='Onboarding Guide'
                    href='https://w.amazon.com/bin/view/Limestone/OnboardingGuide/'
                    iconAlign='right'
                    iconName='external'
                    target='_blank'
                >
                    Onboarding Guide
                </Button>
                <Button
                    ariaLabel='Contact Us'
                    href='https://w.amazon.com/bin/view/Limestone/ContactUs/'
                    iconAlign='right'
                    iconName='external'
                    target='_blank'
                >
                    Contact Us
                </Button>
                <Button
                    ariaLabel='Report a bug (opens new tab)'
                    href='https://email-list.corp.amazon.com/email-list/email-list.mhtml?action=search&name=reses-interests'
                    iconAlign='right'
                    iconName='external'
                    target='_blank'
                >
                    Mailing List
                </Button>
            </ColumnLayout>
        </div>
    );
};
