import React from 'react';
import { Select, SelectProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';

export interface SelectFieldConfig extends ExperimentAttributeConfig, SelectProps {}

/**
 * Field to allow use select one option from a given list of options.
 * Reference: https://polaris.a2z.com/components/awsui-select/?example=with-tags
 */
export abstract class SelectField extends ExperimentAttribute<ExperimentAttributeProps> {
    protected displayConfig!: SelectFieldConfig;

    parseValueFromEvent = (event: CustomEvent<SelectProps.ChangeDetail>): SelectProps.Option => event.detail.selectedOption;

    getSelectedOption = (): SelectProps.Option => this.displayConfig.selectedOption!;

    setValue = async(newValue: SelectProps.Option) => {
        const { isValid, errorText } = this.validate(newValue.label, this.validationRules);
        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            selectedOption: newValue,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };
        await new Promise((resolve) => this.setState({ displayValue: newValue.label!, validity: isValid }, () => resolve(newValue)));
    }

    getPolarisElement = () => <Select data-testid={this.props['data-testid']} {...this.displayConfig}/>;
}
