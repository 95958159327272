import { LimestoneExperimentBoundaries } from '@amzn/limestone-experiment-portal-types';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { BoundarySet, DisplayAttribute } from '@amzn/limestone-experiment-portal-types';

export const convertGetBoundariesResponseToDisplay = (response: LambdaModel.GetExperimentBoundariesResponse): LimestoneExperimentBoundaries => {
    const boundaries = response.boundaries.map((experimentBoundary) => {
        return experimentBoundary;
    });

    return {
        boundaries: new DisplayAttribute('Treatment Boundaries', true, boundaries, boundaries.join(', '))
    };
};

export const convertBoundarySetDtosToBoundarySet = (boundarySetDtos: LambdaModel.BoundarySetDto[]): BoundarySet[] => {
    return boundarySetDtos.map((boundarySetDto) => {
        return {
            boundaries: new Map(Object.entries(boundarySetDto.boundaries)),
            attributes: new Map(Object.entries(boundarySetDto.attributes)),
            isManuallyUploaded: boundarySetDto.isManuallyUploaded
        };
    });
};

/**
 * Convert boundaryName stored in LEMS (e.g. REGION_DE_DE129) to RMS boundaryName (e.g. DE129)
 * @param boundaryName
 */
export const removeLemsBoundaryPrefix = (boundaryName: string) => {
    return boundaryName.split('_').slice(2).join('_');
};
