import { CDC_API_ENDPOINTS } from '../api-endpoints';
import * as LambdaModelTypes from '@amzn/limestone-experiment-portal-types';
import { ApiHandler } from '../../api-handler';
import { CdcApiHandler } from './cdc-api-handler';
import { Realm } from '@amzn/limestone-experiment-portal-types';

export default class CdcApiHandlerImpl extends ApiHandler implements CdcApiHandler {
    public constructor(realm: Realm) {
        super(realm, CDC_API_ENDPOINTS);
    }

    public getTransactionalMetrics = async(experimentId: string, date: string): Promise<LambdaModelTypes.GetTransactionalMetricsResponse> => {
        const url = `getWeeklyTransactionalMetric/${experimentId}/${date}`;
        const response: LambdaModelTypes.GetTransactionalMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }

    public getFinalDownstreamMetrics = async(experimentId: string, date: string): Promise<LambdaModelTypes.MetricsTable> => {
        const url = `getFinalDownstreamMetrics/${experimentId}/${date}`;
        const response: LambdaModelTypes.GetDownstreamMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.table;
    }

    public getCustomMetric = async(experimentId: string, dataType: string, date: string): Promise<LambdaModelTypes.MetricsTable> => {
        const url = `getCustomMetricResult/${experimentId}/${dataType}/${date}`;
        const response: LambdaModelTypes.GetDownstreamMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.table;
    }

    public getQuickSightEmbedUrl = async(userAlias: string): Promise<LambdaModelTypes.GetQuickSightEmbedUrlResponse> => {
        const url = `getQuickSightEmbedUrl/${userAlias}`;
        const response: LambdaModelTypes.GetQuickSightEmbedUrlResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }

    public getCustomSelectionsTransactionalMetric = async(selectionIds: string[], date: string): Promise<LambdaModelTypes.GetCustomSelectionsTransactionalMetricResponse> => {
        const url = 'getCustomSelectionsTransactionalMetric';
        const payload: LambdaModelTypes.GetCustomSelectionsTransactionalMetricRequest = {
            selectionIds: selectionIds,
            datasetDate: date
        };
        return await this.sendHttpPostRequest(url, payload);
    }

    public getCompletedCollectionDates = async(experimentId: string, dataType: string): Promise<string[]> => {
        const url = `getCollectionDates/${experimentId}/${dataType}`;
        const response: LambdaModelTypes.GetCompletedCollectionDatesResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.dates;
    }

    public scheduleDataCollection = async(experimentId: string, marketplaceId: number, dataType: string, collectionDates: string[], startDate: string, endDate: string,
        regionDefinitionType: string, isCustomTriggersUploaded: boolean): Promise<LambdaModelTypes.ScheduleDataCollectionResponse> => {

        const url = 'scheduleDataCollection';
        const payload: LambdaModelTypes.ScheduleDataCollectionRequest = {
            externalId: experimentId,
            marketplaceId: marketplaceId,
            dataType: dataType,
            collectionDates: collectionDates,
            customAnalysisStartDate: startDate,
            customAnalysisEndDate: endDate,
            regionDefinitionType: regionDefinitionType,
            isCustomTriggersUploaded: isCustomTriggersUploaded
        };
        return await this.sendHttpPostRequest(url, payload);
    }

    public getMetricsInExperimentDashboard = async(experimentId: string): Promise<LambdaModelTypes.MetricsTable[]> => {
        const url = `getKeyMetrics/${experimentId}`;
        const response: LambdaModelTypes.GetExperimentKeyMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.tables;
    }

    public getScheduledDataCollectionItems = async(experimentId: string, dataType: string): Promise<LambdaModelTypes.ScheduleDataCollectionDisplayItem[]> => {
        const url = `getScheduledCollectionItems/${experimentId}/${dataType}`;
        const response: LambdaModelTypes.GetScheduledDataCollectionItemsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.items;
    }

    public getExperimentSummary = async(experimentId: string, date: string) => {
        const url = 'getExperimentSummary';
        const payload = {
            'experimentUUID': experimentId,
            'datasetDate': date,
        };
        const response = await this.sendHttpPostRequest(url, payload);
        return response;
    }
}
