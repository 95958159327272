import { Approver, ApproverExperimentAttributes } from '@amzn/limestone-experiment-portal-types';
import { ExperimentApproverDto } from '@amzn/limestone-experiment-portal-types';
import { ApprovalStatus } from '../../../enums/ApprovalStatusType';
import { ApproverRole, ApproverRoleType } from '../../../enums/ApproverRoleType';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';

export const convertApproverDtoToApprover = (approverDto: ExperimentApproverDto): Approver => {
    return {
        alias: approverDto.alias,
        status: (ApprovalStatus as any)[approverDto.status],
        role: (ApproverRole as any)[approverDto.type],
        rejectionReason: approverDto.rejectReason
    };
};

export const convertExperimentToApproverAttributes = (experiments: LimestoneExperiment[], approverRole: ApproverRoleType): ApproverExperimentAttributes[] => {
    return experiments.map((experiment) => {
        return {
            title: experiment.metadata.title.displayValue,
            experimentId: experiment.experimentId!,
            experimentIntegerId: experiment.experimentIntegerId!,
            marketplace: experiment.metadata.marketplace.displayValue,
            primaryOwner: experiment.metadata.primaryOwner.displayValue,
            productFamily: experiment.metadata.productFamily.displayValue,
            approverType: approverRole
        };
    });
};