import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { ExternalLinks } from '@amzn/limestone-experiment-portal-types';
import { PageSection } from '../layout/PageSection';
import { AmazonLogo } from '../common/AmazonLogo';

const useStyles = makeStyles((theme) => ({
    column1: {
        gridColumn: 1
    },
    column2: {
        gridColumn: 2
    },
    content: {
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        padding: '20px'
    },
    footer: {
        marginTop: 'auto'
    },
    link: {
        color: theme.palette.grey[400],
        fontSize: '1.5rem',
        fontWeight: 800,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.secondary.main
        }
    }
}));

const getBackgroundColor = (theme: Theme): string => theme.palette.primary.main;

export const Footer: FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <footer className={classes.footer}>
            <PageSection testId={'footer'} backgroundColor={getBackgroundColor} className={classes.content}>
                <Typography className={classes.column1}>
                    <a href={ExternalLinks.GUIDE.href}>
                        <span className={classes.link}>User Guide</span>
                    </a>
                </Typography>

                <span className={classes.column2}>
                    <AmazonLogo color={theme.palette.grey[400]} width='70px' />
                </span>
            </PageSection>
        </footer>
    );
};
