import React, { Component } from 'react';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { CDC_DATA_NOT_READY_CODE, Realm, GetTransactionalMetricsResponse } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { MetricsPageDisplay } from './MetricsPageDisplay';
import { NonCancelableCustomEvent, SelectProps } from '@amzn/awsui-components-react-v3';
import { UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { constructDate, getNextSaturday } from '../../utils/date-utils';

export interface TransactionalMetricsSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    addMetricElement?: JSX.Element;
    setNotification: Function;
    userAttributes: UserAttributes;
}

export interface TransactionalMetricsSectionState {
    experiment: LimestoneExperiment;
    availableDays: string[];
    tableGroups: DataTableGroupDefinition[];
    showSpinner: boolean;
    buttonLoadingState: boolean;
}

export class TransactionalMetricsSection extends Component<TransactionalMetricsSectionProps, TransactionalMetricsSectionState> {
    public dataCollectionAPI: CdcApiHandler;

    constructor(props: TransactionalMetricsSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            availableDays: [],
            tableGroups: [],
            showSpinner: false,
            buttonLoadingState: false
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchAvailableDates();
    }

    fetchAvailableDates = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getCompletedCollectionDates(this.state.experiment.experimentId, 'WEEKLY_TRANSACTIONAL_METRIC')
            .then((dates: string[]) => {
                this.setState({ availableDays: dates });
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getTransactionalMetrics.FAIL!);
                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    fetchTransactionalMetrics = async(selectedDay: string) => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getTransactionalMetrics(this.state.experiment.experimentId, selectedDay)
            .then((response: GetTransactionalMetricsResponse) => {
                this.setState({ tableGroups: TransactionalMetricsAdaptor.convertMetricsTableToDataTableGroup(response.table) });
            })
            .catch((error: any) => {
                if (!(error.response && error.response.status === CDC_DATA_NOT_READY_CODE)) {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getTransactionalMetrics.FAIL!);
                }

                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    onDateChange = async(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        await this.fetchTransactionalMetrics(event.detail.selectedOption.value!);
    }

    render() {
        return (
            <MetricsPageDisplay
                id='transactional-metrics'
                experiment={this.props.experiment}
                tableGroups={this.state.tableGroups}
                tableKey='Transactional Metrics'
                dataNotReadyWarning={`Transactional Metrics are not ready for this experiment: \
                    ${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experiment.experimentIntegerId})`}
                availableDays={this.props.userAttributes.isAdmin
                    ? this.state.availableDays
                    : this.state.availableDays.filter((date) => constructDate(date) <= getNextSaturday(constructDate((this.props.experiment.metadata.endDate.payloadValue))))}
                showSpinner={this.state.showSpinner}
                onDateChangeFunction={this.onDateChange}
                // 'ccc57ea6-1992-4ec9-9f5b-3d99212722c3' = Test experiment - with transactional and downstream metrics in limestone-cdc-dev
                // '4462dbc5-725f-4687-a06d-9c975a97f43f' = Amazon HB substitution experiment in limestone-prod-iad-data-collection
                // '0462acf1-89fa-46df-b988-05852500b19c' = ReSES Apparel Experiment
                enableExperimentSummary={(this.props.experiment.experimentId === 'ccc57ea6-1992-4ec9-9f5b-3d99212722c3'
                  || this.props.experiment.experimentId === '4462dbc5-725f-4687-a06d-9c975a97f43f'
                || this.props.experiment.experimentId === '0462acf1-89fa-46df-b988-05852500b19c')}
            />
        );
    }
};
