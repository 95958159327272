import { Button, Icon, Popover } from '@amzn/awsui-components-react-v3';
import React, { FunctionComponent } from 'react';

export interface CopyToClipboardProps {
    textToCopy: string;
    label: string;
}

export const CopyToClipboard: FunctionComponent<CopyToClipboardProps> = (props: CopyToClipboardProps) => (
    <Popover
        size='small'
        position='top'
        triggerType='custom'
        dismissButton={true}
        content={
            <span className='awsui-util-status-positive'>
                <Icon variant='success' name='status-positive' /> {`${props.label} copied`}
            </span>
        }>
        <Button iconName='copy' onClick={async() => await navigator.clipboard.writeText(props.textToCopy)}>
            {`${props.label}`}
        </Button>
    </Popover>
);
