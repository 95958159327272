import React, { Component } from 'react';
import { Button, ColumnLayout, FormField, Input, Multiselect, SelectProps, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { CustomEvaluationRequestFile } from '../attributes/CustomEvaluationRequestFile';
import { CustomEvaluationRequest, CustomEvaluationRequestAttributes, DisplayMode, MetricType } from '@amzn/limestone-experiment-portal-types';

/**
 * The Props for the CustomEvaluationRequestField component.
 * @param index index of the CustomEvaluationRequest in the parent component.
 * @param customEvaluationRequest: initial value of the CustomEvaluationRequest controlled by this component
 * @param updateFormState: function to update the state of the parent component
 * @param deleteCustomEvaluationRequest: function to delete this CustomEvaluationRequestField from the parent component
 */
export interface CustomEvaluationRequestFieldProps {
    index: number;
    customEvaluationRequest: CustomEvaluationRequest;
    updateFormState: (customEvaluationRequest: CustomEvaluationRequest, index: number) => void;
    deleteCustomEvaluationRequest: (index: number) => void;
    saveCustomEvaluationRequest?: (index: number) => void;
}

/**
 * State of the CustomEvaluationRequestField component.
 * @param customEvaluationRequest: the CustomEvaluationRequest controlled by this component
 * @param saveAttempted: true if the user has tried to save this CustomEvaluationRequest
 * @param selectionIsValid: true if the user has uploaded a valid selection file
 */
export interface CustomEvaluationRequestFieldState {
    customEvaluationRequest: CustomEvaluationRequest;
    saveAttempted: boolean;
    selectionIsValid: boolean;
}

const METRIC_TYPES: SelectProps.Option[] = [
    {
        label: 'Transactional Metrics',
        value: MetricType.CUSTOMIZED_TRANSACTIONAL_METRIC
    }
];

/**
 * A group of fields used to input a Custom Evaluation Request.
 */
export class CustomEvaluationRequestField extends Component<CustomEvaluationRequestFieldProps, CustomEvaluationRequestFieldState> {
    constructor(props: CustomEvaluationRequestFieldProps) {
        super(props);

        this.state = {
            customEvaluationRequest: props.customEvaluationRequest,
            saveAttempted: false,
            selectionIsValid: !props.customEvaluationRequest.editing
        };
    }

    updateSelectionFile = (_fieldId: string, payloadValue: any, _displayValue: string, isValid: boolean) => {
        this.updateCustomEvaluationRequest(CustomEvaluationRequestAttributes.SELECTION, payloadValue);
        this.setState({ selectionIsValid: isValid });
    }

    updateCustomEvaluationRequest = (fieldId: string, value: any) => {
        this.setState(({ customEvaluationRequest }) => ({
            customEvaluationRequest: {
                ...customEvaluationRequest,
                [fieldId]: value
            }
        }), () => {
            this.props.updateFormState(this.state.customEvaluationRequest, this.props.index);
        });
    }

    saveCustomEvaluationRequest = () => {
        this.setState({ saveAttempted: true });
        if (this.nameValid() && this.metricTypesValid() && this.state.selectionIsValid) {
            this.props.saveCustomEvaluationRequest
                ? this.props.saveCustomEvaluationRequest(this.props.index)
                : this.updateCustomEvaluationRequest(CustomEvaluationRequestAttributes.EDITING, false);
        }
    }

    editCustomEvaluationRequest = () => {
        this.setState({ saveAttempted: false });
        this.updateCustomEvaluationRequest(CustomEvaluationRequestAttributes.EDITING, true);
    }

    nameValid = () => {
        return this.state.customEvaluationRequest.name.length > 0;
    }

    metricTypesValid = () => {
        return this.state.customEvaluationRequest.metricTypes.length > 0;
    }

    /** Renders the JSX Content. */
    render = () =>
        <div style={{ padding: '10px 0 10px 0' }}>
            <ColumnLayout columns={4}>
                <FormField label='Name'>
                    <Input
                        data-testid='name-input'
                        disabled={!this.state.customEvaluationRequest.editing}
                        value={this.props.customEvaluationRequest.name}
                        onChange={(event) => {
                            this.updateCustomEvaluationRequest(CustomEvaluationRequestAttributes.NAME, event.detail.value);
                        }}
                        invalid={this.state.saveAttempted && !this.nameValid()}
                    />
                </FormField>
                <CustomEvaluationRequestFile
                    disabled={!this.state.customEvaluationRequest.editing}
                    updateFormState={this.updateSelectionFile}
                    displayMode={DisplayMode.CREATE}
                    initialValue={this.props.customEvaluationRequest.selection}
                    initialFileName={'Upload Selection'}
                />
                <FormField label='Metric Types'>
                    <Multiselect
                        data-testid='metric-types-multiselect'
                        disabled={!this.state.customEvaluationRequest.editing}
                        placeholder='Choose metric types'
                        options={METRIC_TYPES}
                        selectedOptions={METRIC_TYPES.filter((metricTypeOption) => {
                            return this.state.customEvaluationRequest.metricTypes.some((metricType) => metricType === metricTypeOption.value);
                        })}
                        invalid={this.state.saveAttempted && !this.metricTypesValid()}
                        onChange={(event) => {
                            this.updateCustomEvaluationRequest(CustomEvaluationRequestAttributes.METRIC_TYPES,
                                event.detail.selectedOptions.map((selectedOption) => MetricType[selectedOption.value as keyof typeof MetricType]));
                        }}
                    />
                </FormField>
                <SpaceBetween size='s'>
                    {this.state.customEvaluationRequest.editing
                        ? <Button
                            data-testid='save-button'
                            iconName='file'
                            onClick={this.saveCustomEvaluationRequest}>Save</Button>
                        : <Button
                            data-testid='edit-button'
                            iconName='edit'
                            onClick={this.editCustomEvaluationRequest}>Edit</Button>}
                    <Button
                        data-testid='delete-button'
                        iconName={'close'}
                        onClick={() => this.props.deleteCustomEvaluationRequest(this.props.index)}>Delete</Button>
                </SpaceBetween>
            </ColumnLayout>
        </div>
}
