import React from 'react';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

export enum ApproverRoleType {
    BUSINESS = 'BUSINESS',
    LIMESTONE = 'LIMESTONE'
}

type StatusTypeMap = {
    [name in ApproverRoleType]: JSX.Element;
};

export const ApproverRole: StatusTypeMap = {
    BUSINESS: <span><BusinessCenterIcon fontSize='large'/> Business Approver</span>,
    LIMESTONE: <span><SupervisorAccountIcon fontSize='large'/> ReSES Admin</span>
};
