import { AttributeLabels, CustomerTriggersType, DisplayMode, DownstreamMetricRequestAttributes } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { constructDate } from '../../utils/date-utils';

export class CustomerTriggersTypeField extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_TRIGGERS_TYPE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Select whether you want to upload your own triggers or use our default triggering methodology',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, DownstreamMetricRequestAttributes.CUSTOMER_TRIGGERS_TYPE),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        const endDate = constructDate(this.props.endDate!);
        endDate.setDate(endDate.getDate() + 9);
        const isDefaultOptionDisabled = new Date() < endDate;

        this.displayConfig.options = [
            { label: 'Default Customer Triggers', value: CustomerTriggersType.DEFAULT_CUSTOMER_TRIGGERS.toString(), disabled: isDefaultOptionDisabled },
            { label: 'Use Your Own Customer Triggers', value: CustomerTriggersType.CUSTOMIZED_CUSTOMER_TRIGGERS.toString() },
        ];

        this.forceUpdate();
    }

    getPayloadValue = () => {
        if (this.state.displayValue === 'Use Your Own Customer Triggers') {
            return false;
        }

        return true;
    }
}
