import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { AttributeLabels, DisplayMode, MetadataAttribute, Realm } from '@amzn/limestone-experiment-portal-types';
import { DateField, DateFieldConfig } from '../fields/DateField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { constructDate } from '../../utils/date-utils';

export class EndDate extends DateField {
    protected displayConfig: DateFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);
        this.validationRules = { required: true };

        this.displayConfig = {
            label: AttributeLabels.END_DATE,
            editable: props.editable === true,
            touched: false,
            placeholder: 'YYYY-MM-DD',
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            hintText: 'Required *',
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.END_DATE),
            disabled: true,
            value: initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true,
            startDate: props.startDate
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate() {
        this.displayConfig.disabled = this.props.startDate === '';
        if (this.state.startDate !== this.props.startDate) {
            this.setValueFromPayload('');
            this.setState({ startDate: this.props.startDate });
        }
    }

    isDateEnabled(date: Date): boolean {
        if (date.getUTCDay() === 6 || date.getUTCDay() === 0) {
            return false;
        }

        if (date < constructDate(this.props.startDate!)) {
            return false;
        }

        return true;
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateExperimentEndDate(this.props.experimentId!, this.getPayloadValue())
            .finally(() => {
                this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW });
            });

        return response;
    }
}
