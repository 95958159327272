import React  from 'react';
import {
    Select,
} from '@amzn/awsui-components-react-v3';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react-v3/polaris/internal/events';

const DEFAULT_OPTION_VALUE = '0';

/**
 * Props to declare an {@link AttributeSelectFilter}.
 */
export interface AttributeSelectFilterProps {
    /**
     * A list of items to filter by attribute. It is to provide all attribute values as the select dropdown options.
     * This select filter does not maintain items after filtering.
     */
    items: any[];
    /**
     * Item attribute name to filter.
     */
    attributeName: string;
    /**
     * Attribute label string to display.
     */
    attributeLabel?: string;
    /**
     * Event triggered after select change.
     */
    onChangeEvent: NonCancelableEventHandler<SelectProps.ChangeDetail>;
    /**
     * Select option to display in the box. It would show placeholder when this field is undefined or null.
     */
    selectedOption?: SelectProps.Option;
    testId?: string;
}

/**
 * Provide a common single select filter on an item attribute. Select options are all attribute values that a list of
 * items have.
 */
export const AttributeSelectFilter = (props:AttributeSelectFilterProps) => {

    const attributeLabelToDisplay = props.attributeLabel ? props.attributeLabel : props.attributeName;
    const defaultOption = { value: DEFAULT_OPTION_VALUE, label: `Any ${attributeLabelToDisplay}` };
    const selectOptions = getSelectOptions(props.items, props.attributeName, defaultOption);

    return (
        <div className='select-filter'>
            <Select
                placeholder={`Filter ${attributeLabelToDisplay}`}
                data-testid={props.testId}
                options={selectOptions}
                selectedAriaLabel='Selected'
                selectedOption={props.selectedOption ? props.selectedOption : null}
                onChange={props.onChangeEvent}
                expandToViewport={true}
            />
        </div>
    );
};

/**
 * Build a list of select options based on given items' given attribute.
 *
 * @param items items to filter or to provide all attribute values as selection options
 * @param attributeName attribute name
 * @param defaultOption default option shown at the top of the dropdown list
 * @return a list of {@link SelectProps.Option}
 */
function getSelectOptions(items: any, attributeName: string, defaultOption: SelectProps.Option): SelectProps.Option[] {
    const optionSet: SelectProps.Option[] = [];

    // Build a non-redundant list of the field passed as parameter.
    items.forEach((item: { [x: string]: any; }) => {
        if (optionSet.indexOf(item[attributeName]) === -1) {
            optionSet.push(item[attributeName]);
        }
    });
    optionSet.sort();

    // The first element is the default one.
    const options = [defaultOption];

    // Add the other element ot the list.
    optionSet.forEach((item: any) => options.push({ label: item, value: item }));
    return options;
}

/**
 * Filter items by given attribute name and value.
 *
 * @param items items to filter
 * @param attributeName attribute name(e.g., marketplace)
 * @param attributeValue attribute value(e.g., US) in {@link SelectProps.Option}. If it's null, undefined or the default
 * option(no filter applied), original list will be returned.
 */
export function filterItemsByAttribute(items: any[], attributeName: string, attributeValue: SelectProps.Option | null | undefined): any[]{
    if (attributeValue === null || attributeValue === undefined || attributeValue.value === DEFAULT_OPTION_VALUE) {
        return items;
    } else {
        return items.filter((item) => item[attributeName] === attributeValue.value);
    }
}
