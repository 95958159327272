import { RMS_API_ENDPOINTS } from '../api-endpoints';
import { ApiHandler } from '../../api-handler';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import {
    GetRegionDefinitionsResponse,
    GetRegionDefinitionPreSignedUrl,
    Boundary,
    GetBoundariesInDefinitionTypeResponse,
    GetOverlappingBoundariesInDefinitionTypeResponse,
    BoundaryInput,
    GetBoundariesInBatchResponse
} from '@amzn/limestone-experiment-portal-types';
import { CreateRegionDefinitionOperationType } from '@amzn/limestone-experiment-portal-types';
import axios from 'axios';

/**
 * Handler that processes Region Management Service API calls.
 */
export default class RMSApiHandler extends ApiHandler {

    public constructor(realm: Realm) {
        super(realm, RMS_API_ENDPOINTS);
    }

    /**
     * Get all region definitions in a given marketplace.
     *
     * @param marketplace marketplace plain name(e.g., US)
     * @return a {@link Promise} of {@link GetRegionDefinitionsResponse}
     */
    public getRegionDefinitions = async(marketplace: string): Promise<GetRegionDefinitionsResponse> => {
        const url = `region-definition-types/${marketplace}`;
        return await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
        });
    }

    /**
     * Upload the given region definition file to the region management system by calling RMS API to get pre-signed url and put to the resource.
     *
     * @param marketplace marketplace plain name(e.g., US)
     * @param definitionType customer-defined region definition type name(e.g., ZIP3)
     * @param operationType a {@link CreateRegionDefinitionOperationType} enum object representing operation mode
     * @param file file to upload
     * @return a {@link Promise} of boolean value indicating if upload has succeeded
     */
    public uploadRegionDefinitionFile = async(marketplace: string, definitionType: string, operationType: CreateRegionDefinitionOperationType, file: File): Promise<boolean> => {
        // Calls RMS API to get pre-signed URL for S3 put.
        const url = 'region-definition/uploadLocation';
        const preSignedUrl = (await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: {
                marketplace,
                definitionType,
                operationType,
            }
        }) as GetRegionDefinitionPreSignedUrl).preSignedUrl;

        // Puts files to the pre-signed URL.
        const response = await axios.put(preSignedUrl, file, {
            headers: {
                'Content-Type': 'text/csv',
            }
        });

        if (!response) {
            console.error('Error uploading region definition file to S3');
            return false;
        }
        return true;
    }

    /**
     * Get a list of boundaries with given boundary names, marketplace, and region definition type.
     *
     * @param marketplace marketplace plain name (e.g., US)
     * @param definitionType customer-defined region definition type name (e.g., ZIP3)
     * @param boundaryNames a list of customer-defined boundary names (e.g. DE129)
     * @return a {@link Promise} of {@link Boundary} list
     */
    public getBoundariesInBatch = async(marketplace: string, definitionType: string, boundaryNames: string[]): Promise<Boundary[]> => {
        const url = `boundaries/${marketplace}/${definitionType}`;
        return (await this.sendHttpPostRequest(url, {
            boundaryNames: boundaryNames,
        }) as GetBoundariesInBatchResponse).boundaries;
    }

    /**
     * Get boundaries in the given definitions that have overlapping with the given list of boundaries.
     * E.g., given input [Zip3-981, Zip3-980] and [STATE], RMS returns [WA].
     *
     * @param marketplace marketplace plain name
     * @param definitionType definition type
     * @param boundaries a list of {@link BoundaryInput} objects
     * @return a {@link Promise} of list of boundary names in string
     */
    public getOverlappingBoundariesInDefinitionType = async(marketplace: string, definitionType: string, boundaries: BoundaryInput[]): Promise<string[]> => {
        const url = `overlapping-boundaries/${marketplace}/${definitionType}`;
        return (await this.sendHttpPostRequest(url, {
            marketplace,
            definitionType,
            boundaries,
        }) as GetOverlappingBoundariesInDefinitionTypeResponse).overlappingBoundaries;
    }

    /**
     * Get all boundaries in a given definition type.
     *
     * @param marketplace marketplace plain name
     * @param definitionType definition type
     * @return a {@link Promise} of {@link Boundary} list
     */
    public getBoundariesInDefinitionType = async(marketplace: string, definitionType: string): Promise<Boundary[]> => {
        const url = 'boundaries';
        let nextToken = null;
        const boundaries: Boundary[] = [];
        let result: GetBoundariesInDefinitionTypeResponse;
        do {
            result = await this.sendHttpGetRequest(url, {
                params: {
                    marketplace: marketplace,
                    definitionType: definitionType,
                    nextToken: nextToken,
                },
                headers: this.config.headers,
            });
            nextToken = result.nextToken;
            boundaries.push(...result.boundaries);
        } while (result.nextToken !== null && result.nextToken !== undefined && result.boundaries.length !== 0);

        return boundaries;
    }
}
