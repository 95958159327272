import { DisplayMode } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../../fields/SelectField';
import {
    CreateRegionDefinitionOperationType,
    DEFAULT_REGION_DEFINITION_CREATION_OPERATION,
    REGION_DEFINITION_OPERATION_CONFIG_MAP
} from '@amzn/limestone-experiment-portal-types';
import { SelectProps } from '@amzn/awsui-components-react-v3';

/**
 * Create region definition operation mode.
 */
export class RegionDefinitionOperation extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);
        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: 'Region Definition Operation Type',
            editable: false,
            touched: false,
            placeholder: 'Please select from the drop-down',
            filteringType: 'auto',
            hintText: 'Default mode is creating RABL boundaries and uploading to database.',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent),
            selectedOption: null,
        };

        this.state = {
            displayValue: REGION_DEFINITION_OPERATION_CONFIG_MAP.get(DEFAULT_REGION_DEFINITION_CREATION_OPERATION)!.name,
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        // Initializes hard-coded operation type options.
        this.displayConfig.options = Array.from(REGION_DEFINITION_OPERATION_CONFIG_MAP.keys())
            .map((key) => {
                return {
                    value: key.toString(),
                    label: REGION_DEFINITION_OPERATION_CONFIG_MAP.get(key)!.name,
                } as SelectProps.Option;
            });

        // Sets default option
        if (!this.props.initialValue && this.props.updateFormState) {
            this.setValueFromPayload(DEFAULT_REGION_DEFINITION_CREATION_OPERATION);
            this.props.updateFormState('', DEFAULT_REGION_DEFINITION_CREATION_OPERATION,
                REGION_DEFINITION_OPERATION_CONFIG_MAP.get(DEFAULT_REGION_DEFINITION_CREATION_OPERATION)!.name, true);
        }

        this.forceUpdate();
    }

    /**
     * Function that is triggered whenever the value of the field is changed. It will be responsible for
     * changing any state variables of this component as well as form state of the parent component.
     *
     * @param event event that has triggered the function
     */
    onChangeEvent = async(event: CustomEvent) => {
        const updatedField = Object.create(this);
        const newValue = this.parseValueFromEvent(event);
        await updatedField.setValue(newValue);
        if (this.props.updateFormState) {
            this.props.updateFormState('', newValue.value, newValue.label!, this.getValidity());
        }
        return updatedField;
    };

    setValueFromPayload = async(payloadValue: CreateRegionDefinitionOperationType) => {
        this.setValue({ value: payloadValue.toString(), label: REGION_DEFINITION_OPERATION_CONFIG_MAP.get(payloadValue)!.name });
    }
}
