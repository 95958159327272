export default {
    'aws_project_region': 'us-east-1',
    'aws_cognito_identity_pool_id': 'us-east-1:4201f8f0-8b82-473a-a13f-986d5ed36697',
    'aws_cognito_region': 'us-east-1',
    'aws_user_pools_id': 'us-east-1_EuPrMxGQO',
    'aws_user_pools_web_client_id': '6ojrvs01hae14unnqo6hsf7ogd',
    'oauth': {
        'domain': 'reses-beta.auth.us-east-1.amazoncognito.com',
        'scope': [
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        'redirectSignIn': 'https://beta.limestone.strx.amazon.dev',
        'redirectSignOut': 'https://beta.limestone.strx.amazon.dev',
        'responseType': 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    'federationTarget': 'COGNITO_USER_POOLS',
};
