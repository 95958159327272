import { encryptCustomerId, decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP, MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels, Realm } from '@amzn/limestone-experiment-portal-types';
import { NA_MARKETPLACES, EU_MARKETPLACES, FE_MARKETPLACES } from '../../constants/experiment/marketplace-list';

export class Marketplace extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);
        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm.toUpperCase() : Realm.NA.toUpperCase();

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.MARKETPLACE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: `You are currently in ${realm} Realm. For other realms, use the top navigation bar from the Home Page.`,
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.MARKETPLACE),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        if (this.props.realm === Realm.EU) {
            this.displayConfig.options = EU_MARKETPLACES;
        } else if (this.props.realm === Realm.FE) {
            this.displayConfig.options = FE_MARKETPLACES;
        } else {
            this.displayConfig.options = NA_MARKETPLACES;
        }

        this.forceUpdate();
    }

    getPayloadValue = (): string => encryptCustomerId(MARKETPLACE_MAP[this.state.displayValue]);

    setValueFromPayload = async(obfuscatedMarketplaceId: string) => {
        const deObfuscatedMarketplaceId = decryptCustomerId(obfuscatedMarketplaceId);
        const marketplaceLabel = INVERTED_MARKETPLACE_MAP[Number(deObfuscatedMarketplaceId)];
        this.setValue({ value: marketplaceLabel, label: marketplaceLabel });
    }
}
