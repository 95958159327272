import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3';
import { ScheduleDataCollectionDisplayItem } from '@amzn/limestone-experiment-portal-types';

export const SCHEDULED_REQUESTS_COLUMN_DEFINITIONS: Array<TableProps.ColumnDefinition<ScheduleDataCollectionDisplayItem>> = [
    {
        id: 'requestDate',
        header: <div style={{ 'paddingLeft': '20px' }}>Collection Date</div>,
        cell: (item) => <div style={{ 'paddingLeft': '20px' }}>{item.requestDate}</div>,
        minWidth: '140px'
    },
    {
        id: 'startDate',
        header: 'Analysis Start Date',
        cell: (item) => item.customAnalysisStartDate,
        minWidth: '110px'
    },
    {
        id: 'endDate',
        header: 'Analysis End Date',
        cell: (item) => item.customAnalysisEndDate,
        minWidth: '75px'
    },
    {
        id: 'status',
        header: 'Request Status',
        cell: (item) => item.status,
        minWidth: '110px'
    }
];