import { TextAreaField, TextAreaFieldConfig } from '../fields/TextAreaField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode, ExperimentApprovalAttribute } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';

export class RejectionReason extends TextAreaField {
    protected displayConfig: TextAreaFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true, maxLength: 256 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.REJECTION_REASON,
            editable: true,
            rows: 3,
            placeholder: '256 char limit',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, ExperimentApprovalAttribute.REJECTION_REASON),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid,
            editInProgress: false
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }
}
