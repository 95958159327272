import { Box, Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { ActionType, EMBED_REGION_MAP, EXPERIMENT_DETAIL_PAGE_PERMISSIONS, LimestoneExperiment, PageProps, RABL_BASE_URLS } from '@amzn/limestone-experiment-portal-types';
import React from 'react';
import { Component } from 'react';
import { DisplayTable } from '../../common/DisplayTable';
import { allExperimentColumnOptions, getAllExperimentsTableColumnDefinition, pageSizeOptions } from '../../constants/table/experiment-table/experiment-table-definition';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { asinActionPage } from '..';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';


export interface AsinDeepDivePageState {
    asin: string;
    submitted:boolean;
    experimentIds:string[];
    experimentDetails:LimestoneExperiment[];
    treatmentRegions:string[][];
    controlRegions:string[][];
}

class ASINDeepDivePage extends Component<PageProps, AsinDeepDivePageState> {
    
    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;
        
    constructor(props: PageProps) {
        super(props);
        this.state = {
            asin: '',
            submitted:false,
            experimentIds:[],
            experimentDetails:[],
            treatmentRegions:[[]],
            controlRegions:[[]],
        };
        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
    }
    /* istanbul ignore next */
    componentDidMount = async() => {}

    getAllExperimentsWithASIN = async(asin:string) => {
        //For now, we chose a sample production experiment. In the future, this will be from the all experiments with asin result
        const experiments = await this.experimentServiceAPI.getAllExperimentsWithASIN(asin)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));
        return experiments;
    }

    getExperimentDetails = async(experimentID:string ) => {
        //For now, we chose a sample production experiment. In the future, this will be from the all experiments with asin result
        const experiment = await this.experimentServiceAPI.getExperimentById(experimentID)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));
        return experiment;
    }

    getTreatmentZipCodes = async(experimentID:string) => {
        const treatmentBoundaries = await this.experimentServiceAPI.getAllExperimentBoundaries(experimentID, 'TREATMENT')
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));
        return treatmentBoundaries;
    }
    getControlZipCodes = async(experimentID:string) => {
        const controlBoundaries = await this.experimentServiceAPI.getAllExperimentBoundaries(experimentID,'CONTROL')
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));
        return controlBoundaries;
    }


    validateASINLength = (asin:String): boolean => {
        const validatelength = 10;

        if (asin.length === validatelength){
            return true; 
        } else{
            return false;        
        }
    }

    downloadBoundariesCSV = async(boundaries:string[][])  => {
        let csvContent = 'data:text/csv;charset=utf-8,';

        boundaries.forEach(function(rowArray) {
            let row = rowArray.join(',');
            csvContent += row + '\r\n';
        });

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    
    render() {

        return (
            <Box margin={'xxl'}>
                <div style={{ padding: '20px' }}>
                    <h1>ASIN Deep Dive</h1>
                </div>
            
                <SpaceBetween direction='horizontal' size='xxl'>
                    <label>ASIN Search</label>
                    <input type='text' style= {{ textTransform: 'uppercase' } } onChange={ (e)=> {
                        this.setState( { asin:e.target.value.toUpperCase() } );
                    } }>
                    </input>
                    <Button data-testid="Submit-Button-ASIN-Validation-Button" variant='primary' onClick={async() => {
                        await this.setState( { 
                            submitted : false,
                            experimentDetails: [],
                            treatmentRegions: [[]],
                            controlRegions: [[]]

                        } );

                        if(this.validateASINLength(this.state.asin)){
                            let experiments = await this.getAllExperimentsWithASIN(this.state.asin);
                            if (experiments) {
                                await this.setState({ experimentIds: experiments });
                            }
                           
                            for (let i = 0; i < this.state.experimentIds.length; i++) {
                                let experimentInfo= await this.getExperimentDetails(this.state.experimentIds[i]);
                                let treatmentRegions= await this.getTreatmentZipCodes(this.state.experimentIds[i]);
                                let controlRegions= await this.getControlZipCodes(this.state.experimentIds[i]);

                                if(treatmentRegions) {
                                    let unionTreatmentRegions = this.state.treatmentRegions;
                                    //union treatment regions together 
                                    if(treatmentRegions.boundaries.displayValue !== ''){
                                        unionTreatmentRegions[0].push(treatmentRegions.boundaries.displayValue);
                                        await this.setState({ treatmentRegions:unionTreatmentRegions });
                                    }
                                }
                                if(controlRegions) {
                                    let unionControlRegions = this.state.controlRegions;
                                    //union control regions together 
                                    if(controlRegions.boundaries.displayValue !== ''){
                                        unionControlRegions[0].push(controlRegions.boundaries.displayValue);
                                        await this.setState({ controlRegions:unionControlRegions });
                                    }

                                }
                                if (experimentInfo) {
                                    this.state.experimentDetails.push(experimentInfo);
                                    this.setState({ experimentDetails:this.state.experimentDetails });
                                }

                            }
                            await this.setState( { 
                                submitted : true,
                            } );  
                        }
                    }}> Submit </Button>
                </SpaceBetween>
                {this.state.asin.length > 0 && !this.validateASINLength(this.state.asin) && <p style={{ color: 'red' }} data-testid="Submit-Button-ASIN-Validation-Message">This is an Invalid ASIN length, should be 10</p>}
                <p></p>
                {this.state.submitted && <h2> ReSES Experiments </h2>}
                {this.state.experimentDetails.length !== 0 && this.state.submitted && <div style={{ paddingTop: 20 }}>
                    <DisplayTable
                        title={<Header variant="h2" counter={String(this.state.experimentDetails.length)}> Running Experiments that contain ASIN </Header>}
                        items={this.state.experimentDetails}
                        tableLoading={false}
                        columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                        columnOptions={allExperimentColumnOptions}
                        pageSizeOptions={pageSizeOptions}
                        preferencesEnabled={true}
                        initialSortingDescending={true}
                        initialSortingId={'startDate'}
                        data-testid="All-experiment-with-asin-display-table"
                    /></div>}
                {this.state.experimentDetails.length === 0 && this.state.submitted && <p>No experiments found with this ASIN in {this.props.realm}</p>}
                {this.state.submitted &&<h2 data-testid="Treatment-zip-codes-section-header"> Treatment Zip Codes </h2>}
                
                {this.state.submitted && <div>
                    <Button data-testid="Treatment-zip-codes-section-button" variant='primary' disabled={this.state.treatmentRegions[0].length === 0} onClick={() => {
                        this.downloadBoundariesCSV(this.state.treatmentRegions);
                    }}> Download Treatment Zip Codes</Button>
                </div>}
                {this.state.submitted && this.state.treatmentRegions[0].length === 0 && <p>No treatment zip codes found for this ASIN</p>}

                {this.state.submitted && this.state.controlRegions[0].length > 0 && <h2 data-testid="Control-zip-codes-section-header"> Control Zip Codes </h2>}

                {this.state.submitted && this.state.controlRegions[0].length > 0 && <div>
                    <Button data-testid="Control-zip-codes-section-button" variant='primary'  disabled={(this.state.controlRegions[0].length === 0)} onClick={() => {
                        this.downloadBoundariesCSV(this.state.controlRegions);
                    }}>Download Control Zip Codes</Button>
                </div>}
                {this.state.submitted && this.state.treatmentRegions[0].length > 0 &&<h2> Map </h2>}
                {this.state.submitted && this.state.treatmentRegions[0].length > 0 && <Container>
                    {this.state.experimentDetails.filter(
                        (experiment) => experiment.metadata.rablRegionId.payloadValue !== 'dummy_region_id')
                        .map((experiment)=> (
                            <iframe
                                key={experiment.metadata.rablRegionId.payloadValue}
                                src={`${RABL_BASE_URLS.getEndpoint(this.props.realm) + EMBED_REGION_MAP + experiment.metadata.rablRegionId.payloadValue}`}
                                title='Region map'
                                width='100%'
                                height='500'
                                frameBorder='0'
                                scrolling='no'
                                style={{ width: '70%', float: 'left' }}
                            />
                        ))
                    }
                </Container>}
                <p></p>
                {this.state.submitted && this.state.experimentDetails.length > 0 && <h2>ASIN Action</h2>}
                {this.state.submitted && this.state.experimentDetails.map((experiment) => (
                    <PermissionControlledButton
                        key={experiment.experimentId}
                        userAccessLevels={this.props.userAccessLevels}
                        actionType={ActionType.ADMIN_ACTION}
                        pagePermissionsMap={EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
                        testId={'asin-actions-button'}
                        hideIfNotAuthorized={true}
                        buttonProps={{
                            href: `${asinActionPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${this.props.realm}` }}
                    > {experiment.metadata.title.displayValue}</PermissionControlledButton>
                ))} 
                <p></p>
                {this.state.submitted && this.state.asin.length >0 && <div>
                    <a href={'https://drop.amazon.dev/na/drop/any-offer-query?selectedOfferAsin=' + this.state.asin}>
                        <Button variant='primary' 
                        >Drop Offers with {this.state.asin}
                        </Button>

                    </a>
                </div> }
            </Box> 
        );
    }
}

export default ASINDeepDivePage;
