interface RegionDefinitionTypeMap {
    [name: string]: string;
}

/**
 * Default commonly used region definition type for ReSES experiments in each marketplace.
 */
export const MARKETPLACE_DEFAULT_REGION_DEFINITION_TYPE: RegionDefinitionTypeMap = {
    US: 'ZIP3',
    DE: 'NUTS3',
    UK: 'UK-CUSTOM-BOUNDARY',
    FR: 'FR-CUSTOM-BOUNDARY',
    IT: 'IT-CUSTOM-BOUNDARY',
    ES: 'ES-CUSTOM-BOUNDARY',
    JP: 'ZIP3',
};
