import React from 'react';
import { Textarea, TextareaProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';

export interface TextAreaFieldConfig extends ExperimentAttributeConfig, TextareaProps {}

/**
 * Field to allow user input multiple lines of text.
 * Reference: https://polaris.a2z.com/components/awsui-textarea/?example=with-placeholder
 */
export abstract class TextAreaField extends ExperimentAttribute<ExperimentAttributeProps> {
    protected displayConfig!: TextAreaFieldConfig;

    parseValueFromEvent = (event: CustomEvent<TextareaProps.ChangeDetail>): string => event.detail.value;

    setValue = async(newValue: string) => {
        const { isValid, errorText } = this.validate(newValue, this.validationRules);
        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            value: newValue,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };

        await new Promise((resolve) => this.setState({ displayValue: newValue, validity: isValid }, () => resolve(newValue)));
    }

    getPolarisElement = () => <Textarea data-testid={this.props['data-testid']} {...this.displayConfig}/>;
}
