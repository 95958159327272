import React, { Component } from 'react';
import { Container, Spinner } from '@amzn/awsui-components-react-v3';
import { PageProps } from '@amzn/limestone-experiment-portal-types';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { parseQueryParametersFromUrl } from '../../utils/url-utils';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { addUserAccessLevelsFromExperiment } from '../../utils/auth-utils';
import * as FormUtils from '../../utils/form-utils';
import { experimentDashboardPage } from '../index';
import { ExperimentNavigationBar } from '../ExperimentDetails/ExperimentNavigationBar';

export interface ExperimentDashboardPageState {
    showSpinner: boolean;
    experiment: LimestoneExperiment;
}

export class ExperimentDashboardPage extends Component<PageProps, ExperimentDashboardPageState> {
    readonly EMBEDDING_CONTAINER_ID = 'embeddingContainerId';

    public cdcApiHandler: CdcApiHandler;
    public experimentServiceApi: LemsApiHandler;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            showSpinner: true
        };

        this.cdcApiHandler = new CdcApiHandlerImpl(props.realm);
        this.experimentServiceApi = new LemsApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        const { experimentId, marketplaceId, experimentIntegerId } = parseQueryParametersFromUrl(new URL(window.location.href));

        const experiment = await this.experimentServiceApi.readExperiment(experimentId, experimentIntegerId)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

        if (experiment) {
            this.setState({ experiment });
        }

        await this.cdcApiHandler.getQuickSightEmbedUrl(this.props.userAttributes?.username!)
            .then((response) =>  {
                const options = {
                    url: response.embedUrl,
                    container: document.getElementById(this.EMBEDDING_CONTAINER_ID)!,
                    parameters: {
                        experimentuuid: experimentId,
                        marketplace: marketplaceId,
                    },
                    height: 'AutoFit',
                    loadingHeight: '800px',
                };
                embedDashboard(options);
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getQuickSightEmbedUrl.FAIL!);
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    render() {
        const userAccessLevels = addUserAccessLevelsFromExperiment(this.state.experiment.metadata, this.props.userAttributes!.username, this.props.userAccessLevels);

        return (
            <PermissionControlledView
                userAccessLevels={userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                {this.state.showSpinner
                    ? <Spinner size={'large'} />
                    : <div style={{ padding: 5 }}>
                        <Container>
                            <ExperimentNavigationBar
                                experiment={this.state.experiment}
                                realm={this.props.realm}
                                userAccessLevels={this.props.userAccessLevels}
                                currentPage={experimentDashboardPage}
                            />
                        </Container>
                    </div>
                }
                <div id={this.EMBEDDING_CONTAINER_ID} />
            </PermissionControlledView>
        );
    }
}

export default ExperimentDashboardPage;
