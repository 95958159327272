import { Stage, ExperimentOfferDto } from '@amzn/limestone-experiment-portal-types';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../../constants/experiment/marketplace-map';

export const BETA_MERCHANT_ID_MAP: any = {
    US: '410611560',
    DE: '10',
    UK: '9',
    FR: '11',
    JP: '12',
    CN: '120098015',
    IT: '70502283',
    IN: '525141603',
    ES: '114333523'
};

export const PROD_MERCHANT_ID_MAP: any = {
    US: '608808520',
    DE: '10',
    UK: '9',
    FR: '11',
    JP: '12',
    CN: '623133424',
    IT: '755690533',
    IN: '830277313',
    ES: '695831032'
};

export const marketplaceIdToMerchantId = (marketplaceId: string, stage: Stage): string => {
    const deObfuscatedMarketplaceId = decryptCustomerId(marketplaceId);
    const marketplaceLabel = INVERTED_MARKETPLACE_MAP[Number(deObfuscatedMarketplaceId)];
    return stage === Stage.BETA ? BETA_MERCHANT_ID_MAP[marketplaceLabel] : PROD_MERCHANT_ID_MAP[marketplaceLabel];
};

export const convertAsinsToExperimentOffers = (asins: string[], stage: Stage, marketplaceId: string): ExperimentOfferDto[] => {
    const merchantId = marketplaceIdToMerchantId(marketplaceId, stage);
    const offers: ExperimentOfferDto[] = [];
    asins.forEach((asin: string) => {
        offers.push({
            asin: asin,
            sku: asin,
            merchantId
        });
    });

    return offers;
};
