import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ErrorIcon from '@material-ui/icons/Error';
import CreateIcon from '@material-ui/icons/Create';
import PageviewIcon from '@material-ui/icons/Pageview';
import TableIcon from '@material-ui/icons/TableChart';
import { Page } from '../interfaces/Page';
import * as PermissionsModel from '../constants/auth/permission-models';
import { UserAccessLevel, Realm, Portal } from '@amzn/limestone-experiment-portal-types';
import HomePage from './Home/HomePage';
import PageNotFound from './ErrorPages/PageNotFound';
import ExperimentDetailPage from './ExperimentDetails/ExperimentDetailsPage';
import MyExperimentsPage from './MyExperiments/MyExperimentsPage';
import UpdateStaticConfigPage from './admin/UpdateStaticConfigPage/UpdateStaticConfigPage';
import AllExperimentsPage from './AllExperiments/AllExperimentsPage';
import AsinActionPage from './admin/AsinAction/AsinActionPage';
import ApproverDetailPage from './ApproverDetailPage/ApproverDetailPage';
import ApproversQueue from './ApproversQueue/ApproversQueue';
import TransactionalMetrics from './Metrics/MetricsPage';
import ExperimentDashboardPage from './ExperimentDashboard/ExperimentDashboardPage';
import CreateExperimentPage from './CreateExperiment/CreateExperimentPage';
import CreateRegionDefinitionPage from './admin/CreateRegionDefinition/CreateRegionDefinitionPage';
import ASINDeepDivePage from './AsinDeepDiveTool/AsinDeepDivePage';

export const homePage: Page = {
    caption: 'The homepage for Limestone',
    content: <HomePage
        realm={Realm.NA}
        portal={Portal.BASIC}
        userAccessLevels={new Set<UserAccessLevel>()}
        permissionsMap={PermissionsModel.DEFAULT_PERMISSIONS}
    />,
    contentType: 'default',
    icon: HomeIcon,
    hideTab: true,
    matchExactPath: true,
    path: '/',
    title: 'Home',
    testId: 'home',
    disableContentPaddings: true,
};

export const createExperimentPage: Page = {
    caption: 'Create a Limestone Experiment',
    content: (
        <CreateExperimentPage
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.CREATE_EXPERIMENT_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    icon: CreateIcon,
    matchExactPath: true,
    path: '/create-experiment',
    title: 'Create Experiment',
    testId: 'create'
};

export const experimentDetailPage: Page = {
    caption: 'Below is the summary of the Experiment',
    content: (
        <ExperimentDetailPage
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    matchExactPath: true,
    path: '/display-experiment',
    title: 'Experiment Detail Page',
    testId: 'display'
};

export const myExperimentsPage: Page = {
    caption: 'View My Experiments',
    content: (
        <MyExperimentsPage
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.MY_EXPERIMENTS_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'table',
    icon: TableIcon,
    matchExactPath: true,
    path: '/my-experiments',
    title: 'My Experiments',
    testId: 'view'
};

export const approversQueue: Page = {
    caption: 'View Experiments in my Approval Queue',
    content: (
        <ApproversQueue
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.APPROVERS_QUEUE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'table',
    icon: TableIcon,
    matchExactPath: true,
    path: '/approvers-queue',
    title: 'Approvers Queue',
    testId: 'approvers'
};

export const approverDetailPage: Page = {
    caption: 'Below is the summary of the Experiment',
    content: (
        <ApproverDetailPage
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.APPROVER_DETAIL_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    matchExactPath: true,
    path: '/approver-detail',
    title: 'Approver Detail Page',
    testId: 'approver'
};

export const allExperimentsPage: Page = {
    caption: 'View All Experiments',
    content: (
        <AllExperimentsPage
            realm={Realm.NA}
            portal={Portal.ADMIN}
            permissionsMap={PermissionsModel.ALL_EXPERIMENTS_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'table',
    icon: TableIcon,
    isAdminPage: true,
    matchExactPath: true,
    path: '/all-experiments',
    title: 'All Experiments',
    testId: 'view'
};

export const UpdateStaticConfigPagePage: Page = {
    caption: 'Update Static Configuration Data',
    content: (
        <UpdateStaticConfigPage
            realm={Realm.NA}
            portal={Portal.ADMIN}
            permissionsMap={PermissionsModel.STATIC_CONFIGURATION_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: false,
    isAdminPage: true,
    icon: PageviewIcon,
    matchExactPath: false,
    path: '/static-config-data',
    title: 'Static Configuration Data',
    testId: 'static-config'
};

export const asinActionPage: Page = {
    caption: 'Perform Asin level Actions',
    content: (
        <AsinActionPage
            realm={Realm.NA}
            portal={Portal.ADMIN}
            permissionsMap={PermissionsModel.ASIN_ACTION_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    isAdminPage: true,
    matchExactPath: false,
    path: '/asin-action',
    title: 'Asin Action',
    testId: 'asin-action'
};

export const transactionalMetricsPage: Page = {
    caption: 'Transactional Metrics',
    content: (
        <TransactionalMetrics
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.TRANSACTIONAL_METRICS_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    isAdminPage: false,
    matchExactPath: false,
    path: '/transactional-metrics',
    title: 'Transactional Metrics',
    testId: 'transactional-metrics'
};

export const experimentDashboardPage: Page = {
    caption: 'View the experiment dashboard',
    content: (
        <ExperimentDashboardPage
            realm={Realm.NA}
            portal={Portal.BASIC}
            permissionsMap={PermissionsModel.EXPERIMENT_DASHBOARD_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    isAdminPage: false,
    matchExactPath: false,
    path: '/experiment-dashboard',
    title: 'Experiment Dashboard',
    testId: 'experiment-dashboard'
};

export const errorPage: Page = {
    caption: 'The page you are trying to reach does not exist',
    content: <PageNotFound />,
    contentType: 'default',
    hideTab: true,
    icon: ErrorIcon,
    matchExactPath: false,
    parentPage: homePage,
    path: '*',
    title: 'Error',
    testId: 'error'
};

export const createRegionDefinition: Page = {
    caption: 'Create new region definitions used in ReSES experiments',
    content: (
        <CreateRegionDefinitionPage
            realm={Realm.NA}
            portal={Portal.ADMIN}
            permissionsMap={PermissionsModel.CREATE_REGION_DEFINITION_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}
        />
    ),
    contentType: 'default',
    hideTab: false,
    icon: PageviewIcon,
    isAdminPage: true,
    matchExactPath: false,
    path: '/region-definition',
    title: 'Create Region Definition',
    testId: 'create-region-definition'
};

export const asinDeepDivePage: Page = {
    caption: 'Deep Dive an ASIN',
    content: (
        <ASINDeepDivePage
            realm={Realm.NA}
            portal={Portal.ADMIN}
            permissionsMap={PermissionsModel.ALL_EXPERIMENTS_PAGE_PERMISSIONS}
            userAccessLevels={new Set<UserAccessLevel>()}>
        </ASINDeepDivePage>
    ),
    contentType: 'default',
    icon: TableIcon,
    isAdminPage: true,
    matchExactPath: true,
    path: '/asin-deepdive',
    title: 'ASIN Deep Dive',
    testId: 'view'
};

export const basicPages: Page[] = [homePage, createExperimentPage, experimentDetailPage, myExperimentsPage, approversQueue, approverDetailPage, transactionalMetricsPage, experimentDashboardPage, errorPage];
export const adminPages: Page[] = [allExperimentsPage, asinActionPage, UpdateStaticConfigPagePage, createRegionDefinition, asinDeepDivePage, ...basicPages];
