import { SelectProps } from '@amzn/awsui-components-react-v3';
import {
    IValidationRules,
    LimestoneExperimentBoundaries,
    RegionSelectionAttribute
} from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps, ExperimentAttributeState } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels, DisplayMode } from '@amzn/limestone-experiment-portal-types';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

export interface BoundariesProps extends ExperimentAttributeProps {
    definitionType: string;
    allowOverlappingBoundaries: boolean;
    otherBoundary?: LimestoneExperimentBoundaries;
    regionSelectionAttribute: RegionSelectionAttribute;
}

export class Boundaries extends MultiSelectField<BoundariesProps> {
    private regionServiceAPIHandler: RMSApiHandler;
    private experimentServiceAPIHandler: LemsApiHandlerImpl;
    protected displayConfig: MultiSelectFieldConfig;
    protected staticConfig: string;
    protected isLoading: boolean;

    constructor(props: BoundariesProps) {
        super(props);

        this.staticConfig = '';
        this.isLoading = true;

        this.validationRules = { required: true };
        const { isValid, errorText } = this.validate(this.props.initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.BOUNDARIES,
            editable: false,
            touched: false,
            placeholder: 'Please select from the drop-down menu',
            filteringType: 'auto',
            disabled: false,
            hintText: 'Select boundaries for manual override',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, this.props.regionSelectionAttribute),
            statusType: 'loading',
            selectedOptions: [],
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: isValid
        };
        this.regionServiceAPIHandler = new RMSApiHandler(this.props.realm!);
        this.experimentServiceAPIHandler = new LemsApiHandlerImpl(this.props.realm!);
    }

    componentDidMount = async() => {
        await this.getBoundaryOptions();
        if (this.props.initialValue && this.props.initialValue.length !== 0) {
            await this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate = async(prevProps: Readonly<BoundariesProps>, prevState: Readonly<ExperimentAttributeState>, snapshot?: any) => {
        if (this.props.definitionType !== prevProps.definitionType || this.props.allowOverlappingBoundaries !== prevProps.allowOverlappingBoundaries) {
            await this.getBoundaryOptions();
            await this.setValue([]);
        }
    }

    getBoundaryOptions = async() => {
        this.displayConfig.statusType = 'loading';
        const decryptedMarketplaceId = decryptCustomerId(this.props.marketplaceId!);
        const marketplace = INVERTED_MARKETPLACE_MAP[Number(decryptedMarketplaceId)];

        /* This code block is to get usable boundaries as displayed options for customers to choose. It:
        * 1. Calls RMS to get all boundaries in the experiment's region definition type(e.g., ZIP3). If overlap is allowed, skip remaining steps.
        * 2. Calls LEMS to get all treatment boundaries used in other active experiments.
        * 3. Calls RMS to convert these active treatment boundaries to the boundaries in experiment's region definition type(ZIP3).
        * 4. Calls RMS to get excluded boundaries for the specific region definition type.
        * 5. Removes the boundaries retrieved in the step 3 & 4 from the boundaries retrieved in the step 1.
        */
        // Step 1
        let eligibleBoundaries = await this.regionServiceAPIHandler.getBoundariesInDefinitionType(marketplace, this.props.definitionType);
        if (!this.props.allowOverlappingBoundaries) {
            // Step 2
            const treatmentBoundariesInUse = await this.experimentServiceAPIHandler.getActiveTreatmentBoundaries(this.props.marketplaceId!, this.props.startDate!, this.props.endDate!);
            const activeTreatmentBoundaries = treatmentBoundariesInUse.map((experimentBoundary) => {
                return {
                    boundaryName: experimentBoundary.boundary.boundaryId!,
                    definitionType: experimentBoundary.boundaryDefinitionType
                };
            });
            // Step 3
            const activeBoundariesInGivenDefinition = await this.regionServiceAPIHandler.getOverlappingBoundariesInDefinitionType(marketplace, this.props.definitionType, activeTreatmentBoundaries);
            // Step 4
            const excludeBoundaries = (await this.regionServiceAPIHandler.getRegionDefinitions(marketplace))
                .regionDefinitionTypes.filter((regionDefinition) => regionDefinition.name === this.props.definitionType)[0]
                .excludeBoundaries;

            // Step 5
            eligibleBoundaries = eligibleBoundaries.filter((e) =>
                !activeBoundariesInGivenDefinition.includes(e.boundaryName) &&
                !excludeBoundaries.includes(e.boundaryName));
        }
        let allItems: SelectProps.Option[] = [];
        eligibleBoundaries.forEach((boundary) => {
            const boundaryDisplay: string = `${boundary.boundaryName}`;
            allItems.push({
                label: boundaryDisplay,
                value: boundary.boundaryName!,
            });
        });
        this.displayConfig.options = allItems;
        this.displayConfig.statusType = 'finished';
        this.forceUpdate();
    }

    getPayloadValue = (): string[] => {
        const boundaries: string[] = [];
        this.getSelectedOptions().forEach((selectedOption) => {
            boundaries.push(selectedOption.value!);
        });

        return boundaries;
    };

    setValueFromPayload = async(boundaries: string[]) => {
        const selectedOptions: SelectProps.Option[] = [];
        boundaries.forEach((boundary) => {
            selectedOptions.push({
                value: boundary,
                label: boundary
            });
        });

        await this.setValue(selectedOptions);
    }

    validateAdditionalRules = (options: OptionDefinition[], validationRules: IValidationRules): any => {
        let isValid: boolean = true;
        const errors: string[] = [];
        if (options) {
            if (this.props.otherBoundary && this.props.otherBoundary.boundaries.payloadValue) {
                const invalidBoundaries = options.filter((boundary: OptionDefinition) => {
                    return this.props.otherBoundary!.boundaries.payloadValue.includes(boundary.value);
                }).map((boundary) => boundary.value);
                if (invalidBoundaries.length > 0) {
                    isValid = false;
                    errors.push(`${invalidBoundaries} overlap with other boundaries inputted`);
                }
            }

        }
        return { isValid: isValid, errors: errors };
    }
}
