import { filterExperimentsByDate } from '../../interfaces/LimestoneExperiment';
import { Header } from '@amzn/awsui-components-react-v3';
import React, { Component } from 'react';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { DisplayTable } from '../../common/DisplayTable';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps, isTestExperiment, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import {
    allExperimentColumnOptions,
    getAllExperimentsTableColumnDefinition,
    pageSizeOptions
} from '../../constants/table/experiment-table/experiment-table-definition';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { TableHeaders } from '@amzn/limestone-experiment-portal-types';
import { mapExperimentsToExperimentsTableItems } from '../../utils/experiments-table-utils';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { DateRangeSelectorProps } from '../../common/DateRangeSelector';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';
import {
    EXPERIMENT_FILTERING_PROPERTIES
} from '../../constants/table/experiment-table/experiment-table-filter-properties';
import { filterItemsByAttribute } from '../../common/AttributeSelectFilter';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';

export interface AllExperimentsPageState {
    experiments: LimestoneExperiment[];
    tableLoading: boolean;
    /**
     * Selected experiment start date range to filter experiments.
     */
    selectedStartDateRangeValue: DateRangePickerProps.Value | null;
    /**
     * Selected experiment status to filter experiment.
     */
    selectedExperimentStatusOption: SelectProps.Option | null;
}

class AllExperimentsPage extends Component<PageProps, AllExperimentsPageState> {
    public experimentServiceAPI: LemsApiHandler;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: true,
            experiments: [],
            selectedStartDateRangeValue: null,
            selectedExperimentStatusOption: null,
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);
    }

    componentDidUpdate = async(prevProps: PageProps) => {
        if (prevProps.realm !== this.props.realm) {
            this.experimentServiceAPI = new ApiHandler(this.props.realm);
            await this.fetchExperiments();
        }
    }

    componentDidMount = async() => await this.fetchExperiments();

    fetchExperiments = async() => {
        await this.experimentServiceAPI.getExperimentsInGivenStatuses(Object.values(ExperimentStatusType))
            .then((response: LimestoneExperiment[]) => this.setState({ experiments: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsInGivenStatuses.FAIL!))
            .finally(() => this.setState({ tableLoading: false }));
    }

    /**
     * Event that handles submit edit in the table.
     *
     * @param currentExperiment the experiment that is edited
     * @param column column edited
     * @param value new value of the cell
     */
    tableEditOnSubmitEvent = async (currentExperiment: LimestoneExperiment, column: any, value: any) => {
        if (column['id'] === 'comment') {
            await this.experimentServiceAPI.updateExperimentComment(currentExperiment.experimentId, value as string);
            currentExperiment.comment = value as string;
        }
        this.setState({
            experiments: this.state.experiments.map((experiment) => experiment.experimentId === currentExperiment.experimentId ? currentExperiment : experiment)
        });
    }

    render() {
        const testExperiments = this.state.experiments.filter((experiment) => isTestExperiment(experiment));
        const nonTestExperiments = this.state.experiments.filter((experiment) => !isTestExperiment(experiment));

        const dateRangeSelectorProps: DateRangeSelectorProps = {
            onChangeEvent: ({ detail }) => this.setState({ selectedStartDateRangeValue: detail.value }),
            timeValue: this.state.selectedStartDateRangeValue,
            placeholder: 'Filter by experiment start date',
            testId: 'all-experiments-table-date-picker',
        };

        // Filter all the chosen attributes to get the experiments to display
        const nonTestExperimentsToDisplay = filterItemsByAttribute(mapExperimentsToExperimentsTableItems(
            filterExperimentsByDate(
                nonTestExperiments, this.state.selectedStartDateRangeValue
            )
        ),
        'currentStatusToDisplay', this.state.selectedExperimentStatusOption!);

        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <DisplayTable
                    title={<Header variant="h2" counter={String(nonTestExperiments.length)}>{TableHeaders.ALL_EXPERIMENTS}</Header>}
                    items={nonTestExperimentsToDisplay}
                    tableLoading={this.state.tableLoading}
                    columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                    columnOptions={allExperimentColumnOptions}
                    pageSizeOptions={pageSizeOptions}
                    preferencesEnabled={true}
                    initialSortingDescending={true}
                    initialSortingId={'startDate'}
                    dateRangeSelectorProps={dateRangeSelectorProps}
                    propertyFilterProperties={EXPERIMENT_FILTERING_PROPERTIES}
                    attributeSelectFilterProps={[
                        {
                            items: mapExperimentsToExperimentsTableItems(nonTestExperiments),
                            attributeName: 'currentStatusToDisplay',
                            attributeLabel: 'experiment status',
                            selectedOption: this.state.selectedExperimentStatusOption!,
                            onChangeEvent: ({ detail }) => this.setState({ selectedExperimentStatusOption: detail.selectedOption }),
                        }
                    ]}
                    tableEditOnSubmitEvent={this.tableEditOnSubmitEvent}
                />
                {testExperiments.length !== 0 && <div style={{ paddingTop: 20 }}>
                    <DisplayTable
                        title={<Header variant="h2" counter={String(testExperiments.length)}>{TableHeaders.ALL_EXPERIMENTS_TEST}</Header>}
                        items={mapExperimentsToExperimentsTableItems(filterExperimentsByDate(testExperiments, this.state.selectedStartDateRangeValue))}
                        tableLoading={this.state.tableLoading}
                        columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                        columnOptions={allExperimentColumnOptions}
                        pageSizeOptions={pageSizeOptions}
                        preferencesEnabled={true}
                        initialSortingDescending={true}
                        initialSortingId={'startDate'}
                        tableEditOnSubmitEvent={this.tableEditOnSubmitEvent}
                    /></div>}
            </PermissionControlledView>
        );
    }
}

export default AllExperimentsPage;
